import { Injectable } from "@angular/core";
import { BaseHttpService } from './base.service';
import { from } from 'rxjs';


@Injectable()
export class FriendService {
  constructor(private service: BaseHttpService) { }

  getAllFriends() {
    return from(this.service.callPromise('GET', 'friend/get_all_friends', {}))
  }

  getAllIncomingInvites(){
    return from(this.service.callPromise('GET', 'friend/get_all_incoming_invites', {}));
  }

  getAllOutgoingInvites(){
    return from(this.service.callPromise('GET', 'friend/get_all_outgoing_invites', {}));
  }

  FriendsInvite(inviter_key: any){
    return from(this.service.callPromise('POST', 'friend/accept_invite', {inviter_key}));
  }

  FriendsRejectInvite(inviter_key: any){
    return from(this.service.callPromise('POST', 'friend/reject_invite', {inviter_key}))
  }
  

}

