export const CONSTANTS = {
    'HOST': 'https://enneagram-359016.ue.r.appspot.com',
    'survey_id': '72eeb410-814d-11e6-99d1-0f3ac12d005b',
    'Enneagram type': [],
    'find_out_about_enneagrams': ['', ''],
    'Do you know type': 'Do you already know your enneagram type?',
    'Step 1 (Rate) heading': 'Choose what most describes how you have ACTUALLY BEEN for most of your life. Do not select what you aspire to become.  You will gain the most benefit if you are completely HONEST', // tslint:disable-line max-line-length
    'Test Circles guidance': ['Totally me', 'Describes me well', 'A little like this', 'Neither or both	A little like this', 'Describes me well', 'Totally me'], // tslint:disable-line max-line-length
    'Step 2 (Questions) heading': 'For each of the questions below, pick the TOP 2 statements in order of relevance that feels most true for you and how you HAVE ACTUALLY BEEN over the course of your life (not what you aspire to become).', // tslint:disable-line max-line-length
    'howiknow': ['An Enneagram expert told me', 'Online Research', 'Book(s)', 'Workshop(s)', 'A friend told me', 'I took a test', 'Several of the above'], // tslint:disable-line max-line-length
    'enneagram_variants': ['I don\'t know', 'Self Preservation (Preserving)', 'Social (Navigating)', 'Sexual (Transmitting)'],
    'Personal info': 'Enter your first and last name',
    'IMAGEQUESTIONSHEADING': 'this is the image question heading',
    'SUMMARY': 'Worldview',
    'BASIC FEAR': 'What\'s great about you',
    'CORE DESIRE': 'When you\'re triggered',
    'COPING STRATEGY': 'Deeply held beliefs',
    'KEY STRENGTHS': 'Primary Fears',
    'TYPICAL CHALLENGES': 'Primary Desires',
    'MANAGERIAL STYLE': 'Defense Mechanisms',
    'BUSINESS STYLE': 'You think you\'ll be happy when',
    'COMMUNICATION STYLE': 'You\'ll actually be happy when',
    'variantTitle': 'Subtype'
};
