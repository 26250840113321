import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { 
          MatTabsModule,
          MatSidenavModule,
          MatDialogModule,
          MatExpansionModule,
          MatListModule,
          MatCardModule,
          MatButtonModule,
          MatProgressSpinnerModule,
          MatSnackBarModule,
          MatSelectModule,
          MatInputModule,
          MatFormFieldModule,
          MatIconModule, 
          MatPaginatorModule,
          MatCheckboxModule} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatSidenavModule,
    MatDialogModule,
    MatExpansionModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    MatTabsModule
  ],
  exports: [
    MatSidenavModule,
    MatDialogModule,
    MatExpansionModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTabsModule
  ],
  declarations: []
})
export class MaterialModule { }
