import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmMessageComponent } from './components/confirm-message/confirm-message.component';
import { MessageService } from './message.service';
import { MaterialModule } from '@material/material.module';
import { FormsModule } from '@angular/forms';
import { NotificationMessageComponent } from './components/notification-message/notification-message.component';

@NgModule({
  declarations: [ConfirmMessageComponent, NotificationMessageComponent],
  entryComponents: [ConfirmMessageComponent, NotificationMessageComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
  ]
})
export class MessageModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: MessageModule,
      providers: [MessageService] 
    };
  }
}
