import { v4 as uuid } from 'uuid';
export class EnneagramForm {
    image_url: string = '';
    user_uuid: string = uuid();
    user_id: string = '';
    certainty: number = 0;
    client: string = null;
    do_you_know_your_type: boolean = false;
    first_name: string = '';
    last_name: string = '';
    enneagram_type: string = '';
    variant: string = '';
    how_did_you_find_us: string = '';
    //is_from_team_app: boolean = false;
    email?: string = '';
    duration_in_seconds?: number;
    et?: any;
    //team_id?: string;
    myScoringQ1?: any;
    myScoringQ4?: any;
    myScoringQ5?: any;
    myScoringQ6?: any;
    myScoringQ7?: any;
    myScoringQ8?: any;
    myScoringTotal?: any;
    winning?: any;
    surveyFitsMyself?: any;    
    user1? : string = '';
    user2? : string = '';
    usertouser = '';
    extra?: any;
}

export const VideoDictionary: any = {
    125: 'https://player.vimeo.com/video/265466854',
    126: 'https://player.vimeo.com/video/265467175',
    127: 'https://player.vimeo.com/video/265467207',
    135: 'https://player.vimeo.com/video/265467239',
    136: 'https://player.vimeo.com/video/265467294',
    137: 'https://player.vimeo.com/video/265467356',
    145: 'https://player.vimeo.com/video/265467535',
    146: 'https://player.vimeo.com/video/265467652',
    147: 'https://player.vimeo.com/video/265467787',
    258: 'https://player.vimeo.com/video/265467859',
    259: 'https://player.vimeo.com/video/265468002',
    268: 'https://player.vimeo.com/video/265468084',
    269: 'https://player.vimeo.com/video/265468165',
    278: 'https://player.vimeo.com/video/265468253',
    279: 'https://player.vimeo.com/video/265468329',
    358: 'https://player.vimeo.com/video/265468443',
    359: 'https://player.vimeo.com/video/265468505',
    368: 'https://player.vimeo.com/video/265468600',
    369: 'https://player.vimeo.com/video/265468664',
    378: 'https://player.vimeo.com/video/265468771',
    379: 'https://player.vimeo.com/video/265468890',
    458: 'https://player.vimeo.com/video/265468996',
    459: 'https://player.vimeo.com/video/265469133',
    468: 'https://player.vimeo.com/video/265469227',
    469: 'https://player.vimeo.com/video/265469358',
    478: 'https://player.vimeo.com/video/265469460',
    479: 'https://player.vimeo.com/video/265469561'
  };
