import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CONSTANTS } from '@models/constants.model';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class BaseHttpService {
  authToken: string;

  private API_URL = environment.api_url;
  /*private GAPI_ENDPOINT = CONSTANTS['HOST'] + '/_ah/api';
  private API_NAME = '';
  private API_VERSION = 'v1';*/

  constructor(
    private httpClient: HttpClient
  ) {}

  private get gapiUrl() {
    return this.API_URL; // [this.GAPI_ENDPOINT, this.API_NAME, this.API_VERSION].join('/');
  }

  call(method: string, path: string, params: any = {}): Observable<any> {
    const methodKey = method.toLowerCase();
    if (this.authToken) {
      params.auth_token = this.authToken;
    }

    if (!path.startsWith('/')) {
      path = `/${path}`;
    }

    if (method === 'GET' || method === 'DELETE') {
      const queryParams = new HttpParams({fromObject: params});
      return this.httpClient[methodKey](this.gapiUrl + path, {params: queryParams});
    }

    return this.httpClient[methodKey](this.gapiUrl + path, params);
  }

  callPromise(method: string, path: string, params: any = {}): Promise<any> {
    return this.call(method, path, params).toPromise();
  }


}

