import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, Logger, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from '@ngrx/data';

import { Observable, of, EMPTY } from 'rxjs';
import { map, catchError, pluck } from 'rxjs/operators';
import { Question } from '@models/question.model';
import { BaseHttpService } from '@services/base.service';
import { Survey } from '@models/survey.model';


@Injectable()
export class CacheDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private service: BaseHttpService) {
    super('Cache', http, httpUrlGenerator);
  }

  // get Cache by Key
  getById(cacheKey: number | string): Observable<any> {    
    return this.service.call('GET', '/cache/getByKey', { cacheKey })
    .pipe(
        catchError(err => of(null)),
        pluck('cacheValue'),
        map(items => {
         return { ...JSON.parse(items), id: cacheKey}
        })
    );
  }

  
}

@Injectable()
export class CacheCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory) {
    super('Cache', elementsFactory);
  }

  private getTypeLabel(v, label: string) {
    if (v.userA && v.userA.Type){
      let str = label + ' '+ v.userA.Type;
      return (!v.userB || !v.userB.Type)? str : '';
    }
      return '';
  }

  private clientJourneyTitle(client) {
    let str;
    switch (client) {
      case 'performance':
        str = 'Growth Journey (Essence)';
        break;
    
      default:
        str = 'Growth Journey';
        break;
    }
    return str;
  }

  getRelationshipContent(v : any) {
    if(!v)
    return [];
    return [
        {
          id:'app-results-relationship',
          title:'Unique Relationship Pattern',
          subtitle:'Bring more awareness and deepen your connection',
          items:[
            {id:'app-results-core-pattern', label:'Core Pattern', typeA: v['Core Pattern'] },
            {id:'app-results-shared-values', label:'Shared Values', typeA: v['Shared Values'] },
            {id:'app-results-complimenting', label:'How You Complement Each Other', typeA: v['How You Compliment Each Other'] },
            {id:'app-results-trouble', label:'How You Can Get Into Trouble', typeA: v['How You Can Get Into Trouble'] },
            {id:'app-results-awareness', label:'What Each Type Needs To Be Aware Of', typeA: v['What Each Type Needs To Be Aware Of'] },
            {id:'app-results-support-eachother', label:'How You Can Support One Another', typeA: v['How You Can Support One Another'] },
            {id:'app-results-in-romantic-context', label:'In A Romantic Context', typeA: v['In a Romantic Context'] }
          ]
        }
      ];
  }

  getUserFields(field, {userA, userB}, id, label?, nameA?, nameB?, key?) {
    let labelA = (nameA||'').indexOf('You')>=0 ? label : label.replace(/You are/gi, `${nameA} is` );
    let labelB = (nameB||'').indexOf('You')>=0 ? label : label.replace(/You are/gi,  `${nameB} is` );    
    labelA = (nameA||'').indexOf('You')>=0 ? label : labelA.replace(/(Your|You)/gi,  `${nameA}`);
    labelB = (nameB||'').indexOf('You')>=0 ? label : labelB.replace(/(Your|You)/gi,  `${nameB}`);

    let item = {
      id,
      label,
      labelA: labelA,
      labelB: labelB,
      [field+'A'] : userA[id] || userA[label] || userA[key],
      [field+'B'] : userB[id] || userB[label] || userB[key],       
    }

    return item;
  }
  
  /** Get Tritype Content for specific user(s) */
   getTypeContent(v : any) {   
    if(!v || !v.userA || !v.userB) {
      return [];
    }
    const labelA = (v.userBName) ? v.userAName : null;
    const labelB = v.userBName;
    const typeLabel = this.getTypeLabel(v,"of Type");
    const nameA = (v.userBName) ? (v.userAName||'').replace("'s","") : 'You';
    const nameB = (v.userBName||'').replace("'s","");
    
    return [
      {
        id:'app-results-your-worldview',
        title:`Worldview ${typeLabel}`,
        labelA, labelB,
        items:[
          {...this.getUserFields('type', v, 'overview', 'Overview') },
          {...this.getUserFields('type', v, 'strengthsBP', `What You are Great At`, nameA, nameB) },
          {...this.getUserFields('type', v, 'corewiring', `Core Wiring` ) },
          {...this.getUserFields('type', v, 'drives', `What Drives You`, nameA, nameB ) },
        ]
      },
      {
        id:'app-results-inner-world',
        title:`Inner World ${typeLabel}`,
        labelA, labelB,
        items:[
          {...this.getUserFields('type', v, 'corefears', 'Core Fears') },
          {...this.getUserFields('type', v, 'coredesires', 'Core Desires') },
          {...this.getUserFields('type', v, 'coreneeds', 'Core Needs') },
          {...this.getUserFields('type', v, 'corebeliefs', 'Core Beliefs') },
          {...this.getUserFields('type', v, 'likes', 'Likes') },
          {...this.getUserFields('type', v, 'dislikes', 'Dislikes') },          
        ]
      },
      {
        id:'app-results-outer-world',
        title:`Outer World ${typeLabel}`,
        labelA, labelB,
        items:[
          {...this.getUserFields('type', v, 'strategies', 'Strategies') },
          {...this.getUserFields('type', v, 'impact', 'Impact of Strategies') },
          {...this.getUserFields('type', v, 'greataboutyou', 'What&apos;s Great About You', nameA, nameB, 'What\&apos\;s Great About You') },
          {...this.getUserFields('type', v, 'attentiongoto', 'Attention goes to...','', '', 'Your Attention Goes To' ) },
        ]
      },
      {
        id:'app-results-operating-system',
        title:`Operating System ${typeLabel}`,
        labelA: (v.userBName) ? v.userAName : null,
        labelB: v.userBName,
        items:[
          {...this.getUserFields('type', v, 'best', 'At Your Best', nameA, nameB) },
          {...this.getUserFields('type', v, 'underStress', 'Under Stress') },
          {...this.getUserFields('type', v, 'challenges', 'What Holds You Back', nameA, nameB) },
          {...this.getUserFields('type', v, 'coping', 'Coping Strategy') },
          {...this.getUserFields('type', v, 'defense', 'Defense Strategy') },
          {...this.getUserFields('type', v, 'triggers', 'Hot Buttons & Triggers') },
          {...this.getUserFields('type', v, 'blindSpots', 'Blind Spots') },
          {...this.getUserFields('type', v, 'mistakenBeliefs', 'Mistaken Beliefs / Trap') },
        ]
      },
  
      {
        id:'app-results-journey',
        title: `${this.clientJourneyTitle(v.client)} ${typeLabel}`,
        labelA: (v.userBName) ? v.userAName : null,
        labelB: v.userBName,
        items:[
          {...this.getUserFields('type', v, 'transformation', 'Transformation Journey') },
          {...this.getUserFields('type', v, 'growthunderStress', 'Under Stress', '', '', 'underStress') },
          {...this.getUserFields('type', v, 'averageDay', 'An Average Day') },
          {...this.getUserFields('type', v, 'theZone', 'In The Zone') },
          {...this.getUserFields('type', v, 'keysToGrowth', 'Keys to Growth') },
       ]
      },
      {
        id:'app-results-workplace',
        title: `${this.getTypeLabel(v,"Type")} In the Workplace`,
        labelA: (v.userBName) ? v.userAName : null,
        labelB: v.userBName,
        items:[
          {...this.getUserFields('type', v, 'workingWithOthers', 'Working with Others') },
          {...this.getUserFields('type', v, 'idealEnvironment', 'Ideal Environment') },
          {...this.getUserFields('type', v, 'typicalChallenges', 'Typical Challenges') },
          {...this.getUserFields('type', v, 'takingGuidance', 'Taking Guidance') },
          {...this.getUserFields('type', v, 'leadershipStyle', 'Leadership Style') },          
        ]
      },
      {
        id:'app-results-famous',
        title:  (v.userB.Type) ? `Famous People` : `Famous ${v.userA.Type}s`,
        labelA: (v.userB.Type) ? `Famous ${v.userA.Type}s` : null,
        labelB: (v.userB.Type) ? `Famous ${v.userB.Type}s` : null,
        items:[
          {...this.getUserFields('type', v, 'famous', 'Famous People with Your Type', nameA, nameB) },
        ]
      }  
    ];
  }
}


