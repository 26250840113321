import { Injectable } from '@angular/core';
import { authProviders } from '@constants/auth.constants';

const FB_KEY = 'ts_fb_token';
const GOOGLE_KEY = 'ts_google_token';

@Injectable({providedIn: 'root'})
export class TokenStorageService {
  constructor() {}

  setData(key: string, data: any) {
    if (!key) {
      return;
    }
   localStorage.setItem(key, JSON.stringify(data));
  }

  getData(key: string) {
    if (!key) {
      return null;
    }
   const data = localStorage.getItem(key);
   return data ? JSON.parse(data) : null;
  }

  setFacebookAccessToken(token: string) {
    if (!token) {
      return;
    }
   localStorage.setItem(FB_KEY, token);
  }

  setGoogleAccessToken(token: string) {
    if (!token) {
      return;
    }
    localStorage.setItem(GOOGLE_KEY, token);
  }

  getFacebookAccessToken() {
    return localStorage.getItem(FB_KEY) || '';
  }

  getGoogleAccessToken() {
    return localStorage.getItem(GOOGLE_KEY) || '';
  }

  clearFacebookAccessToken() {
    localStorage.removeItem(FB_KEY);
  }

  clearGoogleAccessToken() {
    localStorage.removeItem(GOOGLE_KEY);
  }

  clearTokenByProvider(providerId: string) {
    switch (providerId) {
      case authProviders.google:
        this.clearGoogleAccessToken();
        break;

      case authProviders.facebook:
        this.clearFacebookAccessToken();
        break;
    }
  }

  clearTokens() {
    this.clearFacebookAccessToken();
    this.clearGoogleAccessToken();
  }
}

