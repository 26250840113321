import { Injectable } from '@angular/core';
import { EntityServicesBase, EntityServicesElements, EntityCacheDispatcher } from '@ngrx/data';
import { QuestionCollectionService } from '@store/services/question.service';
import { ProfileCollectionService } from '@store/services/profile.service';
import { CacheCollectionService } from '@store/services/cache.service';
import { Survey } from '@models/survey.model';
import { UserCollectionService, LoginType } from '@store/services/user.service';
import { CommonDataService, CommonCollectionService } from '@store/services/common.service';
import { SocialCollectionService } from '@store/services/social.service';
import { GroupCollectionService } from '@store/services/group.service';
import { ConnectionCollectionService } from '@store/services/connection.service';
import { Observable, combineLatest, of, forkJoin } from 'rxjs';
import { map, catchError, concatMap, switchMap, take, tap, finalize, debounceTime } from 'rxjs/operators';
import { MessageCollectionService } from '../services/message.service';
import { MessageService } from '@message/message.service';
import { Router } from '@angular/router';
import { InsideHomeScreenNavigation } from '@services/navigation.service';


@Injectable()
export class AppEntityServices extends EntityServicesBase {
    constructor(entityServicesElements: EntityServicesElements, private entityCacheDispatcher: EntityCacheDispatcher,
        public userService: UserCollectionService, public questionService: QuestionCollectionService,
        public commonService: CommonCollectionService, public cacheService: CacheCollectionService,
        public profileService: ProfileCollectionService, public socialService: SocialCollectionService,
        public groupService: GroupCollectionService, public connectionService: ConnectionCollectionService,
        public messageService: MessageCollectionService, private navigation: InsideHomeScreenNavigation, private router: Router) {
        super(entityServicesElements);
        this.registerEntityCollectionServices([
            userService, questionService, commonService, cacheService,
            profileService, socialService, groupService, connectionService]);
    }

    get allConnections$(): Observable<any[]> {
        return combineLatest(this.connectionService.entities$, this.groupService.allConnections$)
            .pipe(map(([connections, groupConnections]) => [...connections, ...groupConnections]));
    }

    /** Clear Store upon signOut */
    clearStore() {
        this.entityCacheDispatcher.setEntityCache({});
    }

    closeAccount() {
        return this.userService.closeAccount().pipe(map(() => this.signOut()));
    }

    signOut() {
        return this.userService.signOut()
            .pipe(
                map(() => this.clearStore()),
                tap(() => this.userService.setLoaded(true))
            );
    }

    login(type: LoginType, ...params: [string?, string?]) {
        this.navigation.showLoading.emit(true);
        return this.userService.login(type, ...params)
        .pipe(switchMap(()=> this.loadUser()), take(1));
    }

    signUp(...params: [string, string, string, string]) {
        this.navigation.showLoading.emit(true);
        return this.userService.signUp(...params)
        .pipe(switchMap(()=> this.loadUser()), take(1));
    }


    loadUser() {
        this.navigation.showLoading.emit(true);
        return this.userService.loadUserInfo()
            .pipe(
                switchMap((user) => forkJoin([of(user), user ? this.initApp() : of(user)] )),
                map(([user]) => user),
                debounceTime(500),
                finalize(() => {
                    this.userService.setLoaded(true); // set CurrentUser loaded flag to true
                    this.navigation.showLoading.emit(false);
                }),
                catchError((err)=> {
                    this.navigation.showLoading.emit(false);
                    return of(null);
                }),
            );
    }

    setUserByAdmin(id?, auth_token?) {
        this.navigation.showLoading.emit(true);
        return this.userService.setUserbyAdmin(id, auth_token)
            .pipe(                
                switchMap((user) => forkJoin([of(user), user ? this.initApp() : of(user)] )),
                map(([user]) => user),
                debounceTime(500),
                finalize(() => {
                    this.userService.setLoaded(true); // set CurrentUser loaded flag to true
                    this.navigation.showLoading.emit(false);
                }),
                catchError((err)=> {
                    this.navigation.showLoading.emit(false);
                    return of(null);
                }),
            );
    }

    initApp() {
        return combineLatest(
            this.groupService.loadGroups(),
            this.connectionService.loadAllConnections()
        ).pipe(take(1));
    }
}