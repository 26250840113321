export class Question {
    is_mandatory: any;
    minPick: any;
    maxPick: any;
    question_type: any;
    question_class: string;
    content: any;
    leftLabel: string = undefined;
    showScroll: boolean = false;
    oid: string;
    page: string;
    qindex: number;
    section: string;
    userScore: number[] = [0,0,0,0,0,0,0,0,0,0,0,0];
    originalAnswerArray: any[] = undefined;
    generatedContent: any = undefined;
    extra_json?: any;
    survey_name?: string;
    center_page?: boolean = false;
    dateLoaded?: Date;

    evaluate(json) {
       eval(json);
    }

    public static mapToQuestion(item: any): Question {
        return {...item};
    }

}
