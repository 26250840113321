import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
  Action
} from '@ngrx/store';
import { environment } from '@env/environment';
import { storeFreeze } from 'ngrx-store-freeze';

import { EntityCacheAction, SetEntityCache } from '@ngrx/data';
import { isUndefined } from 'util';

export interface AppState {}

export const reducers: ActionReducerMap<AppState> = {};

export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function(state: AppState, action: Action): AppState {
    if (action.type === EntityCacheAction.SET_ENTITY_CACHE && isUndefined((<SetEntityCache>action).cache) ) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [storeFreeze, clearState] : [clearState];
