import { Injectable, EventEmitter, NgZone } from '@angular/core';
import { TokenStorageService } from '@services/token-storage.service';
import { from} from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { authProviders } from '@constants/auth.constants';
import { Course } from '@models/course.model';
import { GApiWrapper } from './gapi-wrapper.service';
import { Database } from './database.service';
import { InsideHomeScreenNavigation } from './navigation.service';
import { BackendService } from './backend.service';
import { Router } from '@angular/router'
import { v4 as uuid } from 'uuid';
import { Firebase } from './firebase.service';
import * as _ from 'lodash';
import { MessageService } from '../modules/message/message.service';
import { BaseHttpService } from './base.service';
import { AppEntityServices } from '@store/entity/entity-services';
import { User } from '@models/user.model';

@Injectable()
export class CurrentUser {
  uuid: string = uuid();
  isLoaded: boolean = false;
  courses: Course[] = []
  activeCourse: Course;
  userInfo: any = {};
  messageSent: EventEmitter<any> = new EventEmitter();

  constructor(
    private ngZone: NgZone,
    private base: BaseHttpService,
    private gapiWrapper: GApiWrapper,
    private service: BackendService,
    private navigation: InsideHomeScreenNavigation,
    private messageService: MessageService,
    private db: Database,
    private firebase: Firebase,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private appEntityServices: AppEntityServices
  ) {}

  load() {
    this.isLoaded = false
    this.loadUserInfo();    

    if (!this.userInfo && !this.getID()) {
      return;
    }

    if(!this.navigation.isProfileUrl) // only show loading if this is not while doing Test
    this.navigation.showLoading.emit(true);
    this.gapiWrapper.getUserInfo(this.getID())
      .then(
        response => {          
          this.setUserSocialInfo(response, this.userInfo);
          this.setData(response);
          let currentUser = User.maptoUser(response);
          this.appEntityServices.userService.setCurrentUser(currentUser);
          this.appEntityServices.groupService.loadGroups(); 
          this.appEntityServices.connectionService.loadAllConnections();
    
        },
        err => this.handleError(err)
      )
    //.then(()=>this.db.getUserConnections().toPromise())
    .then(()=>this.loadUserInfo())
    .then(()=>{
      this.isLoaded = true;
      this.navigation.showLoading.emit(false);
      this.navigation.logPage.emit('YouCloud_userLogin');
    }, err => this.handleError(err)
    ).catch(err=>this.handleError(err));
   
  }

  handleError(err) {
    console.log(err);
    this.navigation.showLoading.emit(false);
    const error = err && err.error && err.error.error || {};
    const message = error.message || '';

    if (message.indexOf('Token expired') > -1) {
      this.messageService.notify('danger', 'User session has expired , please login again.');
    } else if (message) {
      this.messageService.notify('danger', message);
    } else {
      this.messageService.notify('danger', 'Failed to properly load current user');
    }
    this.logout().then(() => this.router.navigateByUrl('/login'));
  }

  waitUntilLoaded() {
    if (this.isLoaded)
      return Promise.resolve()
    let i;
    const p = new Promise<any>((resolve, reject) => {
      i = setInterval(() => {
        if (this.isLoaded) {
          clearInterval(i);
          resolve()
        }
      }, 500)
    })
    return p
  }

  /** Refresh Connections Every 5 mins*/
 /* refreshInterval() {
    if (this._refreshInterval)
      return;
    this._refreshInterval = setInterval(() => {
      this.refreshLists();
    }, 300000);
  }
*/

  logEvent(event) {
    this.db.get("log_events").push(event).write();
  }

  getEvents() {
    return this.db.get("log_events").value();
  }

  firstEvent(type) {
    return this.db.get("log_events").filter({ "type": type + "_first" }).value();
  }

  lastEvent(type) {
    return this.db.get("log_events").filter({ "type": type + "_last" }).value();
  }

  setAuthToken(auth_token) {
    this.base.authToken = auth_token;
    this.db.set("me.authToken", auth_token);
  }

  setUserbyAuthToken(auth_token,redirect?) {
    //this.logout().then(() => {      
      if (this.userInfo.isAdmin) {
        this.setAuthToken(auth_token);
        this.gapiWrapper.getUserInfo('').then((res: any) => {
          this.setData(res);
          this.load();
          this.ngZone.run(() => {            
            this.waitUntilLoaded().then(() => {
              this.router.navigateByUrl((redirect) ? redirect : '/home').then(()=>location.reload())
            });
          });
          console.log(this.userInfo);
        })
        .catch(err=>this.handleError(err));
      }
      
    //})
  }

  setData(userInfo) {
    this.userInfo = userInfo;
    this.userInfo.user_id = userInfo.id;
    this.userInfo.image_url = this.userInfo.image_url || this.userInfo.image_url;
    this.userInfo.full_name = [this.userInfo.name, this.userInfo.last_name].join(' ').trim();

    this.db.set('me.email', this.userInfo.email);
    this.db.set('me.currentUserID', this.userInfo.user_id);
    this.db.set('me.userInfo', this.userInfo);

    if (userInfo.auth_token && userInfo.auth_token.length > 0) {
      this.setAuthToken(userInfo.auth_token);
    }
    //let newUser = this.db.find('users', 'id', this.userInfo.user_id);

    /*if (!newUser) {
      this.db.set('users', []); // reset since this is a new user
      newUser = {
        "email": this.userInfo.email,
        "id": this.userInfo.user_id,
      };
    }*/
    for (var index in Object.keys(userInfo)) {
      var kee = Object.keys(userInfo)[index];
     // newUser[kee] = userInfo[kee];

      this.db.set(`me.${kee}`, userInfo[kee]);
    }
    //this.db.insert('users', newUser);
    //this.db.refresh('users');
    this.db.refreshMe();
  }

  get surveyAnswersDefault() {
    return this.db.getValue('me.surveyAnswersDefault');
  }

  set surveyAnswersDefault(value) {
    this.db.set('me.surveyAnswersDefault', value);
  }

  setUserSocialInfo(destination, data) {
    destination.image_url = data.photoURL || data.image_url;
    destination.email = data.email || destination.email;
    destination.phoneNumber = data.phoneNumber || data.phone_number;
    destination.displayName = data.displayName || data.full_name;

    if (this.firebase.user) {
      const user: any = this.firebase.user.toJSON();
      destination.providerData = user.providerData;
    } else {
      destination.providerData =  data.providerData || destination.providerData;
    }

    if (destination.displayName && !destination.name) {
      let names = destination.displayName.split(' ');
      destination.name = (names.length > 0) ? names[0] : destination.name;
      destination.last_name = (names.length > 0) ? names[1] : destination.last_name;
    }

  }

  loadUserInfo() {
    this.userInfo = this.db.getValue("me.userInfo") || {};
  }

  aposIt(obj) {
    try {
      return obj.endsWith('s') ? "'" : "'s";

    } catch (err) {
      return "";
    }
  }

  getID() {
    return this.db.getValue("me.currentUserID");
  }

  getEmail(): string {
    return this.db.getValue("me.email");
  }

  getToken(): string {
    return this.db.getValue("me.authToken");
  } 

  closeAccount() {    
    return from(this.gapiWrapper.deleteUserData(this.getToken()))
    .pipe(concatMap(() => from(this.firebase.deleteUser())))
    .pipe(concatMap(() => from(this.logout())));
  }

  logout() {
    const createPromise = new Promise<any>((resolve, reject) => {
      this.navigation.logPage.emit('YouCloud_userLogout');
      this.firebase.signOut().subscribe(res => {
        this.userInfo = {};
        this.base.authToken = null;
        this.db.clear();
        this.appEntityServices.clearStore();
        this.tokenStorageService.clearTokens();
        resolve();
      }, errResponse => {
        reject(errResponse);
      });
    });

    return createPromise;
  }

  get isloggedIn(): boolean {
    return  this.getID() != null && this.getEmail() != null && this.firebase.user != null;
  }

  isCurrentUser(user_id: any) {
    return user_id === this.getID();
  }

  switchActiveCourse(course: Course) {
    let obj = this.courses.find((item) => item.id === course.id || item.course === course.id);
    if (obj) {
      this.db.set('me.activeCourse', JSON.stringify(obj));
      this.activeCourse = obj;
      this.messageSent.emit({ name: "ActiveCourse", data: this.activeCourse });
      return this.load()
    }
  }

  get avatarUrl(): string {
    if (!this.userInfo) return '../assets/images/default-avatar.svg';
    return this.userInfo.image_url || '../assets/images/default-avatar.svg';
  }

  get hasAvatarUrl(): boolean {
    return this.userInfo && this.userInfo.image_url;
  }

  set currentTest(data) {
    this.db.set('me.currentTest', data);
  }

  get currentTest() {
    let data = this.db.getValue("me.currentTest");
    return data || null;
  }

  mapReport(value) {
    value.answers = this.service.getAnswers(value.answers);
    value.extra = JSON.parse(value.extra);
    value.score = (value.score && _.isString(value.score)) ? JSON.parse(value.score) : value.score;


    value.tritype = value.extra.tritype.join(',');
    value.tritype_full = (value.extra.tritype) ? value.extra.tritype.join('-') : '';
    value.tritype_name = value.extra.tritype_name;
    value.tritypeDescription = value.tritype_name;

    value.enneagramType = value.extra.winning;
    value.instinct_stack = value.extra.instinct_stack;
    value.instinct_stack_full = value.extra.instinct_stack.join(', ');
    value.winning_instinct_full = value.extra.winning_instinct_full || value.extra.instinct_stack[0];
    value.winning_instinct = value.extra.winning_instinct;
    value.starring_roles = value.extra.starring_roles;
    value.instinctualType = value.winning_instinct_full;

    return value;
  }

  getReport(id) {
    let that = this;
    const createPromise = new Promise<any>((resolve, reject) => {
      var entity = that.db.find("reports", "id", id);
      if (entity) {
        resolve(entity);
      } else {
        this.service.getSurveyAnswersbyId(id).then((response) => {
          let report = that.mapReport(response) || [];
          that.db.insert("reports", report, true);
          resolve(report);
        }, function (errResponse) {
          reject(errResponse);
        });
      }
    });
    return createPromise;
  }

  getAllReports() {
    let that = this;
    const createPromise = new Promise<any>((resolve, reject) => {
      that.base.callPromise('GET', '/survey_answers/getAll').then(({items}) => {
        let reports = items && items.map(item => that.mapReport(item)) || [];
        that.db.set("reports", reports);
        resolve(reports);
      }).catch(err => {
        console.log(err);
        reject(err);
      })
    });
    return createPromise;
  }

  isLinked(provider) {
    if (this.userInfo && this.userInfo.providerData) {
      return this.userInfo.providerData.some(({providerId}) => providerId === provider);
    }

    return false;
  }

  isLinkedWithFacebook() {
    return this.isLinked(authProviders.facebook);
  }

  isLinkedWithGoogle() {
    return this.isLinked(authProviders.google);
  }
}
