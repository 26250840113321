import { ChangeDetectorRef, Component, OnDestroy, OnInit, HostListener, ɵConsole } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Angulartics2Amplitude } from 'angulartics2/amplitude';
import { Angulartics2Facebook } from 'angulartics2/facebook';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2Segment } from 'angulartics2/segment';
import { CurrentUser } from '@services/current-user.service';
import { Database } from '@services/database.service';
import { GApiWrapper } from '@services/gapi-wrapper.service';
import { InsideHomeScreenNavigation } from '@services/navigation.service';
import * as moment from 'moment';
import { MessageService } from '@message/message.service';
import { Firebase } from '@services/firebase.service';
import { environment } from '@env/environment';
import { filter, withLatestFrom, map, concatMap, tap, switchMap, delay, debounceTime, mergeMap, take } from 'rxjs/operators';
import { AppEntityServices } from '@store/entity/entity-services';
import { combineLatest, of, forkJoin } from 'rxjs';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  showLoading = false;
  headerisVisible: boolean = true;
  showCalls: number = 0; // actual loading calls , being called from pages
  user: any = {};
  originator: string;
  testName: string;
  allowTestDrive: boolean = false;
  currentEvent: any; // for loging currentEvent
  me: any = this.currentUser.userInfo;
  redirect: string;

  constructor(
    private db: Database,
    private cdr: ChangeDetectorRef,
    private gapiWrapper: GApiWrapper,
    public currentUser: CurrentUser,
    private router: Router,
    private route: ActivatedRoute,
    private angulartics2Facebook: Angulartics2Facebook,
    private angulartics2Amplitude: Angulartics2Amplitude,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private navigation: InsideHomeScreenNavigation,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private angulartics2Segment: Angulartics2Segment,
    private messageService: MessageService,
    private firebase: Firebase,
    private appEntityServices: AppEntityServices
  ) {
  }

  ngOnInit() {
    this.handleRouteParams();
    this.connect();
    this.eventSubscribes();
    this.printVersionToConsole();
    this.initMessages();

  }

  ngOnDestroy() {
    // log visited event when leaving
    if (this.currentEvent) {
      this.logEvent(this.currentEvent);
      this.currentEvent = null;
    }
  }

  initMessages() {
    this.appEntityServices.messageService.initMessages();
    combineLatest(
      this.appEntityServices.userService.currentUser$,
      this.appEntityServices.messageService.loaded$
    )
      .subscribe(([me, loaded]) => {
        this.me = me;
        if (this.me && this.me.firebaseId && !loaded)
          this.appEntityServices.messageService.loadMessages(`${this.me.firebaseId}_server`);

      });
  }

  private printVersionToConsole() {
    if (!environment.production) {
      return;
    }

    const versionObj: any = '{BUILD_VERSION_OBJ}';
    const { counter, timestamp } = versionObj;
    const formattedTime = moment(timestamp).format('lll');
    const css = 'background: #222; color: #bada55; font-size: 18px';

    console.log(`%c BUILD COUNTER = ${counter} | BUILD TIME = ${formattedTime}`, css);
  }

  /**
   * Capture Window Scroll , emits to scrollPage event scroll position {scrollTop,scrollHeight}
   * @param event window scroll event
   */
  @HostListener("window:scroll", ['$event'])
  onScroll(event) {
    this.navigation.scrollPage.emit(event);
  }

  get isHeaderVisible() {
    return !this.navigation.isLoginUrl && !this.navigation.isRegisterUrl && !this.navigation.isProfileTestStartUrl;
  }

  eventSubscribes() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Log Events for all Pages
        // there is a slash at the first of the path Always
        let paths = location.pathname.slice(1).split('/');

        let page = '';
        if (paths.length) {
          page = (paths.length > 1) ? `_${paths[0]}_${paths[1]}` : `_${paths[0]}`;
        }

        // update lastEvent
        if (this.currentEvent) {
          this.logEvent(this.currentEvent); // log visited event when leaving
          this.currentEvent = null;
        }

        this.currentEvent = 'YouCloud_Visited' + page;
        if (!this.currentUser.firstEvent(this.currentEvent).length) {
          this.logEvent('YouCloud_Visited' + page);  // log Event at first of navigation to page
        }

        console.log('navigated to ' + page);
      }
    });
    this.navigation.logPage.subscribe(msg => {
      this.logEvent(msg);
    });
    this.navigation.showLoading.subscribe(show => {
      this.showLoading = show;
      this.showCalls = show ? this.showCalls + 1 : 0;
      this.navigation.isLoading = show;
      this.cdr.detectChanges();
      if (show)
        setTimeout(() => this.resetShowLoading(), 1000 * 60 * 2); // show if loading took too long ( 2 min )
    });
  }

  resetShowLoading() {
    if (this.showLoading) {
      this.showCalls = 0;
      this.navigation.isLoading = false;
      this.showLoading = false;
      this.cdr.detectChanges();
      this.messageService.notify('warning', 'Page is Not loading properly at this time, please try again later!');
      this.appEntityServices.signOut().subscribe(() => {
        this.router.navigateByUrl('/login');
      });
    }
  }

  connect() {
    // do not hide loading if there is an actual loading call
    if (this.showCalls <= 1 && this.showLoading) {
      this.navigation.showLoading.emit(false);
    }
    this.firebase.currentUser.pipe(take(1))
    .subscribe(user => {
      if (user) {
          this.appEntityServices.loadUser()
          .subscribe(()=> {
            this.navigation.logPage.emit('YouCloud_userLogin');
            this.currentUser.isLoaded = true;
          });
      } else {
        this.currentUser.isLoaded = true;
        this.navigation.showLoading.emit(false);
      }
    });
  }

  handleRouteParams() {
    // Handle queries whenever page is change
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      withLatestFrom(this.route.queryParams)
    ).subscribe(([e, queryParams]) => {
      const {
        testdrive,
        logout,
        accept_team,
        accept_invite,
        auth_token,
        auth_id,
        mode,
        oobCode: actionCode,
        redirect
      } = queryParams;

      this.redirect = redirect || null;
      this.allowTestDrive = (testdrive && testdrive.toLowerCase() === 'true') || false;

      if (logout === 'true') {
        this.appEntityServices.signOut().subscribe(() => {
          this.router.navigateByUrl('/login');
        });
      }
      else if (accept_invite && this.firebase.isloggedIn) {
        this.acceptInvite(accept_invite);
      } else if (accept_team && this.firebase.isloggedIn) {
        this.acceptInvite(accept_team, true);
      } else if ((accept_invite || accept_team) && !this.firebase.isloggedIn) {
        this.router.navigate(['login'], { queryParamsHandling: 'preserve' });
      } else if (auth_token) {
        this.appEntityServices.setUserByAdmin(null, auth_token).subscribe();
      }else if (auth_id) {
        this.appEntityServices.setUserByAdmin(auth_id).subscribe();
      } else if (mode === 'resetPassword') {
        this.resetPassword(actionCode);
      } else if (location.href.indexOf('enneagram-test') > -1) {
        // redirect old Url to new Profile Url
        let url = location.href;
        url = url.replace('home/(enneagram-test:enneagram/', 'profile/');
        url = url.slice(url.indexOf('profile/'), -1);
        this.router.navigateByUrl(url);
      } else if (this.firebase.isloggedIn && this.redirect) {
        if (redirect === 'thinkificsso')
          this.appEntityServices.commonService.redirectThinkificsso();
        else
          this.router.navigateByUrl(this.redirect)
      }

    });
  }

  acceptInvite(id, team?) {
    if (team)
      this.appEntityServices.groupService.acceptInviteGroup(id, true, true).subscribe();
    else
      this.appEntityServices.connectionService.acceptInvite(id, true, true, this.me.id).subscribe();
  }

  private resetPassword(actionCode) {
    this.appEntityServices.userService.verifyPasswordResetCode(actionCode)
      .subscribe((email) => {
        if (!email.code) {
          const queryParams = { actionCode, email };
          this.router.navigate(['complete-reset-password'], { queryParams });
        } else if (email.message) {
          this.messageService.notify('danger', email.message);
        }

      });
  }

  logEvent(eventType) {
    let now = new Date();
    let lastEvent;
    let latestEvent;

    let events = this.currentUser.getEvents(); // all events
    if (events && events.length > 0) {
      latestEvent = events[events.length - 1]; // latest event regarless of type
    }

    const lastEvents = this.currentUser.lastEvent(eventType);
    const firstEvents = this.currentUser.firstEvent(eventType);
    const isFirstVisit = !this.currentUser.firstEvent(eventType).length;

    if (lastEvents.length) {
      lastEvent = lastEvents[lastEvents.length - 1];
    } else if (firstEvents.length) {
      lastEvent = firstEvents[firstEvents.length - 1];
    }

    if (lastEvent) {
      // last event is older than a day
      let amount = now.getDate() - new Date(lastEvent.date).getDate();
      if (amount > 0)
        lastEvent = null;
    }
    const newEventType = lastEvent ? eventType + "_last" : eventType + "_first";

    if (this.firebase.isloggedIn) {
      this.user = {
        window_offsetWidth: document.body.offsetWidth,
        window_offsetHeight: document.body.offsetHeight,
        window_availHeight: window.screen.availHeight,
        window_availWidth: window.screen.availWidth
      };

      if (this.me) {
        const { id, name, last_name, UUID, email, firebaseId } = this.me;
        Object.assign(this.user, {
          Id: id,
          UUID,
          Name: [name, last_name].join(' '),
          Email: email,
          firebaseId
        });
      }

      if (!this.user.UUID) {
        this.user.anonymousId = this.currentUser.uuid;
      }

      const getEventCount = () => {
        if (!lastEvent) {
          return 1;
        }

        return lastEvents.length ? lastEvent.count + 1 : lastEvent.count;
      };
      const getEventAmount = () => {
        if (!lastEvent || !latestEvent) {
          return 0;
        }

        return moment().diff(latestEvent.date, 'seconds') + lastEvent.amount;
      };

      /** Event item being saved to local DB */
      let eventItem = {
        user: this.user,
        type: newEventType,
        date: now.toISOString(),
        count: getEventCount(),
        amount: getEventAmount()
      };

      const getFormattedUrl = () => {
        const { origin } = location;
        let { pathname, search } = location;
        const regExp = /[a-zA-Z0-9_]{50,}/;

        if (pathname && regExp.test(pathname)) {
          pathname = pathname
            .split('/')
            .filter(elem => !regExp.test(elem))
            .join('/');
        }

        if (search && regExp.test(search)) {
          const pathArr = [];
          const searchArr = search
            .slice(1)
            .split('&')
            .map((elem) => {
              if (regExp.test(elem)) {
                const [key] = elem.split('=');
                pathArr.push(key);
                return '';
              }
              return elem;
            })
            .filter(el => !!el);

          if (searchArr.length && pathArr.length) {
            const path = pathArr.join('/');
            return origin + [pathname, path].join('/') + '?' + searchArr.join('');
          }

          if (pathArr.length) {
            const path = pathArr.join('/');
            return origin + [pathname, path].join('/');
          }

          if (searchArr.length) {
            return origin + pathname + '?' + searchArr.join('');
          }
        }

        return origin + pathname;
      };

      let eventData = {
        Category: newEventType,
        URL: getFormattedUrl(),
        Originator: this.originator,
        'Test Name': this.testName || environment.enneagram_default_testName
      };

      if (this.firebase.isloggedIn && this.me) {
        const { id, name, last_name, UUID, email, firebaseId } = this.me;
        Object.assign(eventData, {
          'User ID': id,
          'User UUID': UUID,
          'User Name': [name, last_name].join(' '),
          'User Email': email,
          firebaseId
        });
      }

      if (!eventData['User UUID']) {
        eventData['anonymousId'] = this.currentUser.uuid;
      }

      this.user[eventItem.type] = eventItem.date;
      this.user[eventType + '_count'] = eventItem.count;
      if (!isFirstVisit) {
        this.user[eventType + '_amount'] = eventItem.amount;
      }

      eventData[eventItem.type] = eventItem.date;
      eventData[eventType + '_count'] = eventItem.count;
      if (!isFirstVisit) {
        eventData[eventType + '_amount'] = eventItem.amount;
      }


      // Amplitude
      this.angulartics2Amplitude.setUsername(this.user.Id);
      this.angulartics2Amplitude.setUserProperties(this.user);
      this.angulartics2Amplitude.eventTrack(newEventType, eventData);

      // facebook pixel
      this.angulartics2Facebook.eventTrack(newEventType, eventData);

      // Google Analytics
      this.angulartics2GoogleAnalytics.eventTrack(newEventType, eventData);

      // Google Tag Manager
      this.angulartics2GoogleTagManager.eventTrack(newEventType, eventData);

      // Segment
      this.angulartics2Segment.eventTrack(newEventType, eventData);


      this.currentUser.logEvent(eventItem)

      if (this.allowTestDrive) {
        console.log(eventItem);
        console.log("Page Amount: " + eventItem.amount);
      }

    }
  }
}
