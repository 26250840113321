import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { from, Subject, Observable } from 'rxjs';
import { catchError, takeUntil, map, pluck, take } from 'rxjs/operators';
import { CurrentUser } from '@services/current-user.service';
import { Store, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { GroupRequest, Group } from '@models/group.model';
import { Firebase } from '@services/firebase.service';
import { MatDialogRef } from '@angular/material';
import { MessageService } from '@message/message.service';
import { AppEntityServices } from '@store/entity/entity-services';
import { User } from '@models/user.model';
declare var $: any;

@Component({
  selector: 'app-create-group-modal',
  templateUrl: './create-group-modal.component.html',
  styleUrls: ['./create-group-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateGroupModalComponent implements OnInit, OnDestroy {
  currentUserId: string;
  createForm: FormGroup;
  inProgress: boolean = false;
  success: boolean = false;
  errorMsg: string;  
  
  selectedFile: File;
  selectedFilePreview: string | ArrayBuffer;
  member_emails: string[] = [];
  members: {label:string , value: string}[] = [];

  groupTypes: string[] = ['Team','Family','Friends','Club','Conference','Work','Other'];
  permissions: {label:string , value: string}[] = [
    {label: 'Only Admins', value:'admins'},
    {label: 'Members', value:'members'}
  ];  

  usersLoading = false;
  userToken: string = "";
  users: User[] = [];
  private ngUnsubscribe: Subject<any> = new Subject();
  @Output() refreshList: EventEmitter<any> = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: Firebase,
    public currentUser: CurrentUser,
    private actions$: Actions,
    private messageService: MessageService,
    private appEntityServices: AppEntityServices,
    public dialogRef: MatDialogRef<CreateGroupModalComponent>,
  ) {
    this.initForm();    
  }
  
  ngOnInit() {
    this.appEntityServices.userService.currentUserId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(id => this.currentUserId = id);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  getUsers(data) {    
    if(data && data.length === 3 && this.userToken!= data) {
      this.usersLoading = true;
      this.userToken = data;
      this.appEntityServices.userService.loadAllUsers(this.userToken).subscribe(users=>{
        this.users = users;
        this.usersLoading = false;     
        this.cdr.detectChanges();
      });
    }
  }
  
  initForm() {
    this.createForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null),
      group_type: new FormControl(null, Validators.required),
      invitation_text: new FormControl(null),
      invite_permission: new FormControl(null, Validators.required),
      image_url: new FormControl(null)
    });
    this.selectedFile = null;
    this.selectedFilePreview = null;
    this.member_emails = [];
    this.members = [];
  }

  get canSubmit(): boolean {
    return this.member_emails.length > 0 && !this.createForm.invalid;
  }

  previewFile() {
    if (!this.selectedFile)
      return;
    var reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = (_event) => {
      this.selectedFilePreview = reader.result;
      this.cdr.detectChanges();
    }
  }

  onChangeImage(event) {
    let files = event.target.files;
    if (files.length > 0) {
      this.selectedFile = files[0];
      this.previewFile();
    }
     // Clear the input
    event.srcElement.value = null;
  }  

  removeImage(){
    const {image_url , ...rest} = this.createForm.value;
    this.createForm.reset({...rest, image_url: null});

    this.selectedFile = null;
    this.selectedFilePreview = null;
  }

  isInValid(element) {
    element = this.createForm.get(element);
    return (element.invalid && (element.dirty || element.touched));
  }

  
  isValidEmail(email){
    const control = new FormControl(email, Validators.email);    
    return !control.errors;
  }

  addEmail() {
    const {email , ...rest} = this.createForm.value;
    let isValid = email && email.value && (email.data || (!email.data && this.isValidEmail(email.value))) && !this.member_emails.includes(email.value);
    if(isValid) {      
      this.member_emails.push(email.value);
      this.members.push({label: email.label, value: email.value});
      this.createForm.reset({...rest, email: null});
      this.cdr.detectChanges();
    }    
  }  

  deleteEmail(item) {
    let index = this.members.indexOf(item);
    this.member_emails.splice(index,1);
    this.members.splice(index,1);
  }

  submit() {
    let { name } = this.createForm.value;    
    this.inProgress = true;
    if(this.selectedFile) {
      this.fb.uploadGroupFile(this.selectedFile, name)
      .then(res => res.ref.getDownloadURL())
      .then(img => this.createForm.patchValue({image_url: img}))
      .then(() => this.create());
    } else {
      this.create();
    }
  }


  create() {
    let { name, invitation_text, image_url, invite_permission, group_type } = this.createForm.value;
    let member_emails = this.member_emails;
    let admin_users = [this.currentUserId]
    let group: GroupRequest = { name, member_emails, invitation_text, image_url, invite_permission, group_type, admin_users };
    this.appEntityServices.groupService.add(group).pipe(take(1), pluck('error'))
    .subscribe(error => this.handleReturn(error), error=> this.handleReturn(error));

  }

  handleReturn(error) {
    this.inProgress = false;
    this.errorMsg = (error || {}).message;
    this.success = !error;
    if (this.success)
      this.initForm();

    this.cdr.detectChanges();
  }

}
