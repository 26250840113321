import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, Logger, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { Observable, of, EMPTY, combineLatest, from, throwError, pipe, forkJoin } from 'rxjs';
import { map, catchError, pluck, tap, concatMap, switchMap, mergeMap, filter, take } from 'rxjs/operators';
import { Question } from '@models/question.model';
import { BaseHttpService } from '@services/base.service';
import { Survey } from '@models/survey.model';
import { User } from '@models/user.model';
import { AuthService } from '@services/auth.service';
import { Firebase } from '@services/firebase.service';
import { TokenStorageService } from '@services/token-storage.service';
import { MessageService } from '@message/message.service';
import { Router } from '@angular/router';

export enum LoginType { Password, Facebook, Google };


@Injectable()
export class UserDataService extends DefaultDataService<User> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private service: BaseHttpService) {
    super('User', http, httpUrlGenerator);
  }

  // get Users by token
  getWithQuery(params: string | QueryParams | any): Observable<User[]> {
    let { token } = params;
    if (!token) return of([]);

    return this.service.call('GET', '/user/listbytokenreduced', { token })
      .pipe(
        pluck('items'),
        map(result => result.map(item => {
          item = User.maptoUser(item);
          return { ...item };
        }) || []),
        catchError(err => of([]))
      );
  }

  getUserInfo(id: string): Observable<any> {
    return this.service.call('GET', '/user/get', { id })
      .pipe(map(item => {
        item = User.maptoUser(item);
        return { ...item };
      }));
  }

  getUserAuthByEmail(email): Observable<string>  {
    return this.service.call('GET', '/user/authTokenForEmail', { email })
    .pipe(
      pluck('content'),
      map((item: string) => JSON.parse(item)),
      pluck('new_auth_token')
    );
  }


  deleteUserData(): Observable<any> {
    return this.service.call('POST', '/user/delete_user');
  }
}


@Injectable()
export class UserCollectionService extends EntityCollectionServiceBase<User> {

  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private authService: AuthService, private firebase: Firebase, private userDataService: UserDataService, private service: BaseHttpService, private message: MessageService, private router: Router) {
    super('User', elementsFactory);
  }

  loadAllUsers(token): Observable<User[]> {
    return this.getWithQuery({ token });
  }

  login(type: LoginType, ...params: [string?, string?]) {
    switch (type) {
      case LoginType.Password:
        return this.authService.signIn(...params).pipe(tap((user: User) => this.setCurrentUser(user)));
      case LoginType.Facebook:
        return this.authService.signInWithFacebook().pipe(tap((user: User) => this.setCurrentUser(user)))
      case LoginType.Google:
        return this.authService.signInWithGoogle().pipe(tap((user: User) => this.setCurrentUser(user)))
    }
    return of({});
  }

  loadUserInfo() {    
    return this.authService.loadUser()
      .pipe(
        take(1),
        switchMap(([fbUser, cachUser]) => {
          this.service.authToken = (cachUser || {}).auth_token || this.authService.authToken;             
          let user = (!fbUser) ? fbUser : { ...fbUser.toJSON(), ...cachUser };
          let user_id = (user || {}).user_id || '';
          return forkJoin([
            of(user),
            fbUser && cachUser? this.getUserInfo(user_id) : of(cachUser)
          ]);
        }),
        take(1),
        map(([user, userInfo]) => {          
          if (!user || !userInfo) {
            this.handleError({});
            return;
          };            
          this.authService.saveUserData(user, userInfo);
          this.setCurrentUser(user);
          return user;
        }),
        catchError(err=>this.handleError(err)),            
      );
  }

  handleError(err) {    
    if (err) {
        console.log(err);
        const error = err && err.error && err.error.error || {};
        const message = error.message || '';
    
        if (message.indexOf('Token expired') > -1) {
          this.message.notify('danger', 'User session has expired , please login again.');
        } else if (message) {
          this.message.notify('danger', message);
        } else {
          this.message.notify('danger', 'Failed to properly load current user');
        }
    }
    
    this.signOut().subscribe(() => this.router.navigateByUrl('/login'));
    return of({code: err});
  }

  setCurrentUser(currentUser: User) {
    let queryParams: any = { additional: { currentUser, isloggedIn: true } };
    this.getWithQuery(queryParams).subscribe();
    this.setLoaded(true);
  }

  setUserbyAdmin(id?, auth_token?) {    
    if(!this.authService.adminUser)
      this.adminUser = this.authService.cacheUser;  // save Admin User

    if (this.adminUser.isAdmin) {
      this.service.authToken = auth_token || this.adminUser.auth_token;
      return this.getUserInfo(id || '')
      .pipe(        
        switchMap(user =>
          forkJoin([
            of(user) ,
            !auth_token && user && user.email? this.userDataService.getUserAuthByEmail((user||{}).email) : of(auth_token)
        ])),
        take(1),
        map(([user, auth_token ]: [any, string]) => {                  
          if (user) {
            if (auth_token)
            this.service.authToken = auth_token;

            this.authService.saveUserData(user, user);
            this.setCurrentUser(user);
            this.message.notify('success', 'User Changed Successfully');
          }        
          return user;
        }),
        catchError(err => {          
          console.log(err);
          this.message.notify('danger', err.error.error.message, 'User Change Failed!');
          return of({code: err});
        }));
    }
    return of(null);    
  }

  saveUserData(user, fbUser) {
    return this.authService.saveUserData(user, fbUser);
  }

  get adminUser() {
    if (!this.authService.adminUser || !this.authService.adminUser.isAdmin && (this.authService.cacheUser && this.authService.cacheUser.isAdmin))
      this.authService.adminUser = this.authService.cacheUser;
  
    return this.authService.adminUser || this.authService.cacheUser || {};
  }

  set adminUser(user) {
    this.authService.adminUser = user;
  }

  get waitUntilLoaded$() {
    return combineLatest(
      this.firebase.currentUser,
      this.loaded$
    )
    .pipe(
      filter(([user, loaded]) => !user || !!loaded),
      take(1)
    ) 
  }

  get currentUser$(): Observable<User> {
    return this.collection$.pipe(map((item: any) => item.currentUser));
  }

  get currentUserId$(): Observable<string | null> {
    return this.collection$.pipe(map((item: any) => (item.currentUser || {}).id));
  }

  get isloggedIn$(): Observable<boolean> {
    return this.collection$.pipe(map((item: any) => item.isloggedIn));
  }

  signUp(email, password, firstName, lastName) {
    return this.authService.signUp(email, password, firstName, lastName);
  }

  sendPasswordResetEmail(resetEmail) {
    return this.authService.sendPasswordResetEmail(resetEmail);
  }

  verifyPasswordResetCode(code) {
    return this.authService.verifyPasswordResetCode(code);
  }

  confirmPasswordReset(actionCode, newPassword, email) {
    return this.authService.confirmPasswordReset(actionCode, newPassword, email);
  }

  unlinkUser(providerId) {
    return this.authService.unlinkUser(providerId);
  }

  linkWithFacebook() {
    return this.authService.linkWithFacebook();
  }

  linkWithGoogle() {
    return this.authService.linkWithGoogle();
  }

  linkWithEmail(email, password) {
    return this.authService.linkWithEmail(email, password)
  }

  reconnectWithGoogle() {
    return this.authService.reconnectWithGoogle();
  }

  reconnectWithFacebook() {
    return this.authService.reconnectWithFacebook();
  }

  getUserInfo(id) {
    return this.userDataService.getUserInfo(id);
  }

  closeAccount() {
    return this.userDataService.deleteUserData()
      .pipe(map(() => from(this.firebase.deleteUser())));
  }

  signOut() {
    this.service.authToken = null;    
    return this.authService.signOut();
  }

}
