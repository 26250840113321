import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2Segment } from 'angulartics2/segment';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { MaterialModule } from '@material/material.module';
import { FacebookModule } from 'ngx-facebook';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2Facebook } from 'angulartics2/facebook';
import { Angulartics2Amplitude } from 'angulartics2/amplitude';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { MetaModule, MetaLoader, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
// import {enableProdMode} from '@angular/core';
// enableProdMode();

// App Guards
import { LoginGuardGuard } from '@guards/login-guard.guard';
import { PremiumGuardGuard } from '@guards/premium-guard.guard';
import { ProductsModule } from './products/products.module';
import { AuthService } from '@services/auth.service';

// App Services
import { Firebase } from '@services/firebase.service';
import { BaseHttpService } from '@services/base.service';
import { GApiWrapper } from '@services/gapi-wrapper.service';
import { BackendService } from '@services/backend.service';
import { QuestionsService } from '@services/questions.service';
import { TimerService } from '@services/timer.service';
import { CurrentUser } from '@services/current-user.service';
import { InsideHomeScreenNavigation } from '@services/navigation.service';
import { FacebookShareService } from '@services/facebook-share.service';

// App Components
import { AppComponent } from './app.component';
import { environment } from '@env/environment';
import { MobileService } from '@services/mobile.service';
import { Database } from '@services/database.service';
import { MessageModule } from '@message/message.module';
import { AuthenticationModule } from '@auth/authentication.module';
import { CoreModule } from 'app/@core/core.module';
import { RootStoreModule } from '@store/root-store.module';
import { FriendService } from '@services/friend.service';
import { TypeaheadModule } from '@shared/components/typeahead/typeahead.module';
import { FriendsTypeheadModule } from '@shared/components/friends-typehead/friends-typehead.module';
import { ModalsModule } from '@shared/modals/modals.module';

export function metaFactory(): MetaLoader {
  return new MetaStaticLoader({
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: ' - ',
    applicationName: 'Personality Advantage',
    defaults: {
      title: 'Personality Advantage',
      description: 'All the description',
      'og:image': 'https://upload.wikimedia.org/wikipedia/commons/f/f8/superraton.jpg',
      'og:type': 'website',
      'og:locale': 'en_US'
    }
  });
}

declare var amplitude: any;

export function amplitudeInit() {
  return () => {
   
      setInterval(() => {
        if (window['amplitude'] && window['amplitude'] !== undefined) {
        amplitude.getInstance().init(environment.amplitudeKey, null, {
          includeUtm: true,
          includeReferrer: true
        });
      }
      }, 2000);
    return Promise.resolve();
  };
}

const appRoutes: Routes = [
  { path: 'auth', loadChildren: './authentication/authentication.module#AuthenticationModule' },
  { path: 'products', loadChildren: './products/products.module#ProductsModule', data: { activeLink: 'Products' } },
  { path: 'connections', canActivate: [LoginGuardGuard], loadChildren: './connections/connections.module#ConnectionsModule' },
  { path: 'groups', canActivate: [LoginGuardGuard], loadChildren: './groups/groups.module#GroupsModule' },
  { path: 'settings', loadChildren: './settings/settings.module#SettingsModule' },
  { path: 'page', loadChildren: './static-page/static-page.module#StaticPageModule' },
  { path: 'landing', canActivate: [LoginGuardGuard], loadChildren: './landing/landing.module#LandingModule' },
  { path: 'home', canActivate: [LoginGuardGuard], loadChildren: './home/home.module#HomeModule'},
  { path: 'profile', loadChildren: './profile/profile.module#ProfileModule' },
  { path: 'page/about' , loadChildren: './about/about.module#AboutModule'},
  { path: 'enneagram', loadChildren: './enneagram-learn/enneagram-learn.module#EnneagramLearnModule' },
  { path: 'coach', loadChildren: './coach-profile/coach-profile.module#CoachProfileModule' },
  { path: 'partner', loadChildren: './coach-profile/coach-profile.module#CoachProfileModule' },
  { path: 'faq', loadChildren: './faq/faq.module#FaqModule' },
  { path: 'demohome', canActivate: [LoginGuardGuard], loadChildren: './demohome/demohome.module#DemohomeModule'},
  { path: 'onboarding', loadChildren: './onboarding/onboarding.module#OnboardingModule' },
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: '**', redirectTo: 'landing', pathMatch: 'full' }
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    FriendsTypeheadModule,
    TypeaheadModule,
    BrowserModule,
    BrowserAnimationsModule,
    Angulartics2Module.forRoot([
      Angulartics2Facebook,
      Angulartics2Amplitude,
      Angulartics2GoogleAnalytics,
      Angulartics2GoogleTagManager,
      Angulartics2Segment
    ], {
      pageTracking: {
        clearIds: true,
        idsRegExp: new RegExp('[a-zA-Z0-9_]{50,}', 'g'),
        clearQueryParams: true
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 110]
    }),
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: (metaFactory)
    }),
    NgxPageScrollModule,
    MessageModule.forRoot(),
    NgbModule,
    ChartsModule,
    MaterialModule,
    FacebookModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AuthenticationModule,
    CoreModule,
    RootStoreModule,
    ModalsModule
  ],
  providers: [
    FriendService,
    Database,
    Firebase,
    BaseHttpService,
    GApiWrapper,
    BackendService,
    QuestionsService,
    TimerService,
    CurrentUser,
    MobileService,
    InsideHomeScreenNavigation,
    AuthService,
    FacebookShareService,
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: APP_INITIALIZER,
      useFactory: amplitudeInit,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
