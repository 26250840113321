import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, Logger, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { Observable, of, from, forkJoin, throwError } from 'rxjs';
import { map, catchError, pluck, tap, mergeMap, withLatestFrom, switchMap, concatMap, take, filter, finalize, delay } from 'rxjs/operators';
import { BaseHttpService } from '@services/base.service';
import { Connection } from '@models/connection.model';
import { GroupRequest, Group } from '@models/group.model';
import { MessageService } from '../../shared/modules/message/message.service';
import { Router } from '@angular/router';
import { sortComparerFullName } from '@store/entity/entity-metadata';
import { sortComparerName } from '../entity/entity-metadata';


@Injectable()
export class GroupDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private service: BaseHttpService, private messageService: MessageService) {
    super('Group', http, httpUrlGenerator);
  }

  getAll(): Observable<Group[]> {
    return this.loadGroups();
  }

  getWithQuery(params: string | QueryParams | any): Observable<Connection[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(group: GroupRequest): Observable<Group> {
    return this.service.call('POST', '/team_group/create_team_group', {...group})
    .pipe(catchError(({error})=> throwError(error)));
  }

  update(group: Update<GroupRequest>): Observable<Group> {
    return this.service.call('PUT', '/team_group/update_group_settings', {...group.changes})
    .pipe(catchError(({error})=> throwError(error)));
  }

  delete(team_group_key: number | string): Observable<number | string> {
    return this.service.call('DELETE', '/team_group/delete_group', {team_group_key})
    .pipe(catchError(err=> throwError(err)));
  }

  leave(team_group_key: number | string): Observable<number | string> {
    return this.service.call('DELETE', '/team_group/leave_group', {team_group_key})
    .pipe(catchError(err=> throwError(err)));
  }

  loadGroups(): Observable<Group[]> {
    return this.service.call('GET', '/team_group/get_all_groups')
    .pipe(
      pluck('items'),
      catchError(err => of([])),
      map(result => {
        return (result||[]).sort(sortComparerName) || [];
      }));
  }

  loadGroupInvites(): Observable<Group[]> {
    return this.service.call('GET', '/team_group/get_all_pending_invitations')
    .pipe(
      pluck('items'),
      catchError(err => of([])),
      map(result => {
        return (result||[]).sort(sortComparerName) || [];
      }));
  }

  loadGroupUsers(team_group_key: number | string): Observable<Connection[]> {
    return this.service.call('GET', '/team_group/get_all_users_in_group', {team_group_key})
    .pipe(
      pluck('items'),
      map(result => (result||[]).map(item=> {
        item = Connection.maptoConnection(item);
        return {...item, connection_type: 'friend' };
      }).sort(sortComparerFullName) || []),
      catchError(err => of([]))
    );
  }

  loadGroupUserInvites(team_group_key: number | string): Observable<Connection[]> {
    return this.service.call('GET' , '/team_group/get_all_invites_in_group', {team_group_key})
    .pipe(
      pluck('items'),
      map(result => (result||[]).map(item => {
        item = Connection.maptoConnection(item);
        return {...item, connection_type: 'friend' };
      }).sort(sortComparerFullName) || []),
      catchError(err => of([]))
    );
  }


  inviteUserstoGroup(group: GroupRequest): Observable<any> {
    return this.service.call('POST', '/team_group/invite_users_to_team_group', {...group})
    .pipe(catchError(err=> throwError(err)));
  }

  removeUsersfromGroup(group: GroupRequest): Observable<any> {
    return this.service.call('POST', '/team_group/remove_user_from_team_group', {...group})
    .pipe(catchError(err=> throwError(err)));
  }

  acceptInviteGroup(team_group_key: string): Observable<any> {
    return this.service.call('POST', '/team_group/accept_team_group_invite', {team_group_key})
    .pipe(catchError(err=> throwError(err)));
  }

  rejectInviteGroup(team_group_key: string): Observable<any> {
    return this.service.call('POST', '/team_group/reject_team_group_invite', {team_group_key})
    .pipe(catchError(err=> throwError(err)));
  }  
}

@Injectable()
export class GroupCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: GroupDataService, private router: Router, private message: MessageService) {
    super('Group', elementsFactory);
  }  

  loadGroups() {
    let requests: Observable<any>[] = [
      this.load(),
      this.dataService.loadGroupInvites()
    ];
    return forkJoin(requests)
    .pipe(tap(([data, group_invites]) => {        
        this.setData({group_invites});
      }));
  }

  setData(additional: any): Observable<any> {
    let queryParams: any = { additional };
    return this.getWithQuery(queryParams);
  }

  loadAllGroupConnections(groupId: string) {
    forkJoin(
      this.loadGroupInvites(groupId),
      this.loadGroupUsers(groupId)
    )
    .subscribe();
  }

  loadGroupUsers(groupId: number | string): Observable<Connection[]> {    
    return this.dataService.loadGroupUsers(groupId)
    .pipe(withLatestFrom(this.collection$.pipe(take(1),pluck('groupConnections'))),
    switchMap(([groupConnections, collection]:[any[], any[]]) => {      
        this.setData({groupConnections:{...collection, [groupId]: groupConnections}})
        return of(groupConnections);
    }));
  }

  loadGroupInvites(groupId: number | string): Observable<Connection[]> {    
    return this.dataService.loadGroupUserInvites(groupId)
    .pipe(withLatestFrom(this.collection$.pipe(take(1),pluck('groupInvitesConnections'))),
    switchMap(([groupInvitesConnections, collection]:[any[], any[]]) => {      
        this.setData({groupInvitesConnections:{...collection, [groupId]: groupInvitesConnections}})
        return of(groupInvitesConnections);
    }));
  }

  leave(team_group_key: string): Observable<number | string> {
    return this.dataService.leave(team_group_key).pipe(
      tap(() => this.loadGroups()),
      tap(()=> this.router.navigate(['connections', 'groups'])));
  }

  inviteUserstoGroup(group: GroupRequest): Observable<any> {
    return this.dataService.inviteUserstoGroup(group).pipe(
      tap(() => this.loadGroups()),
      tap(() => this.loadAllGroupConnections(group.team_group_key)));
  }

  removeUsersfromGroup(group: GroupRequest): Observable<any> {
    return this.dataService.removeUsersfromGroup(group).pipe(
      tap(() => this.loadGroups()),
      tap(() => this.loadAllGroupConnections(group.team_group_key)));
  }

  rejectInviteGroup(team_group_key: string): Observable<any> {
    return this.dataService.rejectInviteGroup(team_group_key).pipe(
      tap(() => this.loadGroups()));
  }

  acceptInviteGroup(team_group_key: string, showMsg?, redirect = false): Observable<any> {
    return this.dataService.acceptInviteGroup(team_group_key).pipe(
      tap(() => this.loadGroups()),
      tap(() =>  this.loadAllGroupConnections(team_group_key)),
      map(response => {        
        if (response && !response.error) {
          if (showMsg)
            this.message.notify('success', 'Team Invitation is accepted Successfully!');
          if (team_group_key && redirect)
            this.router.navigate(['connections', 'group', team_group_key]);        
        }
        else {
          if (showMsg)
            this.message.notify('danger', response.error, 'Team Invitation is Not accepted Successfully!');
        }
      }),
      
      catchError((err) => {
        if (showMsg)
          this.message.notify('danger', err.error.error.message, 'Team Invitation is Not accepted Successfully!');
        return throwError(err);
      })
    )
  }

  
  setSelectedGroupId(selectedGroupId : string) {
    this.setData({selectedGroupId});
  }
  
  getGroup(groupId): Observable<Group> {
    return this.entityMap$.pipe(pluck(groupId));
  }

  get group_invites$(): Observable<any> {
    return this.collection$.pipe(pluck('group_invites'));
  }

  get selectedGroupId$(): Observable<string>  {
    return this.collection$.pipe(pluck('selectedGroupId'));
  }

  get selectedGroup$(): Observable<Group> {
    return this.collection$.pipe(pluck('selectedGroupId'), switchMap(id=> this.getGroup(id)));
  }

  get allConnections$(): Observable<Connection[]> {
    return this.collection$.pipe(map(({groupConnections, groupInvitesConnections}: any) => {
      let connections: any[] = <Connection[]>(Object.values(groupConnections) || []);      
      let invites: any[] = <Connection[]>(Object.values(groupInvitesConnections) || []);
      return [...connections, ...invites ].reduce((acc, curr)=> [...acc,...curr], []);
    }));
  }

  get selectedGroupConnections$(): Observable<Connection[]> {    
    return this.collection$.pipe(map(({selectedGroupId, groupConnections} :any ) => (groupConnections||{}) [selectedGroupId] || []));
  }

  get selectedGroupInvitesConnections$(): Observable<Connection[]> {
    return this.collection$.pipe(map(({selectedGroupId, groupInvitesConnections} :any ) => (groupInvitesConnections||{}) [selectedGroupId] || []));
  }

}
