import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable, EventEmitter } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, Logger, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { Observable, of, from, forkJoin, throwError } from 'rxjs';
import { map, catchError, pluck, tap, mergeMap, withLatestFrom, switchMap, concatMap, take, filter, finalize } from 'rxjs/operators';
import { BaseHttpService } from '@services/base.service';
import { Connection, ConnectionInvite } from '@models/connection.model';
import { MessageService } from '../../shared/modules/message/message.service';
import { AppEntityServices } from '../entity/entity-services';
import { Router } from '@angular/router';
import { sortComparerFullName } from '@store/entity/entity-metadata';


@Injectable()
export class ConnectionDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private service: BaseHttpService) {
    super('Connection', http, httpUrlGenerator);
  }

  getWithQuery(params: string | QueryParams | any): Observable<Connection[] | any[] | any> {
    const { data } = params;
    return of(data || []);
  }

  loadFriends(): Observable<any> {
    return this.service.call('GET', '/friend/get_all_friends')
      .pipe(
        pluck('items'),
        map(result => 
          result.map(item => {
            item = Connection.maptoConnection(item);
            return { ...item, connection_type: 'friend' };
          }).sort(sortComparerFullName) || []),
        catchError(err => of([]))
      );
  }

  loadOutgoingFriends(): Observable<any> {
    return this.service.call('GET', '/friend/get_all_outgoing_invites')
      .pipe(
        pluck('items'),
        map(result =>
          result.map(item => {
            item = Connection.maptoConnection(item);
            return { ...item, connection_type: 'outgoing_invite' };
          }).sort(sortComparerFullName) || []),
        catchError(err => of([]))
      );
  }

  loadIncomingFriends(): Observable<any> {
    return this.service.call('GET', '/friend/get_all_incoming_invites')
      .pipe(
        pluck('items'),
        map(result =>
          result.map(item => {
            item = Connection.maptoConnection(item);
            return { ...item, connection_type: 'incoming_invite' };
          }).sort(sortComparerFullName) || []),
        catchError(err => of([]))
      );
  }

  inviteUser(user: Connection | ConnectionInvite): Observable<any> {
    return this.service.call('POST', '/friend/send_invite', { ...user })
      .pipe(
        map(result => {
          if (result && !result.code) {
            return { ...user, connection_type: 'outgoing_invite', inviteLastSent: new Date() };
          }
          return result;
        }),
        catchError(err=> throwError(err))
      );
  }

  acceptInvite(inviter_key: string): Observable<any> {
    return this.service.call('POST', '/friend/accept_invite', { inviter_key })
    .pipe(catchError(err=> throwError(err)));
  }

  deleteFriend(friend_key: string): Observable<any> {
    return this.service.call('POST', '/friend/delete_friend', { friend_key: friend_key })
    .pipe(catchError(err=> throwError(err)));
  }

  cancelInvite(email: string): Observable<any> {
    return this.service.call('POST', '/friend/cancel_invite', { email })
    .pipe(catchError(err=> throwError(err)));
  }

  rejectInvite(inviter_key: string): Observable<any> {
    return this.service.call('POST', '/friend/reject_invite', { inviter_key })
    .pipe(catchError(err=> throwError(err)));
  }
}

@Injectable()
export class ConnectionCollectionService extends EntityCollectionServiceBase<any> {
  _filterConnections = new EventEmitter<any>();
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private router: Router, private dataService: ConnectionDataService, private message: MessageService) {
    super('Connection', elementsFactory);
  }

  setData(additional: any): Observable<any> {
    let queryParams: any = { additional };
    return this.getWithQuery(queryParams);
  }  

  loadAllConnections(): Observable<any> {
    let requests: Observable<any>[] = [
      this.dataService.loadFriends(),
      this.dataService.loadIncomingFriends(),
      this.dataService.loadOutgoingFriends()
    ];
    return forkJoin(requests)
      .pipe(
        map(([friends, incoming_invites, outgoing_invites]) => {
          let emails: string[] = [...friends, ...incoming_invites].map(item => item.email);
          outgoing_invites = outgoing_invites.filter(item => !emails.includes(item.email));
          let data = [...friends, ...incoming_invites, ...outgoing_invites];
          this.setData({ friends, incoming_invites, outgoing_invites }); // set as Additional Data
          this.getWithQuery({ data }); // set Entities with All connections data       
          return { friends, incoming_invites, outgoing_invites };
        }),
        tap((res) => {
          this.setLoaded(true);
        }),
        catchError(err => of([]))
      );
  }

  inviteConnection(user: Connection | ConnectionInvite, showMsg?: boolean): Observable<any> {
    return this.dataService.inviteUser(user)
      .pipe(
        map(response => {          
          if (!showMsg) return response;
          if (response && !response.error) {
            this.message.notify('success', 'Invitation is sent Successfully!');
          } else {
            this.message.notify('danger', response.error, 'Invitation is Not sent Successfully!');
          }
          return response;
        }),
        tap(() => this.loadAllConnections()),
        catchError((err) => {          
          if (showMsg)
            this.message.notify('danger', err.error.error.message, 'Invitation is Not sent Successfully!');
          return throwError(err.error.error.message);
        }),
      );
  }

  acceptInvite(inviter_key: string, showMsg?, redirect = false, user1?): Observable<any> {
    return this.dataService.acceptInvite(inviter_key).pipe(
      map(response => {        
        if (response && !response.error) {
          if (showMsg)
            this.message.notify('success', 'Invitation is accepted Successfully!');
          if (user1 && redirect) {
            let query: any = { user1, user2: inviter_key, accept_invite: null }
            this.router.navigate(['profile', 'results'], { queryParams: query })
          }
        }
        else if (showMsg)          
          this.message.notify('danger', response.error, 'Invitation is Not accepted Successfully!');
        
        return response;
      }),
      tap(() => this.loadAllConnections()),
      catchError((err) => {
        if (showMsg)
          this.message.notify('danger', err.error.error.message, 'Invitation is Not accepted Successfully!');
        return throwError(err);
      })
    )
  }

  deleteConnections(users: Connection[]): Observable<any> {
    let requests = users.map((user: Connection) => {
      let req;
      switch (user.connection_type) {
        case 'friend':
          req = this.dataService.deleteFriend(user.id);
          break;

        case 'incoming_invite':
          req = this.dataService.rejectInvite(user.id)
          break;

        case 'outgoing_invite':
          req = this.dataService.cancelInvite(user.email);
          break;
      }
      return req.pipe(map(() => user), catchError((error) => of({ user, error })));
    })
    return forkJoin(requests).pipe(
      map(([...result]) => {        
        let str = result.length > 1 ? '(s) are' : ' is';
        let users: string[] = result.filter((item: any) => !item.error).map((item: any) => item.id);
        let errorUsers = result.filter((item: any) => item.error != null);
        if (errorUsers && errorUsers.length > 0)
          this.message.notify('warning', `${result.length > 1 ? 'Some ' : ''}Connection${str} not Removed Successfully!`);
        else if (users.length > 0)
          this.message.notify('success', `Connection${str} Removed Successfully!`);
        else
          this.message.notify('danger', `Connection${str} Not Removed Successfully!`);

        return result;
      }),
      tap(() => this.loadAllConnections()),
      catchError((err) => {        
        let str = users.length > 1 ? '(s) are' : ' is';
        this.message.notify('danger', err.error.error.message, `Connection${str} Not Removed Successfully!`);
        return throwError(err)
      }),
    );
  }

  rejectInvite(inviter_key: string): Observable<any> {
    return this.dataService.rejectInvite(inviter_key).pipe(tap(() => this.loadAllConnections()));
  }

  getConnection(ConnectionId): Observable<Connection> {
    return this.entityMap$.pipe(pluck(ConnectionId));
  }

  filterConnections(searchValue: any) {
    this.setData({ filterConnections: searchValue });
    this._filterConnections.emit(searchValue);
  }

  get filterConnections$(): EventEmitter<any> {
    return this._filterConnections;
  }

  get friends$(): Observable<Connection[]> {
    return this.collection$.pipe(pluck('friends'));
  }

  get incoming_invites$(): Observable<Connection[]> {
    return this.collection$.pipe(pluck('incoming_invites'));
  }

  get outgoing_invites$(): Observable<Connection[]> {
    return this.collection$.pipe(pluck('outgoing_invites'));
  }

}
