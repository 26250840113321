import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { notificationData } from '../../message.service';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss']
})
export class NotificationMessageComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<NotificationMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: notificationData) {
  }

  ngOnInit() {
  }

  sendAction(action): void {
    this.dialogRef.close(action);
  }

  close() {
    this.dialogRef.close();
  }

}
