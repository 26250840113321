import { Injectable } from '@angular/core';

@Injectable()
export class TimerService {

  started_at: Date;
  stopped_at: Date;

  constructor() {}

  start() {
    if (this.started_at) {
      return;
    }
    return this.started_at = new Date();
  }

  stop() {
    if (this.stopped_at) {
      return;
    }
    return this.stopped_at = new Date();
  }

  duration_in_seconds() {
    if (this.stopped_at && this.started_at) {
      const diffInSec: number = ( Date.parse(this.stopped_at.toString()) - Date.parse(this.started_at.toString()) ) / 1000;
      return diffInSec;
    } else {
      return 0;
    }
  }

}
