import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Pipe({
  name: 'search'
})
export class searchPipe implements PipeTransform {

  transform(value: any, args?: {searchText: string, fields: string[]}): any {
    console.log(args + ' ' + value);
    if (args == null || isNullOrUndefined(args.searchText) || args.searchText === '') {
        return null;
    }
    return value.filter((item) => {
      let result = args.fields.reduce((acc,curr) => {
          return acc = (acc || item[curr].includes(args.searchText));
      }, false);

      return result;
    });
  }

}
