import { Component, OnInit, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, takeUntil, catchError, take, switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { InsideHomeScreenNavigation } from '@services/navigation.service';
import { MessageService } from '@message/message.service';
import { combineLatest, of } from 'rxjs';
import { environment } from '@env/environment';
import { AppEntityServices } from '@store/entity/entity-services';
import { LoginType } from '@store/services/user.service';
declare var $: any;

@Component({
  selector: 'app-questions-register',
  templateUrl: './questions-register.component.html',
  styleUrls: ['./questions-register.component.css']
})

export class QuestionsRegisterComponent implements OnInit, OnDestroy {
  showForm: boolean = false;
  registerForm: any
  registered: boolean = false;
  allowTestDrive: boolean = false;
  pageIndex: number = 0;
  toogleSwithcButton: any;
  @Output() loggedIn: EventEmitter<any> = new EventEmitter();

  private ngUnsubscribe: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private navigation: InsideHomeScreenNavigation,
    private appEntityServices: AppEntityServices,
    private messageService: MessageService
  ) {

    this.registerForm = fb.group({
			email: ['', Validators.required],
      lname: ['', Validators.required],
      fname: ['', Validators.required],
      password: ['', Validators.required]
		})
  }

  ngOnInit() {
    this.getRouteParams();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getRouteParams() {
    combineLatest(this.route.params, this.route.queryParams)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(([params,queries])=>{
        this.pageIndex = +params.page || 0;
        this.allowTestDrive = (queries.testdrive && queries.testdrive.toLowerCase() === 'true');
        if (queries.email)
          this.registerForm.get('email').setValue(queries.email);
    });
  }

  get showFacebook(): boolean {
    return environment.enable_facebook;
  }

  reEnterEmail(){
    this.registered = false;
    this.registerForm = this.fb.group({
			email: ['', Validators.required],
      lname: ['', Validators.required],
      fname: ['', Validators.required],
      password: ['', Validators.required]
		})
  }

  doRegister() {
    const { email, password, fname, lname } = this.registerForm.value;
    const displayName = [fname, lname].join(' ').trim();
    const registerBtn = $('#register-button');

    registerBtn.button('loading');

    this.appEntityServices.signUp(email, password, fname, lname)
      .pipe(finalize(() => registerBtn.button('reset')))
      .subscribe((res) => {        
        if (res.code)
          this.messageService.notify('danger', res.message); 
        
        else if (!res.code && !res.error && res.user) {
          this.loggedIn.emit({name: res.user.displayName , email, register: true})
          this.registered = true;
        }
      });
  }

  signInWithGoogle() {
    const loginGoogleBtn = $('#login-google-button');

    loginGoogleBtn.button('loading');

    this.registered = false;

    this.appEntityServices.userService.login(LoginType.Google)
      .pipe(finalize(() => loginGoogleBtn.button('reset')))
      .subscribe((res: any) => {
        if (!res.code && !res.error && res.user) {
          const { displayName, email } = res.user;
          this.registered = true;
          this.loggedIn.emit({name: displayName , email, register: true});
        }
      });
  }

  signInWithFacebook() {
    const loginFacebookBtn = $('#login-facebook-button');

    loginFacebookBtn.button('loading');

    this.registered = false;
    this.appEntityServices.login(LoginType.Facebook)
      .pipe(finalize(() => loginFacebookBtn.button('reset')))
      .subscribe((res) => {
        if (!res.code && !res.error && res.user) {
          const { displayName, email } = res.user;
          this.registered = true;
          this.loggedIn.emit({name: displayName , email, register: true});
        }
      });
  }

  togglePasswordVisiblity(passwordField) {
    if (passwordField.type == 'text')
      passwordField.type = 'password';
    else
      passwordField.type = 'text';
  }

  goPage(page) {
    let query = {login: false, register: false};
    query[page]=true;
    this.navigation.goToEnneagramTest(this.navigation.pageIndex, query);
  }

  changedToogleButton(toogle) {
    this.toogleSwithcButton = toogle;
  }

}
