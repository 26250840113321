import * as moment from 'moment';
import * as _ from 'lodash';

export class ConnectionInvite {
  email: string;
  first_name: string;
  last_name: string;
  image_url?: string;
}
export class Connection {
    UUID?: string;
    id: string;    
    full_name: string;
    last_name: string;
    name: string;
    email: string;
    image_url: string  = 'assets/images/default-avatar.svg';
    starring_roles: string;
    instinctualType: 'SP' | 'SO' | 'SX';
    instinctualTypeFull: string;
    instinct_stack: string[] = Array.from({length: 3}).map(() => '');
    tritype: number[] = Array.from({length: 3}).map(() => null);
    tritype_full: string;
    tritypeDescription: string;
    enneagramType: number;
    score: number[] = Array.from({length: 10}).map(() => null);
    connection_type?: ('friend'|'incoming_invite'|'outgoing_invite'|'invite') = 'friend';    
    surveyAnswersDefault: string;    
    guessPrecision: number;
    isGuess: boolean;
    new: boolean;
    inviteLastSent?: Date;
    friend_type?: string;
    invite_status?: string;


    public static maptoConnection(item): Connection {
      let tritype: string[] = _.isString(item.tritype) ? item.tritype.split(',') : item.tritype;
        return {
          ...item,
          id: item.id || item.email,
          user_id: item.id || item.email,
          image_url: item.image_url || 'assets/images/default-avatar.svg',
          full_name: [item.name, item.last_name].join(' ').trim(),
          enneagramType: tritype && tritype.length > 0 ? tritype[0] : '',
          tritype_full: tritype && tritype.length > 0 ? tritype.join('-') : '',
          tritype: tritype || [],
          instinctualTypeFull: (item.instinct_stack && item.instinct_stack.length) ? item.instinct_stack[0] : '',
          new: moment(item.inviteLastSent).add(7, 'days') > moment(),
          connection_type: item.connection_type || 'friend' 
        };
    }
}