import { TimerService } from './timer.service';
import { Question } from '@models/questions.model';
import { EnneagramForm } from '@models/enneagram-form.model';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import { Database } from './database.service';

@Injectable()
export class QuestionsService {    
    started_at: Date;
    stopped_at: Date;
    welcomeForm: EnneagramForm = new EnneagramForm();
    isSubmitted: boolean = false;
    isLoaded: boolean = false;
    survey_name: any;
    surveyID: any;
    firstName: any;
    lastName: any;
    email: any;
    list: Question[] = [];
    selectedPage: number = 0;
    sections: any;
    extra?: any;
    pagesNeededToDo = [];
    originalQuestion: any;
    

    constructor(){}

    setCurrentTest(test?: Partial<any>) {
        Object.assign(this, test);
    }

    init(testName, questions) {
        this.list = [];
        this.surveyID = null;
        this.survey_name = testName;
        this.isSubmitted = false;   
        (questions||[]).forEach((question) => {
            question = Question.mapToQuestion(question);
            // add to Questions evaluated variables
            if (question.extra_json && question.extra_json.length > 0) {
                this.eval(question.extra_json);
            }
            this.list.push(question);
            this[question.oid] = question;
        });
        this.getPagesThatNeedDoing();

        if (questions.length > 0)
            this.isLoaded = true;
    }

  startTimer() {
    if (this.started_at) {
      return;
    }
    return this.started_at = new Date();
  }

  stopTimer() {
    if (this.stopped_at) {
      return;
    }
    return this.stopped_at = new Date();
  }

    duration_in_seconds() {
        if (this.stopped_at && this.started_at) {
        const diffInSec: number = ( Date.parse(this.stopped_at.toString()) - Date.parse(this.started_at.toString()) ) / 1000;
        return diffInSec;
        } else {
        return 0;
        }
    }

    eval(json) {
        eval(json);
    }

    processSurveyAnswers(response) {
        const that = this;
        let extra = null;

        if (response.items && response.items.length > 0) {
            let sortedResponse = response.items.sort((a, b) => (a.updated <= b.updated) ? -1 : 1);
            let question = sortedResponse[sortedResponse.length - 1];
            this.originalQuestion = question;
            let answers = JSON.parse(question.answers);
            extra = JSON.parse(question.extra);

            that.surveyID = question.id;
            that.surveyID = question.id;
            that.extra = extra;
            that.welcomeForm.first_name = extra.first_name;
            that.welcomeForm.last_name = extra.last_name;
            that.welcomeForm.email = extra.email;

            answers.forEach(item => {
                if (item && item.oid && that[item.oid]) {
                    that[item.oid].originalAnswerArray = item.value;
                }
            });
        }
        return this.getUnansweredPage();
    }


    submitAnswers(params?) {
        if (!params) {
            this.stopTimer();
            this.welcomeForm.duration_in_seconds = this.duration_in_seconds();
            const answers = this.generateAnswersDict();
            let extra = this.generateExtra(this.welcomeForm);
            let survey_name = this.survey_name || environment.enneagram_default_testName;

            params = {
                'answers': JSON.stringify(answers),
                'extra': JSON.stringify(extra),
                'survey_name': survey_name
            };

        }

        return { ...params };
    }

    updateAnswers(id, params?) {
        if (!params) {
            this.stopTimer();
            this.welcomeForm.duration_in_seconds = this.duration_in_seconds();
            const answers = this.generateAnswersDict();
            let extra = this.generateExtra(this.welcomeForm);
            let originalextra = (this.originalQuestion) ? JSON.parse(this.originalQuestion.extra) : extra;
            console.log(originalextra['duration_in_seconds'], extra['duration_in_seconds']);
            extra['duration_in_seconds'] = originalextra['duration_in_seconds'] + extra['duration_in_seconds'];
            console.log(extra['uuid'], originalextra['uuid']);
            extra['uuid'] = originalextra['uuid'];
            console.log(originalextra, extra);
            extra = this.mergeDictionarys(extra, originalextra);
            let scoreArray = [];
            [4, 5, 6, 7, 8].forEach((page) => {
                scoreArray.push(extra[`myScoringQ${page}`])
            });
            let total_array = this.addListOfArrays(scoreArray);
            extra['myScoringTotal'] = total_array;
            if (total_array)
                total_array = total_array.slice(0, 9);
            extra['winning'] = this.orderByType(total_array, 1)[0];

            let survey_name = this.survey_name || environment.enneagram_default_testName;
            params = {
                'answers': JSON.stringify(answers),
                'extra': JSON.stringify(extra),
                'survey_name': survey_name
            };
        }

        return { ...params, id };
    }

    getUnansweredPage() {
        let pages = this.getPages();
        let selectedPage = 0;
        for (let page of pages) {
            selectedPage = page;
            if (!this.skipAnsweredPage(page)) {
                break;
            }
        }
        return selectedPage;
    }

    getPages() {
        let pages = this.list.reduce((acc, curr) => {
            let page = +curr.page;
            if (page >= 0 && !acc.includes(page))
                acc = [...acc, page];
            return acc;
        }, []);
        return pages.sort((a, b) => a < b ? -1 : 1);
    }

    get pageContents() {
        let pages = this.getPages().reduce((acc, curr) => {
            let questions = this.list.filter(item => +item.page === +curr) || [];
            questions = questions.sort(function (a, b) { return (+a.qindex > +b.qindex) ? 1 : ((+b.qindex > +a.qindex) ? -1 : 0); });
            questions = questions.map(item => {
                this.generateScore(item);
                return this.generateAnswer(item);
            })
            acc[curr] = questions || [];
            return acc;
        }, {})
        return pages;
    }


    enneagramMatrix(enneType) {  // 1  = [1,0,0,0,0,0,0,0,0,0]
        var temp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        temp[enneType - 1] = 1;
        return temp;
    }

    mapScore(score, mapArray, answerDict) {
        var temp = []
        mapArray.forEach((item, i) => {
            temp.push(answerDict[String(score[item])]);
        })
        return temp;
    }

    mapAnswer(score_dict, mapArray, answerDict) {
        let temp = []
        mapArray.forEach((item, i) => {
            temp.push(this.pickOne(answerDict[String(score_dict[item])]));
        })
        return temp;


    }

    getComplexAnswer(pageNum, indexNum, num, possibleScores) {
        console.log(pageNum, indexNum, num);
        console.log(`possible scores:${JSON.stringify(possibleScores)}`);
        let score_dict = this.orderByType(this.getPageResults(pageNum), indexNum);
        console.log(`score dict: ${score_dict}`);
        let return_value = this.mapAnswer(score_dict, num, possibleScores);
        console.log(`possible scores:${JSON.stringify(possibleScores)}`);
        return return_value;
    }

    getComplexScore(pageNum, indexNum, num, possibleScores) {
        console.log(pageNum, indexNum, num);
        console.log(`possible scores:${JSON.stringify(possibleScores)}`);
        let score_dict = this.orderByType(this.getPageResults(pageNum), indexNum);
        console.log(`score dict: ${score_dict}`);
        let return_value = this.mapScore(score_dict, num, possibleScores);
        console.log(`possible scores:${JSON.stringify(possibleScores)}`);
        return return_value;
    }

    getDictItemsByTypes(dict, enneTypes) {
        let temp = [];
        enneTypes.forEach((value) => {
            temp.push(dict[value])
        })
        return temp;
    }

    pickOne(myArray) {
        if (!myArray) return null;
        let index = Math.floor(Math.random() * myArray.length);
        return myArray.splice(index, 1)[0];
    }

    result() {
        let total_array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.list.forEach((value, i) => {
            if (value.content === 'Last name') {
                this.lastName = value.originalAnswerArray;
            }
            if (value.content === 'First name') {
                this.firstName = value.originalAnswerArray;
            }
            if (value.content === 'Email goes here') {
                this.email = value.originalAnswerArray;
            }
            total_array = this.addArray(value.userScore, total_array);
        });
        return total_array;
    }
    getPageResults(page) {
        let total_array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        console.log('page', page);
        let questions = this.questionsForPage(page);
        let score_array = []
        questions.forEach((value, i) => {
            score_array.push(value.userScore);
        });
        return this.addListOfArrays(score_array);
    }

    IndexedAnswerFromPage(page, index) {
        this.orderByType(this.getPageResults(page), 1)
    }
    highestAnswerValue(question) {
        return question.answerArrayValues[question.answerArray[0]];
    }
    highestAnswerScore(question) {
        return question.answerArrayScores[question.answerArray[0]];
    }

    orderByType(array1, index0) {
        let array2 = [];
        if (!array1) {
            array1 = [];
        }
        array1.forEach((value, i) => {
            array2.push({ 'type': i + index0, 'value': value });
        });
        array2.sort(function (a, b) {
            return a['value'] - b['value'];
        });
        console.log(array2)
        let array3 = []
        array2.forEach((value) => {
            array3.push(value['type']);
        });

        return array3.reverse();
    }
    multArrray(array1, mult) {
        console.log('multiply', array1, mult);
        let copy_array1 = JSON.parse(JSON.stringify(array1));
        copy_array1.forEach((element, i) => {
            copy_array1[i] = copy_array1[i] * mult;
        });
        console.log(copy_array1);
        return copy_array1;
    }
    createZeroArray(lengthOfArray) {
        let temp = [];
        for (let i = 0; i < lengthOfArray; i++) {
            temp.push(0);
        }
        return temp
    }

    makeTriType(arrayList) {

        let emotional = this.createZeroArray(12);
        emotional[1] = arrayList[1];
        emotional[2] = arrayList[2];
        emotional[3] = arrayList[3];

        let mental = this.createZeroArray(12);
        mental[4] = arrayList[4];
        mental[5] = arrayList[5];
        mental[6] = arrayList[6];

        let instinctual = this.createZeroArray(12);
        instinctual[7] = arrayList[7];
        instinctual[8] = arrayList[8];
        instinctual[0] = arrayList[0];

        let highest_emotional = this.orderByType(emotional, 1)[0];
        let highest_mental = this.orderByType(mental, 1)[0];
        let highest_instinctual = this.orderByType(instinctual, 1)[0];
        let temp = this.createZeroArray(9)

        temp[highest_emotional - 1] = emotional[highest_emotional - 1];
        temp[highest_mental - 1] = mental[highest_mental - 1];
        temp[highest_instinctual - 1] = instinctual[highest_instinctual - 1];

        return this.orderByType(temp, 1).slice(0, 3);
    }

    addListOfArrays(arrayList) {
        let copy_array = JSON.parse(JSON.stringify(arrayList));
        let temp;

        if (copy_array && copy_array[0]) {
            temp = this.createZeroArray(copy_array[0].length);
        } else {
            temp = this.createZeroArray(9)
        }
        copy_array.forEach((value, i) => {
            temp = this.addArray(temp, value)
        });
        return temp;
    }

    addArray(array1, array2) {
        let copy_array2 = JSON.parse(JSON.stringify(array2));
        if (array1 == null) {
            array1 = this.createZeroArray(9);
        }
        array1.forEach((value, i) => {

            let temp;
            try {
                temp = copy_array2[i] || 0;
            } catch (error) {
                temp = 0;
            }
            if (temp === undefined) {
                temp = 0;
            }
            if (value === undefined) {
                value = 0;
            }
            let result = temp + value;
            try {
                copy_array2[i] = result;
            } catch (error) {
                if (copy_array2 == null) {
                    this.createZeroArray(12);
                }
            }
        });
        return copy_array2;
    }
    assignScoreByValue(oid, values) {
        let index_array = [];
        let question = this[oid];
        question.originalAnswerArray = values;
        if (values.constructor === Array) {
            values.forEach((value) => {
                this.generateAnswer(question).content.forEach((item, i) => {
                    if (value === item) {
                        index_array.push(i);
                    }
                });
            });
            question.userScore = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            return this.assignScore(question, index_array);
        } else {
            return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        }
    }

    assignScore(question, answerArray) {
        question.answerArray = answerArray;
        answerArray.forEach((answer, i) => {
            let temp_score = question.answerArrayScores[answer] || [];
            if (_.indexOf(Object.keys(question.score), "multiplier") > -1) {
                console.log("tempscore: ", temp_score, "question score:", question.score)
                temp_score = this.multArrray(temp_score, question.score.multiplier[i])
            } else {
                console.log("tempscore: ", temp_score, "answerArray", answerArray)
                temp_score = this.multArrray(temp_score, answerArray.length - i)
            }
            question.userScore = this.addArray(temp_score, question.userScore)
            console.log("userscore", question.userScore)
        });


    }
    contentForPage(page) {
        let question_for_page = [];
        let questions = this.questionsForPage(page);
        questions = questions.sort(function (a, b) { return (+a.qindex > +b.qindex) ? 1 : ((+b.qindex > +a.qindex) ? -1 : 0); });
        questions.forEach((item) => {
            this.generateScore(item);
            question_for_page.push(this.generateAnswer(item))
        });
        return question_for_page;
    }
    questionsForPage(page) {
        let questions = _.filter(this.list, function (item) {
            return item.page === String(page);
        });

        return questions;
    }

    moveNextPage(page) {
       
        let bad_question_array = [];
        let questions = this.questionsForPage(page);

        bad_question_array = questions.reduce((acc, curr) => {
            if ((curr.question_type !== 'label') && (curr.is_mandatory === true) &&
                (!curr.originalAnswerArray || ((Array.isArray(curr.originalAnswerArray) &&
                    (curr.originalAnswerArray.length < +curr.minPick || curr.originalAnswerArray.length > +curr.maxPick))))) {
                return [...acc, curr];
            }

            return [...acc];
        }, []);

        return bad_question_array;
    }

    getNextPage(presentIndex) {
        //console.log(presentIndex);
        let pages = this.getPages();
        pages = pages.sort((a, b) => a < b ? -1 : 1);
        let presentPage = pages[presentIndex];
        // console.log('presentIndex', presentIndex);
        let filterList = _.filter(this.pagesNeededToDo, function (num) {
            return num > presentPage;
        });
        filterList = filterList.sort((a, b) => a < b ? -1 : 1);

        if (filterList.length === 0) {
            return -1;
        } else {
            return _.indexOf(this.getPages(), _.first(filterList));
        }
    }

    getPagesThatNeedDoing() {
        let allQuestions = [];
        let uniquePages = [];
        let allPages = this.getPages();
        allPages.forEach((page) => {
            this.questionsForPage(page).forEach((question) => {
                if (question.question_type !== 'label') {
                    allQuestions.push(question);
                }
            });
        });
        let filterList = _.filter(allQuestions, function (question) {
            if (!question.originalAnswerArray || ((Array.isArray(question.originalAnswerArray) && (question.originalAnswerArray.length < question.minPick || question.originalAnswerArray.length > question.maxPick)))) {
                return true;
            }
            return false;
        });

        filterList.forEach((item) => {
            uniquePages.push(parseInt(item.page));
        })
        this.pagesNeededToDo = _.uniq(uniquePages);
        return this.pagesNeededToDo;

    }
    skipAnsweredPage(page) {
        let bad_question_array = [];
        let questions = this.questionsForPage(page);
        bad_question_array = questions.reduce((acc, curr) => {
            if ((curr.question_type !== 'label') &&
                (!curr.originalAnswerArray || ((Array.isArray(curr.originalAnswerArray) &&
                    (curr.originalAnswerArray.length < +curr.minPick || curr.originalAnswerArray.length > +curr.maxPick))))) {
                return [...acc, curr];
            }

            return [...acc];
        }, []);

        return bad_question_array.length === 0;
    }
    getAllAnswers() {
        let answer_dict = {}
        this.list.forEach((question) => {
            if (question.originalAnswerArray) {
                answer_dict[question.oid] = question.originalAnswerArray;
            }
        });
        return answer_dict;
    }

    generateScore(question) {
        let answerArray = [];
        if (!question.score || question.score == "undefined") {
            question.score = {};
        }

        if (typeof (question.score) === 'string') {
            if (question.score.indexOf('{') >= 0)
                question.score = JSON.parse(question.score) || {};
            else
                question.score = eval(question.score) || {};
        }

        if (typeof (question.score.values) === 'object') {
            for (let answer of question.score.values) {
                if (question.score.function === 'simple') {
                    if (answer.length > 0) {
                        answerArray.push(answer);
                    }
                } else {
                    let evaluated = eval(answer);
                    answerArray.push(evaluated);
                }
            };
            question.answerArrayScores = answerArray
        } else if (Array.isArray(question.score)) {
            for (let score of question.score) {

                if (typeof (score) === "string" && score.indexOf('{') >= 0) {
                    score = JSON.parse(score);
                    if (score.function == "simple") {
                        answerArray = score.values;
                    } else {
                        answerArray = eval(score.values);
                    }
                }
            }

            question.answerArrayScores = (answerArray.length > 0) ? answerArray : question.score;
        } else {
            question.answerArrayScores = eval(question.score.values) || [];
        }
        return question.answerArrayScores;
    }
    highestFromQuestion(question, type) {
        if (question.answerArray !== undefined) {
            if (type === 'score') {
                return question.answerArrayScores[question.answerArray[0]];
            } else {
                return question.originalAnswerArray[0];
            }
        }
    }
    mergeDictionarys(canBeOverwritten, overwriter) {
        let copyCanBeOverWritten = JSON.parse(JSON.stringify(canBeOverwritten));
        Object.keys(overwriter).forEach((kee) => {
            copyCanBeOverWritten[kee] = overwriter[kee];
        })
        return copyCanBeOverWritten;
    }

    generateAnswer(question) {
        if (!question.generatedContent) {
            let content = [];
            let placeholder = [];

            if (question.answers && typeof (question.answers.values) === 'object') {
                for (let answer of question.answers.values) {
                    if (question.answers.function === 'simple') {
                        if (answer.length > 0) {
                            content.push(answer);
                        }
                    } else {
                        let evaluated = eval(answer);
                        content.push(evaluated);
                    }
                };

                question.answerArrayValues = content;
            }
            else if (Array.isArray(question.answers)) {
                for (let answer of question.answers) {
                    if (typeof (answer) === 'string' && answer.indexOf('{') >= 0) {
                        answer = JSON.parse(answer);
                        if (answer.function === 'simple') {
                            content = answer.values;
                        } else {
                            try {
                                content = eval(answer.values);
                            } catch (err) {
                                content = eval(JSON.parse(answer).values);
                            }


                        }
                    }
                }

                question.answerArrayValues = (content.length > 0) ? content : question.answers;
                content = question.answerArrayValues;
            }
            else {
                if (question.answers) {
                    question.answerArrayValues = eval(question.answers.values);
                }

                content = question.answerArrayValues;
            }
            console.log(question);
            if (question.question_type === 'label') {
                content = question.content;
            }
            placeholder = question.content;
            let return_value = {
                mustdo: false,
                section: question.section,
                page: question.page,
                variable_name: question.oid,
                qindex: question.qindex,
                type: question.question_type,
                class: question.question_class,
                content: content,
                placeholder: placeholder,
                minPick: question.minPick,
                maxPick: question.maxPick,
                answers: question.originalAnswerArray || {},
                answers_other: question.originalAnswerArray || {},
                leftLabel: question.leftLabel,
                showScroll: question.showScroll || false
            }
            question.generatedContent = return_value;
        }
        return question.generatedContent;
    }

    getSectionPages(section) {
        let pages = [];
        this.list.forEach((item) => {
            if (item.section === section) {
                let page_number = parseInt(item.page);
                if (pages.indexOf(page_number) === -1) {
                    pages.push(page_number);
                }
            }
        })
        pages.sort(function (a, b) {
            return a - b;
        });
        return pages;
    }

    generateAnswersDict() {
        let list_array = [];
        this.list.forEach((item) => {
            list_array.push(this.getQuestionSurveyAnswer(item));
        })
        return list_array;
    }
    getQuestionSurveyAnswer(question) {
        if (question.originalAnswerArray) {
            return {
                'value': question.originalAnswerArray,
                'key': question.id,
                'oid': question.oid
            };
        }
    }
    generateExtraScore(originalreturn_value) {
        let return_value = JSON.parse(JSON.stringify(originalreturn_value));

        this.getPages().forEach((item) => {
            return_value[`myScoringQ${item}`] = this.getPageResults(item);
        });

        let scoringValues = this.getScoringByArray(this.result(), 1);
        Object.keys(scoringValues).forEach((item) => {
            let keyAsNumber = parseInt(item, 10);
            if (keyAsNumber > 12) {
                delete scoringValues[item];
            }
        });


        return_value['myScoringTotal'] = JSON.stringify(scoringValues);
        let total_array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        let section2 = this.getSectionPages('2');
        if (section2.length === 0) {
            section2 = this.getSectionPages('3');
        }

        console.log(section2);
        section2.forEach((value) => {
            let temp = this.getPageResults(value);
            total_array = this.addArray(temp, total_array);
        });
        total_array = total_array.slice(0, 9);
        return_value['winning'] = this.orderByType(total_array, 1)[0];
        this.extra = return_value;
        return return_value;
    }
    generateExtra(welcome_form) {
        try {
            if (welcome_form.first_name.length < 1 && this.firstName.length > 1) {
                welcome_form.first_name = this.firstName;
            }
        } catch (err) { }
        try {
            if (welcome_form.email.length < 1 && this.email.length > 1) {
                welcome_form.email = this.email;
            }
        } catch (err) { }
        try {
            if (this.firstName.length > 1) {
                welcome_form.last_name = this.lastName;
            }
        } catch (err) { }

        let return_value = {
            'uuid': welcome_form.user_uuid,
            'last_name': welcome_form.last_name,
            'first_name': welcome_form.first_name,
            'user_id': welcome_form.user_id,
            'is_from_team_app': welcome_form.is_from_team_app,
            'email': welcome_form.email,
            'team_id': welcome_form.team_id,
            'duration_in_seconds': welcome_form.duration_in_seconds,
            'client': welcome_form.client,
            'user1': welcome_form.user1,
            'user2': welcome_form.user2,
            'usertouser': welcome_form.usertouser,
            'image_url': welcome_form.image_url || 'assets/images/default-avatar.svg',
            'avatar': welcome_form.image_url || 'assets/images/default-avatar.svg',
            ...welcome_form.extra            
        }
        return_value = this.generateExtraScore(return_value);
        return return_value;
    }
    getScoringByArray(array3, index_number) {
        let array2 = {};
        let index0 = 1;
        array3.forEach((value, i) => {
            array2[`${i + 1}`] = value;
        });
        return array2;
    }
    computeNormalizedScore(rawScore) {
        if (typeof rawScore !== 'object') {
            rawScore = JSON.parse(rawScore);
        }
        const maxScore = _.max(_.values(rawScore));
        _.each(rawScore, (value, key) => rawScore[key] = Math.round((+value / maxScore) * 100) / 100);
        return rawScore;
    }

    /*getYourAnswers() {
        let allQuestions: any = [] ;
        if (this['q1-1'] !== undefined) {
            allQuestions.push({
                'id' : 1,
                'oid' : 'q1',
                'question': 'Pick 5 adjectives that describe you best',
                'answer': [this['q1-1'].originalAnswerArray,this['q1-2'].originalAnswerArray,this['q1-3'].originalAnswerArray,this['q1-4'].originalAnswerArray,this['q1-5'].originalAnswerArray].join(" , ")
            });
        }
        this.list.forEach((item) => {
            let id = item.oid ? item.oid.replace('q','') : '0';
            id = id.replace('-','.');
            let temp = {
                'id' : +id,
                'oid': item.oid,
                'question' : this[item.oid].content,
                'answer':  this[item.oid].originalAnswerArray
            };
            if(temp.id >= 2) {
                allQuestions.push(temp);
            }
        });

        allQuestions.sort((a, b) => a.id - b.id);
        return allQuestions;
    }
    getWhatYouGaveUs() {
        let allQuestions: any = [];
        let questionsForSection = ['q2-1', 'q2-2', 'q2-3', 'q2-4', 'q3-1', 'q3-2', 'q3-3', 'q3-4', 'q3-5', 'q3-6'];
        questionsForSection.forEach((question) => {

            try {
                let temp: any;
                temp = {
                    'id' : question,
                    'question' : this[question].content,
                    'answer': this[question].originalAnswerArray
                };
                allQuestions.push(temp);
            } catch (err) {
                console.log(err);
            }
        })
        try {
            allQuestions.push({
                'id' : 'q1',
                'question': 'Pick 5 adjectives that describe you best',
                'answer': [this['q1-1'].originalAnswerArray,this['q1-2'].originalAnswerArray,this['q1-3'].originalAnswerArray,this['q1-4'].originalAnswerArray,this['q1-5'].originalAnswerArray].join("<br/>")
            });
        } catch ( err ) {
            console.log(err);
        }
        allQuestions.sort((a, b) => a.id < b.id ? -1 : 1);
        return allQuestions;
    }*/
}
