// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import base from './base';

export const environment = {
  ...base,
  firebase: {
    apiKey: 'AIzaSyDZrBtAwbSci6r9LgRM-I6OQSFwaG--z6M',
    authDomain: 'enneagram-359016.firebaseapp.com',
    databaseURL: 'https://enneagram-359016.firebaseapp.com',
    projectId: 'enneagram-359016',
    storageBucket: 'enneagram-359016.appspot.com',
    messagingSenderId: '424178159889',
    appId: "1:424178159889:web:33dfc2eed10a8e8c123cb0"
  },
  production: true
};
