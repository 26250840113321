import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-results-modal',
  templateUrl: './results-modal.component.html',
  styleUrls: ['./results-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultsModalComponent implements OnInit {

  env = environment;
  constructor(
  ) {}

  ngOnInit(): void {
  }
}
