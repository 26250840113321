import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { finalize, takeUntil } from 'rxjs/operators';
import { Firebase } from '@services/firebase.service';
import { AppEntityServices } from '@store/entity/entity-services';

@Component({
  selector: 'app-complete-reset-password',
  templateUrl: './complete-reset-password.component.html',
  styleUrls: ['./complete-reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompleteResetPasswordComponent implements OnInit, OnDestroy {
  email: string;
  newPassword = '';
  isSubmitting = false;

  private ngUnsubscribe: EventEmitter<any> = new EventEmitter();

  @ViewChild('passwordField') private passwordField: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private appEntityServices: AppEntityServices
  ) {}

  ngOnInit() {
    this.email = this.route.snapshot.queryParams['email'];
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  submit() {
    const actionCode = this.route.snapshot.queryParams['actionCode'];

    this.isSubmitting = true;
    this.appEntityServices.userService.confirmPasswordReset(actionCode, this.newPassword, this.email)
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
          this.cdr.detectChanges();
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.newPassword = '';
        this.ngZone.run(() => this.router.navigateByUrl('/home'));
      });
  }

  isPasswordField() {
    return this.passwordField.nativeElement.type === 'password';
  }

  togglePasswordVisibility() {
    this.passwordField.nativeElement.type = this.isPasswordField() ? 'text' : 'password';
  }
}
