import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, Input, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { takeUntil, map, debounceTime, pluck, catchError, finalize, tap, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { GroupRequest, Group } from '@models/group.model';
import { Firebase } from '@services/firebase.service';
import { MessageService } from '@message/message.service';
import { User } from '@models/user.model';
import { MatDialogRef } from '@angular/material';
import { AppEntityServices } from '@store/entity/entity-services';
import { Router } from '@angular/router';
import { messageData } from '../../../modules/message/message.service';
declare var $: any;

@Component({
  selector: 'app-update-group-modal',
  templateUrl: './update-group-modal.component.html',
  styleUrls: ['./update-group-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateGroupModalComponent implements OnInit, OnDestroy {
  currentUser: User;
  updateForm: FormGroup;
  inProgress: boolean = false;
  success: boolean = false;
  errorMsg: string;

  selectedFile: File;
  selectedFilePreview: string | ArrayBuffer;
  admin_users: { value: string, label: string }[] = [];

  groupTypes: string[] = ['Team', 'Family', 'Friends', 'Club', 'Conference', 'Work', 'Other'];
  permissions: { label: string, value: string }[] = [
    { label: 'Only Admins', value: 'admins' },
    { label: 'Members', value: 'members' }
  ];
  usersLoading = false;
  userToken: string = "";
  users: User[] = [];
  item: Group = { name: '', group_type: '', invite_permission: '', image_url: '', members: [], admin_users: [] };
  private ngUnsubscribe: EventEmitter<any> = new EventEmitter();
  @Output() refreshList: EventEmitter<any> = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: Firebase,
    private router: Router,
    private messageService: MessageService,
    private appEntityServices: AppEntityServices,
    public dialogRef: MatDialogRef<UpdateGroupModalComponent>
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.initObservables();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  close() {
    this.dialogRef.close();
  }

  getUsers(data) {
    if (data && data.length === 3 && this.userToken != data) {
      this.usersLoading = true;
      this.userToken = data;
      this.appEntityServices.userService.loadAllUsers(this.userToken).subscribe(users => {
        this.users = users;
        this.usersLoading = false;
        this.cdr.detectChanges();
      });
    }
  }


  initObservables() {
    this.appEntityServices.userService.currentUser$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => this.currentUser = user);
    this.appEntityServices.groupService.selectedGroup$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(group => {
      this.item = {...group};
      if (!this.inProgress) {
        this.initForm();
      }      
    });
  }

  initForm() {
    this.updateForm = new FormGroup({
      name: new FormControl(this.item.name, Validators.required),
      admin_user: new FormControl(null),
      group_type: new FormControl(this.item.group_type, Validators.required),
      invite_permission: new FormControl(this.item.invite_permission, Validators.required),
      image_url: new FormControl(this.item.image_url)
    });
    this.selectedFile = null;
    this.selectedFilePreview = this.item.image_url;
    this.admin_users = (this.item.admin_users || []).reduce((acc, curr) => {
      let item = [this.currentUser, ...this.users].find(item => item.id === curr);
      return item ? [...acc, { label: item.full_name, value: item.id }] : acc;
    }, []);
    this.cdr.detectChanges();
  }

  get canSubmit(): boolean {
    return this.admin_users.length > 0 && !this.updateForm.invalid;
  }

  previewFile() {
    if (!this.selectedFile)
      return;
    var reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = (_event) => {
      this.selectedFilePreview = reader.result;
      this.cdr.detectChanges();
    }
  }

  onChangeImage(event) {
    let files = event.target.files;
    if (files.length > 0) {
      this.selectedFile = files[0];
      this.previewFile();
    }
    // Clear the input
    event.srcElement.value = null;
  }

  removeImage() {
    const { image_url, ...rest } = this.updateForm.value;
    this.updateForm.reset({ ...rest, image_url: null });

    this.selectedFile = null;
    this.selectedFilePreview = null;
  }

  isInValid(element) {
    element = this.updateForm.get(element);
    return (element.invalid && (element.dirty || element.touched));
  }

  isValidEmail(email) {
    const control = new FormControl(email, Validators.email);
    return !control.errors;
  }

  addAdminUser() {
    const { admin_user, ...rest } = this.updateForm.value;
    let isValid = admin_user && admin_user.value && (admin_user.data || (!admin_user.data && this.isValidEmail(admin_user.value))) && !this.admin_users.includes(admin_user.value);
    if (isValid) {
      this.admin_users = [...this.admin_users, admin_user];
      this.updateForm.reset({ ...rest, admin_user: null });
      this.cdr.detectChanges();
    }
  }

  deleteAdmin(item) {
    let index = this.admin_users.indexOf(item);
    this.admin_users.splice(index, 1);
  }

  submit() {
    let { name } = this.updateForm.value;
    this.inProgress = true;
    if (this.selectedFile) {
      this.fb.uploadGroupFile(this.selectedFile, name)
        .then(res => res.ref.getDownloadURL())
        .then(img => this.updateForm.patchValue({ image_url: img }))
        .then(() => this.update());
    } else {
      this.update();
    }
  }

  deleteGroup() {
    this.close();
    this.messageService.message({
      type: 'confirm',
      message: `Are you Sure you want to Delete ${this.item.name} Group? `
    })
      .subscribe(({ isOk }) => {
        if (isOk) this.confirmDelete()
      });
  }

  leaveGroup() {
    this.close();
    this.messageService.message({
      type: 'confirm',
      message: `Are you Sure you want to Leave ${this.item.name} Group? `
    })
      .subscribe(({ isOk }) => {
        if (isOk) this.confirmLeave()
      });
  }

  get isGroupAdmin() {
    return this.currentUser && this.item.admin_users && this.item.admin_users.includes(this.currentUser.id);
  }

  confirmDelete() {
    this.router.navigateByUrl('connections/groups');
    this.appEntityServices.groupService.delete(this.item.id)
    .subscribe(
      () => {
        this.messageService.notify('success', 'Group Deleted Successfully!');
        this.appEntityServices.groupService.loadGroups();
      },
      err => this.messageService.notify('danger', 'Group Delete is Not Successfull!'));
    
  }

  confirmLeave() {
    this.router.navigateByUrl('connections/groups');
    this.appEntityServices.groupService.leave(this.item.id)
    .subscribe(
      () => {
        this.messageService.notify('success', 'Your left the group Successfully!');
        this.appEntityServices.groupService.loadGroups();
      },
      err => this.messageService.notify('danger', 'Group Leaving is Not Successfull!'));      
  }

  update() {
    let { name, invitation_text, image_url, invite_permission, group_type } = this.updateForm.value;
    let me = this.currentUser.id;
    let admin_users = [{ value: me }, ...this.admin_users.filter(item => item.value != me)].map(item => item.value);
    let group = { ...this.item, team_group_key: this.item.id, name, invitation_text, image_url, invite_permission, group_type, admin_users };
    this.appEntityServices.groupService.update(group).pipe(take(1), pluck('error'))
    .subscribe(error => this.handleReturn(error), error=> this.handleReturn(error));

  }

  handleReturn(error) {
    this.inProgress = false;
    this.errorMsg = (error || {}).message;
    this.success = !error;
    if (this.success)
      this.initForm();

    this.cdr.detectChanges();
  }

  public model: any;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => term === '' ? []
        : this.users.filter(v => v.full_name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  formatter = (x: { full_name: string }) => x.full_name;

}


