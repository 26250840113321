import { Question } from './question.model';
import { isString } from 'util';

export class Survey {  
    id: string;
    name?: string;
    survey_name?: string;
    first_name?: string;
    last_name?: string;
    full_name?: string;
    email?: string;
    enneagramType?: number;    
    questions?: Question[] = [];
    extra?: any;  
    score?: any[];
    answers?: string[] = [''];
    created?: Date;
    updated?: Date;
    public static maptoSurvey(item): Survey {      
      const extra = isString(item.extra) ? JSON.parse(item.extra): item.extra;
      let answers = isString(item.answers) ? JSON.parse(item.answers): item.answers;
      answers = (answers||[])
      .filter(item => item != null)
      .sort((a,b)=> {
        let aId = (a && a.oid)? a.oid.replace(/q/gi,'').replace(/-/gi,'.') : 0;
        let bId = (b && b.oid)? b.oid.replace(/q/gi,'').replace(/-/gi,'.') : 0;
        return +aId > +bId ? 1 : -1;
      });
      item = {...item, ...extra};
      return {
        ...item,
        extra: {...extra},
        surveyID: item.surveyID || item.id,
        user_id: item.user_id || item.owner || item.user1,
        score : isString(item.score) ? JSON.parse(item.score): item.score,        
        answers: [...answers], 
        image_url: item.image_url || item.imageUrl || item.avatar || 'assets/images/default-avatar.svg',
        name: item.name || item.first_name || item.author,
        full_name: item.author || [item.first_name, item.last_name].join(' ').trim(),
        enneagramType: (item.tritype || [])[0] || '',
        tritype_full: item.tritype ? item.tritype.join('-') : '',
        tritype: item.tritype || ['','',''],
        tritypeDescription: item.tritypeDescription || item.tritype_name,
        instinctualTypeFull: (item.instinct_stack && item.instinct_stack.length) ? item.instinct_stack[0] : '',
      };
  }
  }