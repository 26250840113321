import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/modules/material/material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { QuestionsLoginComponent } from './questions-login/questions-login.component';
import { QuestionsRegisterComponent } from './questions-register/questions-register.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { CompleteResetPasswordComponent } from './complete-reset-password/complete-reset-password.component';


@NgModule({
  declarations: [
    AcceptInvitationComponent,
    CompleteResetPasswordComponent,
    LoginComponent,
    QuestionsLoginComponent,
    QuestionsRegisterComponent,
    RegisterComponent,    
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgbModule,
  ],
  exports: [
    AcceptInvitationComponent,
    CompleteResetPasswordComponent,
    LoginComponent,
    QuestionsLoginComponent,
    QuestionsRegisterComponent,
    RegisterComponent,    
  ],
})
export class AuthenticationModule { }
