import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MobileService {

  constructor() { }

  get isAndroid() {
    return /Android/i.test(this.userAgent) && !this.isWindows;
}
  get isBlackBerry() {
    return /BlackBerry|BB10|PlayBook/i.test(this.userAgent);;
  }

  get isIPhone() {
    return /iPhone/i.test(this.userAgent) && !this.isIPad && !this.isWindows;
  }

  get isIPod() {
    return /iPod/i.test(this.userAgent);
  }

  get isIPad() {
    return /iPad/i.test(this.userAgent);
  }

  get isIOS() {
    return this.isIPad || this.isIPod || this.isIPhone;
  }

  get isSafari() {
    return /Safari/i.test(this.userAgent);
  }

  get isOpera() {
    return /Opera Mini/i.test(this.userAgent);
  }

  get isWindows() {
    return /Windows Phone|IEMobile|WPDesktop/i.test(this.userAgent);
  }

  get isKindleFire() {
    return /Kindle Fire|Silk|KFAPWA|KFSOWI|KFJWA|KFJWI|KFAPWI|KFAPWI|KFOT|KFTT|KFTHWI|KFTHWA|KFASWI|KFTBWI|KFMEWI|KFFOWI|KFSAWA|KFSAWI|KFARWI/i.test(this.userAgent);
  }

  get isAnyMobile() {
      return this.isAndroid || this.isBlackBerry || this.isIOS || this.isOpera || this.isWindows;
  }

  private get userAgent() {
    return navigator.userAgent;
  }
}
