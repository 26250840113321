import { Component, OnInit, Inject, NgZone, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, takeUntil, take, switchMap } from 'rxjs/operators';
import { GApiWrapper } from '@services/gapi-wrapper.service';
import { Router, ActivatedRoute } from '@angular/router'

import { CurrentUser } from '@services/current-user.service';
import { Firebase } from '@services/firebase.service';
import { AppEntityServices } from '@store/entity/entity-services';
import { MessageService } from '@message/message.service';
import { environment } from '@env/environment';
import { LoginType } from '@store/services/user.service';
declare var $: any

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit, OnDestroy {
  allowTestDrive: boolean = false;
  registerForm: FormGroup;
  quotes: any[] = [];
  quote = null;

  private ngUnsubscribe: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private ngZone: NgZone,
    private gapiWrapper: GApiWrapper,
    private currentUser: CurrentUser,
    private router: Router,
    private route: ActivatedRoute,
    private firebase: Firebase,
    private appEntityServices: AppEntityServices,
    private messageService: MessageService
  ) {

    this.registerForm = fb.group({
      email: ['', Validators.required],
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.getQuotes();    
    this.getRouteParams();
    this.firebase.onUserChange.subscribe(() => {
      if (this.firebase.isloggedIn)
        this.ngZone.run(() => this.router.navigateByUrl('/home' + location.search));
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getRouteParams() {
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      this.allowTestDrive = (params.testdrive && params.testdrive.toLowerCase() === 'true');      
      if (params.email)
        this.registerForm.get('email').setValue(params.email);
    });
  }

  get showFacebook(): boolean {
    return environment.enable_facebook;
  }

  randomQuote() {
    if (this.quotes.length > 0) {
      let random = Math.floor(Math.random() * this.quotes.length);
      this.quote = this.quotes[random];
    }
  }

  getQuotes() {
    this.appEntityServices.commonService.quotes$.pipe(take(1)).subscribe(quotes => {      
      this.quotes = quotes;
      this.randomQuote();
    })
  }

  doRegister() {
    let { email, password, fname, lname } = this.registerForm.value;
    const registerBtn = $('#register-button');
    registerBtn.button('loading');

    this.appEntityServices.userService.signUp(email, password, fname, lname)
      .pipe(
        switchMap((user)=> this.appEntityServices.loadUser()),
        finalize(() => registerBtn.button('reset')),
        take(1)
      )
      .subscribe((res) => {
        if(res.code) 
        this.messageService.notify('danger', res.message);
        

        if (res.code === 'auth/email-already-in-use') {
          this.ngZone.run(() => this.router.navigateByUrl('/login'));
        }
        else if (!res.code)
          this.ngZone.run(() => this.router.navigateByUrl('/home'));
      });
  }

  signInWithGoogle() {
    const loginGoogleBtn = $('#login-google-button');

    loginGoogleBtn.button('loading');

    this.appEntityServices.userService.login(LoginType.Google)
      .pipe(
        switchMap((user)=> this.appEntityServices.loadUser()),
        finalize(() => loginGoogleBtn.button('reset')),
        take(1)
      )
      .subscribe((res) => {
        if (!res.code)
          this.ngZone.run(() => this.router.navigateByUrl('/home'));
      });
  }

  signInWithFacebook() {
    const loginFacebookBtn = $('#login-facebook-button');

    loginFacebookBtn.button('loading');

    this.appEntityServices.login(LoginType.Facebook)
      .pipe(finalize(() => loginFacebookBtn.button('reset')))
      .subscribe((res) => {
        if (!res.code)
          this.ngZone.run(() => this.router.navigateByUrl('/home'));
      });
  }

  togglePasswordVisiblity(passwordField) {
    if (passwordField.type == 'text')
      passwordField.type = 'password';
    else
      passwordField.type = 'text';
  }

}