import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageService } from '@message/message.service';
import { AppEntityServices } from '@store/entity/entity-services';

@Component({
  selector: 'app-results-feedback',
  templateUrl: './results-feedback.component.html',
  styleUrls: ['./results-feedback.component.scss']
})
export class ResultsFeedbackComponent implements OnInit {

  resonatedItem: number = NaN;
  comment: string = '';
  currentUser: any = {};
  surveyID: string = '';
  constructor(public dialogRef: MatDialogRef<ResultsFeedbackComponent>, private appEntityServices: AppEntityServices, private messageService: MessageService) {
    this.appEntityServices.userService.currentUser$.subscribe(currentUser => this.currentUser = currentUser);
    this.appEntityServices.profileService.surveyID$.subscribe(surveyID => this.surveyID = surveyID);
  }

  ngOnInit() { }

  resonateUpdate(amount, comment) {    
    this.resonatedItem = amount;    
    const surveyId = this.surveyID || this.currentUser.surveyAnswersDefault;
    this.appEntityServices.profileService.resonateUpdate(amount, comment, surveyId).subscribe(response => {
      this.resonatedItem = this.comment = null;
      this.closeFeedback(true);
      this.messageService.notify('success', 'Feedback Submit was Successfull');
    }, error => {
      console.log(error);
      this.messageService.notify('danger', 'Feedback Submit failed');
    });
  }

  closeFeedback(saved?) {
    this.dialogRef.close(saved || false);
  }

}
