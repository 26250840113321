import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, Logger, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { Observable, of, from, forkJoin, throwError } from 'rxjs';
import { map, catchError, pluck, tap, mergeMap, withLatestFrom, switchMap, concatMap, take, filter, finalize, delay, debounceTime } from 'rxjs/operators';
import { BaseHttpService } from '@services/base.service';
import { Connection } from '@models/connection.model';
import { GroupRequest, Group } from '@models/group.model';
import { MessageService } from '../../shared/modules/message/message.service';
import { Router } from '@angular/router';
import { Firebase } from '@services/firebase.service';
import { NotificationMessageComponent } from '@message/components/notification-message/notification-message.component';


@Injectable()
export class MessageDataService extends DefaultDataService<any> {
  channelId: string;
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private service: BaseHttpService, private firebase: Firebase) {
    super('Message', http, httpUrlGenerator);
  }

  getAll(): Observable<any[]> {
    return this.firebase.list_db('channels/'+ this.channelId).pipe(catchError(err=>{
      console.error(err);
      return [];
    }));
  } 

  delete(id: number | string): Observable<number | string> {
    return this.service.call('DELETE', '/cloud_message/deletemessage', {id})
    .pipe(catchError(err=> throwError(err)));
  }
  
}

@Injectable()
export class MessageCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: MessageDataService, private router: Router, private messageService: MessageService) {
    super('Message', elementsFactory);
  }  

  loadMessages(channelId) {
    this.dataService.channelId = channelId;    
    this.load();    
  }

  initMessages() {
    this.entities$.pipe(debounceTime(1000), tap(messages=>{
      (messages||[]).map(message => {
        if (message.html)
        this.sendMessage(message)
        console.log(message);
      });
    })).subscribe();
  }

  dialogPosition(position: string) {
    switch (position) {
      case 'top-right': return { top: '10px', right: '10px' };
      case 'top-left': return { top: '0%', left: '10px' };
      case 'bottom-right': return { bottom: '10px', right: '10px' };
      case 'bottom-left': return { bottom: '10px', left: '10px' };
      case 'center': return { top: '10%' };
      case 'top-center': return { top: '10px' };
      case 'bottom-center': return { bottom: '10px' };
    }
    return { bottom: '10px', right: '10px' };
  }

  sendMessage(data) {
    this.messageService.message(data, NotificationMessageComponent, {
      width: '500px',
      panelClass: 'feedbackDialog',
      position: this.dialogPosition(data.location)
    })
      .subscribe((action) => {   
        try {          
          if (action)
          this.eval(action);
          if (data.id) // delete Read Message
          this.delete(data.id);
        } catch (error) {
          console.error(error)
        }      

      });
  }

  eval(action){
    eval(action);
  }

  nav(url) {
    this.router.navigateByUrl(url);
  }

}
