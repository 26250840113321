import { EntityAction, DefaultPersistenceResultHandler, EntityCollectionReducerMethods, EntityDefinition, EntityCollection, EntityDefinitionService, EntityCollectionReducerMethodMap } from '@ngrx/data';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { isString } from 'util';

export class AdditionalPropertyPersistenceResultHandler extends DefaultPersistenceResultHandler {
    handleSuccess(originalAction: EntityAction): (data: any) => Action {
        const actionHandler = super.handleSuccess(originalAction);
        const payload: any = originalAction.payload.data || null;        
        // return a factory to get a data handler to
        // parse data from DataService and save to action.payload
        return function (data: any) {            
            const action = actionHandler.call(this, data);
            if (payload) {                
                // save the data.additional to action.payload.additional
                action.payload = {...action.payload, payload_data: payload};
            }
            return action;
        };
    }
}

export class AdditionalEntityCollectionReducerMethods<T> extends EntityCollectionReducerMethods<T> {
    constructor(public entityName: string, public definition: EntityDefinition<T>) {
        super(entityName, definition);
    }
    protected queryManySuccess(
        collection: EntityCollection<T>,
        action: EntityAction<T[]>
    ): EntityCollection<T> {
        const ec: any = super.queryManySuccess(collection, action);
        const payload: any = action.payload;
        if (payload.payload_data.additional) {            
            // save the additional property from action.payload to entityCollection instance
            return { ...ec, ...payload.payload_data.additional };
        }
        return ec;
    }
}

@Injectable()
export class AdditionalEntityCollectionReducerMethodsFactory {
    constructor(private entityDefinitionService: EntityDefinitionService) { }
    /** Create the  {EntityCollectionReducerMethods} for the named entity type */
    create<T>(entityName: string): EntityCollectionReducerMethodMap<T> {        
        const definition = this.entityDefinitionService.getDefinition<T>(entityName);
        const methodsClass = new AdditionalEntityCollectionReducerMethods(entityName, definition);
        return methodsClass.methods;
    }
}