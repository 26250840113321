import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, Logger, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { Observable, of, EMPTY } from 'rxjs';
import { map, catchError, pluck, tap, switchMap } from 'rxjs/operators';
import { Question } from '@models/question.model';
import { BaseHttpService } from '@services/base.service';
import { Survey } from '@models/survey.model';
import { User } from '@models/user.model';
import { Coach } from '@models/coach.model';


@Injectable()
export class CommonDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, logger: Logger, private service: BaseHttpService) {
    super('Common', http, httpUrlGenerator);
  }

  getWithQuery(params: string | QueryParams | any): Observable<any> {
    return of([]);
  }

  // get All Survey  
  getAllSurveys(): Observable<Survey[]> {
    return this.service.call('GET', '/survey/list')
      .pipe(       
        pluck('items'),
        catchError(err => of([]))
      );
  }

  getThinkific_UserSSO(): Observable<any> {    
    return this.service.call('GET', '/thinkific_courses/get_user_sso')
      .pipe(
        pluck('content'),
        map(content => JSON.parse(content)),
        pluck('url'),
        map(url => url || ''),
        catchError(err => EMPTY)
      );
  }

  getThinkific_UserCourses(): Observable<any[]> {
    return this.service.call('GET', '/thinkific_courses/get_users_courses')
      .pipe(
        pluck('content'),
        map(content => JSON.parse(content)),
        pluck('items'),
        map(items => items || []),
        catchError(err => of([]))
      );
  }

  getFAQ(): Observable<any[]> {
    return this.service.call('GET', 'faq/getAll')
      .pipe(pluck('items'),
        catchError(err => of([]))
      );
  }

  getQuotes(): Observable<any[]> {
    return this.service.call('POST', '/quote/list')
      .pipe(pluck('items'),
        catchError(err => of([]))
      );
  }

  getCoaches(): Observable<any> {
    return this.service.call('GET', 'coach/getAll')
      .pipe(
        pluck('items'),
        catchError(err => of([])),
        map(result => {
          return Coach.toCoachArray(result);
        }),
      );
  }

}

@Injectable()
export class CommonCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: CommonDataService) {
    super('Common', elementsFactory);
  }

  redirectThinkificsso() {    
    this.thinkific_UserSSO$.subscribe(url => {
      if (url) window.location.assign(url);
    })
  }

  getQuotes(): Observable<any[]> {
    return this.dataService.getQuotes().pipe(tap(quotes => this.setData({ quotes })));
  }

  getAllSurveys(): Observable<Survey[]> {
    return this.dataService.getAllSurveys().pipe(tap(surveys => this.setData({ surveys })));
  }

  getThinkific_UserSSO(): Observable<any> {
    return  this.dataService.getThinkific_UserSSO().pipe(tap(thinkific_UserSSO => this.setData({ thinkific_UserSSO })));
  }

  getThinkific_UserCourses(): Observable<any> {
    return this.dataService.getThinkific_UserCourses().pipe(tap(thinkific_UserCourses => this.setData({ thinkific_UserCourses })));
  }

  getFAQ(): Observable<any[]> {
    return this.dataService.getFAQ().pipe(tap(faq => this.setData({ faq })));    
  }

  getCoaches(): Observable<any[]> {
    return this.dataService.getCoaches().pipe(tap(coaches => this.setData({ coaches })));    
  }

  setData(additional: any): Observable<any> {
    let queryParams: any = { additional };
    return this.getWithQuery(queryParams);
  }

  get quotes$(): Observable<any[]> {
    return this.collection$.pipe(
      map((item: any) => item.quotes),
      switchMap(items => items ? of(items) : this.getQuotes()))
  }

  get faq$(): Observable<any[]> {
    return this.collection$.pipe(
      map((item: any) => item.faq),
      switchMap(items => items ? of(items) : this.getFAQ()));
  }

  get thinkific_UserSSO$(): Observable<any> {    
    return this.collection$.pipe(
      map((item: any) => item.thinkific_UserSSO),
      switchMap(items => items ? of(items) : this.getThinkific_UserSSO()))
  }

  get thinkific_UserCourses$(): Observable<any[]> {
    return this.collection$.pipe(
      map((item: any) => item.thinkific_UserCourses),
      switchMap(items => items ? of(items) : this.getThinkific_UserCourses()));
  }

  get surveys$(): Observable<Survey[]> {
    return this.collection$.pipe(
      map((item: any) => item.surveys),
      switchMap(items => items ? of(items) : this.getAllSurveys()));
  }

  get coaches$(): Observable<any[]> {
    return this.collection$.pipe(
      map((item: any) => item.coaches),
      switchMap(items => items ? of(items) : this.getCoaches()));
  }

}
