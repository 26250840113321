import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import {Router, ActivatedRoute, NavigationStart, ResolveEnd} from '@angular/router';
import { MatDialog } from '@angular/material';
import { CurrentUser } from '@services/current-user.service';
import { InsideHomeScreenNavigation } from '@services/navigation.service';
import { Firebase } from '@services/firebase.service';
import { MobileService } from '@services/mobile.service';
import { MessageService } from '@message/message.service';
import { BackendService } from '@services/backend.service';
import { AppEntityServices } from '@store/entity/entity-services';
import { User } from '@models/user.model';
import { takeUntil, takeWhile, filter, take } from 'rxjs/operators';
import { environment } from '@env/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  env = environment;
  me: any = {};
  isloggedIn: boolean = false;
  pageIndex: number = 0;
  thinkificUserCourse: any;
  thinkificUserSso: any;
  getUrlQueryParams: any;
  private ngUnsubscribe: EventEmitter<any> = new EventEmitter<any>();
  constructor(public currentUser: CurrentUser, public navigation: InsideHomeScreenNavigation, public mobileService: MobileService,
    private router: Router, private messageService: MessageService, private ms: BackendService, private appEntityServices: AppEntityServices,
  ) {
    this.appEntityServices.userService.isloggedIn$.subscribe(isloggedIn => this.isloggedIn = isloggedIn);
    this.appEntityServices.userService.currentUser$.subscribe(user => this.me = user);
  }

  ngOnInit() {
      this.getThinkificUserCourseData();
      const url = this.router.url.split('?');
      if (url[1] === 'testdrive=true') {
        this.getUrlQueryParams = url[1];
      } else {
        return;
      }
  }
  get showHeader(){
    return !this.navigation.isLoginUrl && !this.navigation.isRegisterUrl && !this.navigation.isProfileTestStartUrl;
  }

  get showUserNav(){
    return !this.navigation.isProfileTestUrl;
  }

  isActive(link) {
    return location.href.indexOf(link)>=0;
  }

  takeTheTest() {
    this.navigation.goToEnneagramTest(0);
  }

  get isRegister(){
    return !this.isloggedIn && this.navigation.isQuestionRegisterUrl;
  }

  get isLogin(){
    return !this.isloggedIn && this.navigation.isQuestionLoginUrl;
  }

  goPage(page) {
    let query = {login: false, register: false};
    query[page]=true;
    this.navigation.goToEnneagramTest(this.navigation.pageIndex, query);
  }

  teamMemberName(member): string{
    let out = ''

    if(member.isFacilitator)
      out += 'Facilitator ('+member.name+')'
    else
    out += member.name +" "+ member.last_name

    if(member.isCurrentUser)
      out += ' [you]'

    return out
  }

  unreadMessageCount(member): any{
    let c = member.conversation.unreadMessagesCount
    if(c == 0)
      return ''
    else return c
  }

  get avatar() {
    return this.me.image_url || 'assets/images/default-avatar.svg';
  }

  logout(): void {
    this.messageService.message({
      type: 'confirm',
      message: 'Do you want to log out?'
    })
    .subscribe(({isOk}) => {
      if (isOk) this.confirmLogout()
    });
  }

  confirmLogout(): void {
    this.appEntityServices.signOut().subscribe(() => {
      this.router.navigateByUrl('/login');
      this.messageService.notify('success', 'You logged out Successfully.')
    });
  }

   getThinkificUserCourseData() {
    if (this.isloggedIn && !this.thinkificUserCourse) {
      this.appEntityServices.commonService.getThinkific_UserCourses().pipe(take(1))
      .subscribe((courses) => {
         this.thinkificUserCourse = courses;
         if (this.thinkificUserCourse.length > 0) {
          this.appEntityServices.commonService.getThinkific_UserSSO().pipe(take(1))
          .subscribe((userSso) => this.thinkificUserSso = userSso);
         }
      });
    }
  }

}
