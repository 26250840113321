import { Injectable, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig, DialogPosition } from '@angular/material';
import { ConfirmMessageComponent } from './components/confirm-message/confirm-message.component';
import { ComponentType } from '@angular/cdk/portal';
import { mergeMapTo } from 'rxjs/operators';

export interface notificationData {
  title?: string;
  html?: string;
  buttons?: {text: string, action: string}[];
}

export interface messageData {
  type: 'success' | 'danger' | 'warning' | 'info' | 'confirm' | 'custom';
  title?: string;
  message: string;
  note?: string;
  showInput?: boolean;
  input?: string;
  okBtn?: {
    cssClass?: string;
    label: string;
  }
  cancelBtn?: {
    cssClass?: string;
    label: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(public dialog: MatDialog) {
  }
  /**
   * Custom Alert Messages
   * @param data 
   * @param componentOrTemplateRef 
   * @param config 
   */
  message(data: messageData | notificationData, componentOrTemplateRef?: ComponentType<any> | TemplateRef<any>, config?: MatDialogConfig<any>): Observable<any> {
    const defaultConfig: MatDialogConfig<any> = {
      data: data,
      width: '350px',
      disableClose: false,
      position: { top: '5%' }
    };

    const targetConfig = Object.assign({}, defaultConfig, config || {});

    const dialogRef = this.dialog.open(componentOrTemplateRef || ConfirmMessageComponent, targetConfig);
    return dialogRef.afterClosed();
  }

  /**
   * Simple Alert Messages
   * @param type 
   * @param message 
   * @param title 
   */
  notify(type: 'success' | 'danger' | 'warning' | 'info' | 'confirm', message: string, title?: string, note?: string) {
    return this.message({ type, message, title, note });
  }

}
