import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FacebookService, InitParams, UIParams, UIResponse } from 'ngx-facebook';
import { environment } from "environments/environment";
import { CurrentUser } from '@services/current-user.service';
import { MessageService } from '@message/message.service';
import { AppEntityServices } from '@store/entity/entity-services';
import { User } from '@models/user.model';


@Component({
  selector: 'app-results-share',
  templateUrl: './results-share.component.html',
  styleUrls: ['./results-share.component.scss']
})
export class ResultsShareComponent implements OnInit {

  emailForm: FormGroup;
  item: any = {};
  surveyID: string;
  user1Key: string;
  user2Key: string;
  success: boolean = false;
  error: boolean = false;
  errorMsg: string = '';
  currentUser: any = {};
  constructor(private appEntityServices: AppEntityServices, private fb: FacebookService, private messageService: MessageService) {
    this.fbInit();
    this.initForm();
    this.appEntityServices.userService.currentUser$.subscribe(currentUser => this.currentUser = currentUser || {});    
    this.appEntityServices.profileService.surveyID$.subscribe(surveyID => this.surveyID = surveyID);
  }

  ngOnInit() {
  }

  

  initForm(){
    this.emailForm = new FormGroup({
      email: new FormControl('', [ Validators.required, Validators.email])
    });
  }
  
  fbInit() {
    let initParams: InitParams = {
      appId: environment.fb_app_id,
      cookie: true,
      xfbml: true,
      version: 'v2.12'
    };

    this.fb.init(initParams);
  }

  get shared_url() {
    // Share Your Results      
    let surveyId = this.surveyID || this.currentUser.surveyAnswersDefault || '';
    return `${environment.fb_shared_url}/${surveyId}`;
  }

  fbShare() {
      let params: UIParams = {
        href: this.shared_url,
        method: 'share'
      };    
      this.fb.ui(params)
        .then((res: UIResponse) => {
          console.log(res);
          this.success = true;
        })
        .catch((e: any) => {
          this.error = true;
          this.errorMsg = e.message;
          console.error(e)
        });
    
  }

  fbSend() {      
    let params: UIParams = {
      link: this.shared_url,
      method: 'send'
    };  
    this.fb.ui(params)
        .then((res: UIResponse) => {
          console.log(res);
          this.success = true;
        })
        .catch((e: any) => {
          this.error = true;
          this.errorMsg = e.message;
          console.error(e)
        });
  }

  isInValid(element) {
    element = this.emailForm.get(element);
    let invalid = (element.invalid && (element.dirty || element.touched))
    return invalid;
  }  

  shareEmail(){
    if(this.emailForm.valid){
      let email: string = this.emailForm.get('email').value;
      if(email.length>0){
        this.appEntityServices.profileService.emailResultsTo(email).subscribe(response=>{
          this.messageService.notify('success', 'Results was Shared Via Email Successfully');
        }, err=>{
          this.messageService.notify('danger', 'Results was NOT Shared Via Email Successfully');
        })
      }
    }
  }

  copyToClipboard(element) {
    element.select();
    document.execCommand('copy');
    this.messageService.notify('success', 'Link copied to clipboard.');
    element.setSelectionRange(0, 0);
  }

}
