import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, Logger, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from '@ngrx/data';

import { Observable, of, EMPTY } from 'rxjs';
import { map, catchError, pluck } from 'rxjs/operators';
import { Question } from '@models/questions.model';
import { BaseHttpService } from '@services/base.service';


@Injectable()
export class QuestionDataService extends DefaultDataService<Question> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private service: BaseHttpService) {
    super('Question', http, httpUrlGenerator);
  }

  getAll(): Observable<Question[]> {
    return this.service.call('POST', '/question/get_by_survey_name', { name: environment.enneagram_default_testName })
    .pipe(
        catchError(err => of([])),
        pluck('items'),
        map(questions => (questions||[]).map(question => Question.mapToQuestion(question)))
    );
  }

  getWithQuery(params: string | QueryParams | any): Observable<Question[]> {
    let {name} = params;
    if (!name) return of([]);

    return this.service.call('POST', '/question/get_by_survey_name', { name })
   .pipe(
        catchError(err => of([])),
        pluck('items'),
        map(questions => (questions||[]).map(question => Question.mapToQuestion(question)))
    );
  }

}

@Injectable()
export class QuestionCollectionService extends EntityCollectionServiceBase<Question> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory) {
    super('Question', elementsFactory);
  }

  getTest(name): Observable<any> {
    return this.getWithQuery({name});
  }

  setPageIndex(pageIndex): Observable<any> {        
    let queryParams: any = {additional: {pageIndex}};
    return this.getWithQuery(queryParams);
  }

  setPages(pages): Observable<any> {        
    let queryParams: any = {additional: {pages}};
    return this.getWithQuery(queryParams);
  }
 
  get pageIndex$(): Observable<number> {
    return this.collection$.pipe(map((item: any) => item.pageIndex));
  }

  get pages$(): Observable<number[]> {
    return this.collection$.pipe(map((item: any) => item.pages));
  }
}
