import { EntityMetadataMap } from '@ngrx/data';
 
const entityMetadata: EntityMetadataMap = {
    Common: {},
    Message: {},
    User: {
        selectId: selectId,
        sortComparer: sortComparerFullName,
        additionalCollectionState: {
            currentUser: null,
            isloggedIn: false,
        }
    },
    Social: {
        selectId: selectId,
        sortComparer: sortComparerFullName,
        additionalCollectionState: {
            facebookFriends: {
                friends: [],
                more: false,
                accessTokenIsValid: null,
                cursor: ''
            },
            googleFriends: {
                friends: [],
                more: false,
                accessTokenIsValid: null,
                cursor: ''
            }
        }
    },
    Coach: {
        selectId: selectCode,
        sortComparer: sortComparerName
    },
    Connection: {
        selectId: selectId,
        sortComparer: sortComparerFullName,
        additionalCollectionState: {
            friends: [],
            incoming_invites: [],
            outgoing_invites: []
        }
    },
    Group: {
        selectId: selectId,
        sortComparer: sortComparerName,
        additionalCollectionState: {
            groupConnections: {},
            groupInvitesConnections: {}
        }
    },
    Question: {
        selectId: selectId,
        sortComparer: sortComparerOID,
        additionalCollectionState: {
            pageIndex: 0,
            pages: [],
        }
    },
    Survey: {
        selectId: selectId,
        sortComparer: sortComparerName,
    },
    Profile: {
        selectId: selectId,
        sortComparer: sortComparerOID,
        additionalCollectionState: {
            user1: null,
            user2: null,
            survey: null
        }
    },
    Cache: {
        selectId: selectId,
        sortComparer: sortComparerID,
    },
};

export function selectId(item: { id: string }) {
    return item.id;
}

export function selectCode(item: { code: string }) {
    return item.code;
}

export function sortComparerName(a: {name: string}, b: {name: string}) {
    const lcA = a.name.toLowerCase() || '';
    const lcB = b.name.toLowerCase() || '';
  
    if (lcA < lcB) return -1;
    if (lcA === lcB) return 0;
    if (lcA > lcB) return 1;
}

export function sortComparerFullName(a: {full_name: string}, b: {full_name: string}) {
    const lcA = a.full_name.toLowerCase() || '';
    const lcB = b.full_name.toLowerCase() || '';
  
    if (lcA < lcB) return -1;
    if (lcA === lcB) return 0;
    if (lcA > lcB) return 1;
}

export function sortComparerOID(a: {oid: string}, b: {oid: string}) {
    return (a.oid && b.oid ) ? a.oid.localeCompare(b.oid) : 0
}

export function sortComparerID(a: {id: string}, b: {id: string}) {
    return (a.id && b.id ) ? a.id.localeCompare(b.id) : 0
}
 
// because the plural of "coach" is not "coachs"
const pluralNames = { Coach: 'Coaches' };
 
export const entityConfig = {
  entityMetadata,
  pluralNames
};
