import { Injectable, NgZone } from '@angular/core';
import { TokenStorageService } from '@services/token-storage.service';
import { forkJoin, from, of, pipe, combineLatest } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap, take } from 'rxjs/operators';
import { authProviders } from '@constants/auth.constants';
import { CurrentUser } from './current-user.service';
import { Firebase } from './firebase.service';
import { GApiWrapper } from './gapi-wrapper.service';
import * as _ from 'lodash';
import { MessageService } from '../modules/message/message.service';
import { Database } from '@services/database.service';
import { User } from '@models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private gapiWrapper: GApiWrapper,
    //private currentUser: CurrentUser,
    private db: Database,
    private firebase: Firebase,
    private messageService: MessageService,
    //private ngZone: NgZone,
    private tokenStorageService: TokenStorageService
  ) {}

  loadUser() {    
    return combineLatest(this.firebase.currentUser, of(this.cacheUser)).pipe(take(1));
  }

  get cacheUser() {
    return this.db.getValue('me.userInfo') || null;
  }

  get authToken() {
    return this.db.getValue("me.authToken") || null;
  }

  set adminUser(user) {
    this.db.set('AdminUser', user);
  }

  get adminUser() {
    return this.db.getValue('AdminUser') || null;
  }

  signIn(email, password) {
    return this.firebase.signIn(email, password)
      .pipe(this.commonSignInPipe(authProviders.email));
  }

  signUp(email, password, firstName, lastName) {
    
    const displayName = [firstName, lastName].join(' ').trim();
    const first_name = firstName;
    const last_name = lastName;
    return this.firebase.signUp(email, password)
      .pipe(
        concatMap((response) => {
          const token = this.getToken(response);
         

          return forkJoin([
            this.gapiWrapper.firepassword4token(token),
            of(response),
            this.firebase.updateProfile(displayName, null)
          ]);
        }),        
        map(([resp, fbResp]) => {
          if (resp.user && resp.user.emailVerified === false) {
            return this.verifyEmail();
          }
          
          resp.name=first_name;
          resp.last_name = last_name;
          this.saveUserData(resp, fbResp.user);
          this.successLoginMsg();
  
          return fbResp;
        }),
        catchError((err) => {
          this.messageService.notify('danger', err.message || err, 'Registration failed');
          return of(err);
        })
      );
  }

  signOut() {
    return this.firebase.signOut().pipe(tap(() => {
      this.tokenStorageService.clearTokens();
      this.db.clear();
    }));
}

  signInWithGoogle() {
    return this.firebase.signInWithGoogle()
      .pipe(this.commonSignInPipe(authProviders.google));
  }

  signInWithFacebook() {
    return this.firebase.signInWithFacebook()
      .pipe(this.commonSignInPipe(authProviders.facebook));
  }

  reconnectWithGoogle() {
    return this.firebase.signInWithGoogle()
      .pipe(this.commonReconnectPipe(authProviders.google));
  }

  reconnectWithFacebook() {
    return this.firebase.signInWithFacebook()
      .pipe(this.commonReconnectPipe(authProviders.facebook));
  }

  sendPasswordResetEmail(resetEmail) {
    return this.firebase.sendPasswordResetEmail(resetEmail);
  }

  verifyPasswordResetCode(actionCode) {
    return this.firebase.verifyPasswordResetCode(actionCode)
      .pipe(
        catchError((err) => {
          this.messageService.notify('danger', 'Invalid or expired action code. Please, try to reset the password again.');

          return of(err);
        })
      );
  }

  confirmPasswordReset(actionCode, newPassword, email) {
    return this.firebase.confirmPasswordReset(actionCode, newPassword)
      .pipe(
        concatMap(() => this.signIn(email, newPassword)),
        catchError((err) => {
          this.messageService.notify('danger', 'Error occurred during confirmation. The code might have expired or the password is too weak.');

          return of(err);
        })
      );
  }

  unlinkUser(providerId) {
    return from(this.firebase.unlinkUser(providerId))
      .pipe(tap(() => this.tokenStorageService.clearTokenByProvider(providerId)));
  }

  linkWithFacebook() {
    return from(this.firebase.linkWithFacebook())
      .pipe(
        map((response) => {          
          this.tokenStorageService.setFacebookAccessToken(response.credential.accessToken);
          this.gapiWrapper.setRefreshFacebookToken(response.credential.accessToken);
          return  response.user;
        }),
        catchError(error => {
          this.tokenStorageService.clearFacebookAccessToken();
          return of(null);
        })
      );
  }

  linkWithGoogle() {
    return from(this.firebase.linkWithGoogle())
      .pipe(
        mergeMap((response) => {          
          const accessToken = response.credential.accessToken;
          const refreshToken = response.user.toJSON().stsTokenManager.refreshToken;
          this.gapiWrapper.setRefreshGoogleToken(accessToken, refreshToken);
          this.tokenStorageService.setGoogleAccessToken(accessToken);

          return  response.user;
        }),
        catchError(error => {
          this.tokenStorageService.clearGoogleAccessToken();
          return of(null);
        })
      );
  }

  linkWithEmail(email, password) {
    return from(this.firebase.linkWithEmail(email, password));
  }

  saveUserData(user, fbUser) {    
    this.setUserSocialInfo(user, fbUser);
    user = User.maptoUser(user);
    this.db.set('me.userInfo', user);
    this.db.set('me.email', user.email);
    this.db.set('me.currentUserID', user.user_id);
    this.db.set("me.authToken", user.auth_token);
  }

  private successLoginMsg() {
    /*this.ngZone.run(() => {
      const email = this.currentUser.getEmail();  
      this.messageService.notify('info', `You are logged in as ${email}`, 'Welcome');
    });*/
  }

  private errorLoginMsg(error) {
    this.messageService.notify('danger', error, 'Login failed');
  }

  private getToken(response) {
    return _.isString(response.user.ra) ? response.user.ra : response.user.qa;
  }

  private verifyEmail() {
    return this.firebase.verifyEmail()
      .pipe(
        tap(() => this.messageService.notify('info', 'Email Verification sent to User'))
      );
  }

  private commonSignInPipe(provider) {
    return pipe(
      concatMap((response: any) => {
        return forkJoin([
          this.getTokenRequest(response, provider),
          of(response)
        ]);
      }),
      map(([resp, fbResp]) => {        
        this.saveUserData(resp, fbResp.user);
        this.successLoginMsg();

        return resp;
      }),
      catchError((error) => {
        this.errorLoginMsg(error.message || error);
        return of(error);
      })
    );
  }

  private getTokenRequest(response, provider) {    
    const token = this.getToken(response);
    const {refreshToken} = response.user;    
    const {idToken, accessToken} = <any>(response.credential || {});
        

    switch (provider) {
      case authProviders.email:
        return this.gapiWrapper.firepassword4token(token);

      case authProviders.google:     
        this.tokenStorageService.setGoogleAccessToken(accessToken);
        return this.gapiWrapper.google4token(idToken, accessToken, refreshToken, token);

      case authProviders.facebook:
        this.tokenStorageService.setFacebookAccessToken(accessToken);
        return this.gapiWrapper.facebook4token(accessToken, token);
    }
  }

  private commonReconnectPipe(provider) {
    return pipe(
      concatMap((response) => this.setRefreshToken(provider, response)),
      map((fbResp) => fbResp.user),
      catchError((error) => {
        this.tokenStorageService.clearTokenByProvider(provider);
        this.errorLoginMsg(error.message || error);
        return of(error);
      })
    );
  }

  private setRefreshToken(provider, response) {
    switch (provider) {
      case authProviders.google:
        this.tokenStorageService.setGoogleAccessToken(response.credential.accessToken);
        const accessToken = response.credential.accessToken;
        const refreshToken = response.user.toJSON().stsTokenManager.refreshToken;
        return this.gapiWrapper.setRefreshGoogleToken(accessToken, refreshToken);

      case authProviders.facebook:
        this.tokenStorageService.setFacebookAccessToken(response.credential.accessToken);
        return this.gapiWrapper.setRefreshFacebookToken(response.credential.accessToken);
    }
  }

  setUserSocialInfo(destination, data) {    
    destination = { ...destination, ...data, 
      image_url : data.photoURL || data.image_url,
      phoneNumber : data.phoneNumber || data.phone_number,
      displayName : data.displayName || data.full_name,
    };

    if (destination.displayName && !destination.name) {
      let names = destination.displayName.split(' ');
      destination = {...destination,
        name : (names.length > 0) ? names[0] : destination.name,
        last_name : (names.length > 0) ? names[1] : destination.last_name
      }
    }

  }
}
