import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { finalize, takeUntil, take, switchMap } from 'rxjs/operators';
import { GApiWrapper } from '@services/gapi-wrapper.service';
import { Router, ActivatedRoute } from '@angular/router';

import { CurrentUser } from '@services/current-user.service';
import { Firebase } from '@services/firebase.service';
import { InsideHomeScreenNavigation } from '@services/navigation.service';
import { MessageService } from '@message/message.service';
import { environment } from '@env/environment';
import { AppEntityServices } from '@store/entity/entity-services';
import { LoginType } from '../../@store/services/user.service';


declare var $: any

@Component({
  selector: 'app-questions-login',
  templateUrl: './questions-login.component.html',
  styleUrls: ['./questions-login.component.css']
})

export class QuestionsLoginComponent implements OnInit, OnDestroy {
  showForm: boolean = false;
  loginForm: any;
  resetPasswordEmail: string;
  allowTestDrive: boolean = false;

  @Output() loggedIn: EventEmitter<any> = new EventEmitter();

  private ngUnsubscribe: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private navigation: InsideHomeScreenNavigation,
    private appEntityServices: AppEntityServices
  ) {

    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    })
    this.resetPasswordEmail = ''
  }

  
  ngOnInit() {
    this.getRouteParams();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getRouteParams() {   
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      this.allowTestDrive = (params.testdrive && params.testdrive.toLowerCase() === 'true');  
      if (params.email)
        this.loginForm.get('email').setValue(params.email);
    });
  }
  
  get showFacebook(): boolean {
    return environment.enable_facebook;
  }

  doLogin() {
    const { email, password } = this.loginForm.value;
    const loginBtn = $('#login-button');

    loginBtn.button('loading');
    
    this.appEntityServices.login(LoginType.Password, email, password)
      .pipe(finalize(() => loginBtn.button('reset')))
      .subscribe(res => {
        if (res.code) 
          this.messageService.notify('danger', res.message); 
        
        else if (!res.code && !res.error && res.user)
          this.loggedIn.emit({name: res.user.displayName , email})
      });
      

  }

  signInWithGoogle() {
    const loginGoogleBtn = $('#login-google-button');

    loginGoogleBtn.button('loading');

    this.appEntityServices.login(LoginType.Google)
      .pipe(finalize(() => loginGoogleBtn.button('reset')))
      .subscribe((res) => {
        if (!res.code && !res.error && res.user) {
          const { displayName, email } = res.user;
          this.loggedIn.emit({name: displayName , email});
        }
        
      });
  }

  signInWithFacebook() {
    const loginFacebookBtn = $('#login-facebook-button');

    loginFacebookBtn.button('loading');

    this.appEntityServices.login(LoginType.Facebook)
      .pipe(finalize(() => loginFacebookBtn.button('reset')))
      .subscribe((res) => {
        if (!res.code && !res.error && res.user) {
          const { displayName, email } = res.user;
          this.loggedIn.emit({name: displayName , email});
        }        
      });
  }

  togglePasswordVisiblity(passwordField) {
    if (passwordField.type == 'text')
      passwordField.type = 'password';
    else
      passwordField.type = 'text';
  }

  goPage(page) {
    let query = {login: false, register: false};
    query[page]=true;
    this.navigation.goToEnneagramTest(this.navigation.pageIndex, query);
  }
}
