import { Component, OnInit, NgZone, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { timer } from 'rxjs';
import { finalize, takeUntil, take, switchMap } from 'rxjs/operators';
import { GApiWrapper } from '@services/gapi-wrapper.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';

import { CurrentUser } from '@services/current-user.service';
import { MessageService } from '@message/message.service';
import { Firebase } from '@services/firebase.service';
import { AppEntityServices } from '@store/entity/entity-services';
import { LoginType } from '@store/services/user.service';



declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit, OnDestroy {
  resetPassword: boolean = false;
  allowTestDrive: boolean = false;
  resetDone: boolean = false;
  loginForm: FormGroup;
  resetPasswordEmail: string;
  quotes: any[] = [];
  quote = {};
  redirect: string;
  private ngUnsubscribe: EventEmitter<any> = new EventEmitter<any>();


  constructor(
    private fb: FormBuilder,
    private ngZone: NgZone,
    private gapiWrapper: GApiWrapper,
    private router: Router,
    private route: ActivatedRoute,
    private currentUser: CurrentUser,
    private messageService: MessageService,
    private firebase: Firebase,
    private appEntityServices: AppEntityServices
  ) {

    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    })
    this.resetPasswordEmail = '';
  }

  ngOnInit() {
    this.getQuotes();
    this.getRouteParams();
    this.firebase.onUserChange.subscribe(() => {
      if (this.firebase.isloggedIn)
        this.ngZone.run(() => this.router.navigateByUrl('/home' + location.search));
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getRouteParams() {
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      this.redirect = params.redirect || null;
      this.allowTestDrive = (params.testdrive && params.testdrive.toLowerCase() === 'true');
      if (params.email)
        this.loginForm.get('email').setValue(params.email);
    });

  }

  get showFacebook(): boolean {
    return environment.enable_facebook;
  }

  toggleResetPassword() {
    this.resetPasswordEmail = null;
    this.resetPassword = !this.resetPassword;
    this.resetDone = false;
  }

  randomQuote() {
    if (this.quotes.length > 0) {
      let random = Math.floor(Math.random() * this.quotes.length);
      this.quote = this.quotes[random];
    }
  }

  getQuotes() {
    this.appEntityServices.commonService.quotes$.pipe(take(1)).subscribe(quotes => {      
      this.quotes = quotes;
      console.log(quotes);
      this.randomQuote();
    })
  }

  get registerUrl() {
    return (!this.allowTestDrive) ? '/profile/questions' : '/register';
  }

  doLogin() {
    const { email, password } = this.loginForm.value;
    const loginBtn = $('#login-button');

    loginBtn.button('loading');

    this.appEntityServices.login(LoginType.Password, email, password)
      .pipe(finalize(() => loginBtn.button('reset')))
      .subscribe(
        (res) => {
          if (!res.code) this.navigateToHome();
        }
      );
  }

  signInWithGoogle() {
    const loginGoogleBtn = $('#login-google-button');

    loginGoogleBtn.button('loading');

    this.appEntityServices.login(LoginType.Google)
      .pipe(finalize(() => loginGoogleBtn.button('reset')))
      .subscribe((res) => {
        if (!res.code) this.navigateToHome();
      });
  }

  signInWithFacebook() {
    const loginFacebookBtn = $('#login-facebook-button');

    loginFacebookBtn.button('loading');

    this.appEntityServices.login(LoginType.Facebook)
      .pipe(finalize(() => loginFacebookBtn.button('reset')))
      .subscribe((res) => {
        if (!res.code) this.navigateToHome();
      });
  }

  initResetPassword() {
    this.appEntityServices.userService.sendPasswordResetEmail(this.resetPasswordEmail)
      .pipe()
      .subscribe(
        () => {
          $('.reset-password-modal').modal('hide');
          this.resetPasswordEmail = '';
          this.resetDone = true;
        },
        (error) => {
          if (error.code === 'auth/user-not-found') {
            this.messageService.notify('danger', error.message + '\n redirecting to Registration in 5 sec..');
            timer(5000).subscribe(() => this.router.navigateByUrl('/register'));
          } else {
            this.messageService.notify('danger', error.message);
          }
        });
  }

  togglePasswordVisiblity(passwordField) {
    if (passwordField.type == 'text')
      passwordField.type = 'password';
    else
      passwordField.type = 'text';
  }

  private navigateToHome() {
    this.ngZone.run(() => {
      if (this.redirect) {
        if (this.redirect === 'thinkificsso')
          this.appEntityServices.commonService.redirectThinkificsso();
        else
          this.router.navigateByUrl(this.redirect);
      }
      else
        this.router.navigateByUrl('/home' + location.search);
    });
  }

}
