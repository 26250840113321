import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of, from, combineLatest } from 'rxjs';
import { CurrentUser } from '../services/current-user.service';
import { map, take, tap, debounceTime, withLatestFrom, filter, throttleTime, takeUntil, takeWhile } from 'rxjs/operators';
import { AppEntityServices } from '@store/entity/entity-services';
import { Firebase } from '@services/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {

  constructor(
    private appEntityServices: AppEntityServices,
    private firebase: Firebase,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.appEntityServices.userService.waitUntilLoaded$
    .pipe(map(([user]) => {
        return this.handleLogin(!!user, state);
     })
   );
  }

  handleLogin(isloggedIn, state: RouterStateSnapshot) {
    let page = state.url.match('.*login|register|landing.*');
    let valid = (isloggedIn && !page)
      || (!isloggedIn && page != null)
      || (state.url.indexOf('share') > -1)
      || (state.url.indexOf('(enneagram-test:enneagram/') > -1);

      if (!valid) {
        let url = isloggedIn ? 'home' + location.search : !page ? 'login' + location.search : location.href;
        this.router.navigateByUrl(url);
      }
      return valid;
  }
}
