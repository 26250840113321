import * as _ from 'lodash';
export class User {

  UUID: string;
  activated: string;
    auth_token?: string;
    created: Date;
    email: string;
    enneagramType: number;
    firebaseId: string;
    full_name: string;
    gender: string;
    google_access_token: string;
    google_access_token_updated: Date;
    google_refresh_token: string;
    hasSentAnInvite: boolean;
    id: string;
    image_url: string  = 'assets/images/default-avatar.svg';
    instinct_stack: string[] = Array.from({length: 3}).map(() => '');
    instinctualType: 'SP' | 'SO' | 'SX';
    isAdmin: boolean;
    last_name: string;
    last_seen: Date;
    name: string;
    profileIsPublic: boolean;
    score: number[] = Array.from({length: 10}).map(() => null);
    starring_roles: string;
    surveyAnswers: string[] = [''];
    surveyAnswersDefault: string = '';
    tritype: number[] = Array.from({length: 3}).map(() => null);
    tritype_full: string;
    tritypeDescription: string;
    updated: Date;
    username: string;

  public static maptoUser(item): User {
    let tritype: string[] = _.isString(item.tritype) ? item.tritype.split(',') : item.tritype;
    return {
      ...item,
      id: item.id || item.email,
      user_id: item.id || item.email,   
      image_url: item.image_url || 'assets/images/default-avatar.svg',
      full_name: [item.name, item.last_name].join(' ').trim(),
      enneagramType: tritype && tritype.length > 0 ? tritype[0] : '',
      tritype_full: tritype && tritype.length > 0 ? tritype.join('-') : '',
      tritype: tritype || [],
      instinctualTypeFull: (item.instinct_stack && item.instinct_stack.length) ? item.instinct_stack[0] : '',   
      name: item.name,
      last_name: item.last_name           
    };
  }

}