import { Injectable, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import * as firebase from 'firebase/app';
import { from, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';

@Injectable()
export class Firebase {
    user: firebase.User;
    onUserChange: EventEmitter<firebase.User> = new EventEmitter();

    constructor(
      private fbAuth: AngularFireAuth,
      private fbStorage: AngularFireStorage,
      private fireDB: AngularFireDatabase
    ) {
        this.loadCurrentUser();
    }

    loadCurrentUser() {
        this.fbAuth.auth.onAuthStateChanged((user) => {
            this.user = user;
            this.onUserChange.emit(user);
        });        
    }

    get isloggedIn(): boolean {
        return this.user != null;
    }

    get currentUser(): Observable<firebase.User> {
      return this.user ? of(this.user) : this.fbAuth.user;
    }


    list_db(path): Observable<any> {
        return this.fireDB.list(path).snapshotChanges()
        .pipe(map(items=> (items||[]).map(item => {
            return {id: item.key, ...<any>item.payload.val()};
        })));
    }

    signInWithGoogle() {
        return from(this.fbAuth.auth.signInWithPopup(this.GoogleAuthProvider));
    }

    signInWithFacebook() {
        return from(this.fbAuth.auth.signInWithPopup(this.FacebookAuthProvider));
    }

    signIn(email, password) {
        console.log(email,password)
        return from(this.fbAuth.auth.signInWithEmailAndPassword(email, password));
    }

    signUp(email, password) {
        return from(this.fbAuth.auth.createUserWithEmailAndPassword(email, password));
    }

    signOut() {
        return from (this.fbAuth.auth.signOut());
    }

    verifyEmail() {
        return from(this.fbAuth.auth.currentUser.sendEmailVerification());
    }

    sendPasswordResetEmail(email) {
        return from(this.fbAuth.auth.sendPasswordResetEmail(email));
    }

    verifyPasswordResetCode(actionCode) {
        return from(this.fbAuth.auth.verifyPasswordResetCode(actionCode));
    }

    confirmPasswordReset(actionCode, newPassword) {
        return from(this.fbAuth.auth.confirmPasswordReset(actionCode, newPassword));
    }

    updateProfile(displayName: string, photoURL: string) {
        return this.fbAuth.auth.currentUser.updateProfile({displayName, photoURL});
    }


    updateEmail(email) {
        return this.fbAuth.auth.currentUser.updateEmail(email);
    }

    uploadFile(file:File){
        let path = "/profile-images/" + this.fbAuth.auth.currentUser.uid + ".png";
        return this.fbStorage.ref(path).put(file);
        //return this.fbStorage.upload(path,file);
    }

    uploadGroupFile(file: File, group_name: string) {
        let path = "/group-images/" + this.fbAuth.auth.currentUser.uid + "_" + group_name + ".png";
        return this.fbStorage.ref(path).put(file);
        //return this.fbStorage.upload(path,file);
    }

    deleteUser(){
        return this.fbAuth.auth.currentUser.delete();
    }

    unlinkUser(providerId) {
        let that = this;
        var promise = new Promise<any>((resolve, reject) => {
            let originalEmail = that.fbAuth.auth.currentUser.email;
            that.fbAuth.auth.currentUser.unlink(providerId)
            .then(function(user) {
                // if the account Email has changed recover it , the linking should be always with same primary email
                 if(user.email != originalEmail) {
                    user.updateEmail(originalEmail).then(()=>{
                       resolve(user);
                    }).catch(()=> resolve(user));
                }
                else
                resolve(user);          
                
            }, function(response) {
                console.log('failed to execute user.linkWithFacebook')
                console.log(response)
                reject(response)
            })
            .catch(function(error) {
                console.log('failed to execute user.linkWithFacebook')
                console.log(error)
                reject(error)
            });
        });

        return promise;
    }

    linkWithGoogle() {
        let that = this;        
        var promise = new Promise<any>((resolve, reject) => {
            that.fbAuth.auth.currentUser.linkWithPopup(this.GoogleAuthProvider)
            .then(function(response: any) {
                resolve(response);
            }, function(error) {
                console.log('failed to execute user.linkWithGoogle')
                console.log(error)
                reject(error)
            })
            .catch(function(error) {
                console.log('failed to execute user.linkWithGoogle')
                console.log(error)
                reject(error)
            });
        });

        return promise;
    }
    
    linkWithFacebook() {
        let that = this;    
        var promise = new Promise<any>((resolve, reject) => {
            that.fbAuth.auth.currentUser.linkWithPopup(this.FacebookAuthProvider)
            .then(function(response: any) {
                console.log(response);
                resolve(response)
            }, function(error) {
                console.log('failed to execute user.linkWithFacebook')
                console.log(error)
                reject(error)
            })
            .catch(function(error) {
                console.log('failed to execute user.linkWithFacebook')
                console.log(error)
                reject(error)
            });
        });

        return promise;
    }

    linkWithEmail(email, password) {   
        let that = this;     
        var promise = new Promise<any>((resolve, reject) => {
            var prevUser = that.fbAuth.auth.currentUser;
            let originalEmail = that.fbAuth.auth.currentUser.email;            
            var credential = firebase.auth.EmailAuthProvider.credential(email, password);
            that.fbAuth.auth.signInWithCredential(credential)
            .then(function(user) {
                console.log("Sign In Success", user);                
                // After getting new user data delete the duplicate user
                user.delete()
                .then(function() {
                    if(!that.fbAuth.auth.currentUser)
                    prevUser.reload();
                    // Link the OAuth Credential to original account
                    prevUser.linkAndRetrieveDataWithCredential(credential)
                    .then(function(response) {
                        // Sign in with the newly linked credential
                        that.fbAuth.auth.signInWithCredential(credential)
                        .then(function(user) {
                            // Sign in with the newly linked credential
                            console.log("Sign in Success with the newly linked credential", user);
                            // if the account Email has changed recover it , the linking should be always with same primary email
                            if(user.email != originalEmail) {
                                user.updateEmail(originalEmail).then(()=>{                                   
                                    resolve(user);
                                }).catch(()=> resolve(user));
                            }
                            else
                            resolve(user);
                        })
                        .catch(function(error) {
                            console.log('failed to execute prevUser.signInWithCredential')
                            console.log(error)
                            reject(error)
                        });
                    })
                    .catch(function(error) {
                        console.log('failed to execute prevUser.linkWithCredential')
                        console.log(error)
                        reject(error)
                    });
                })
                .catch(function(error) {
                    console.log('failed to execute user.delete')
                    console.log(error)
                    reject(error)
                });
               
            }, function(response) {
                console.log('failed to execute user.linkWithEmail')
                console.log(response)
                reject(response)
            })
            .catch(function(error) {
                console.log('failed to execute user.linkWithEmail')
                console.log(error)
                reject(error)
            });
        });

        return promise;
    }

    private addProviderScopes(provider: firebase.auth.GoogleAuthProvider | firebase.auth.FacebookAuthProvider, scopes: string[]) {
        scopes.forEach(scope => {
            provider.addScope(scope);
        });
        return provider;
    }

    private get GoogleAuthProvider() {
        return this.addProviderScopes(new firebase.auth.GoogleAuthProvider(), ['https://www.googleapis.com/auth/contacts.readonly'])
    }

    private get FacebookAuthProvider() {
        return this.addProviderScopes(new firebase.auth.FacebookAuthProvider(), ['public_profile', 'email', 'user_friends']);
    }

}
