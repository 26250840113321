import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, of, throwError } from 'rxjs';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { GApiWrapper } from './gapi-wrapper.service';
import { Database } from './database.service';
import * as _ from 'lodash';
import { BaseHttpService } from './base.service';

@Injectable()
export class BackendService {
  constructor(
    private service: BaseHttpService,
    private db: Database
  ) {}

  getScore(answer_key: string) {
    const value = this.db.find('surveyAnswers', 'id', answer_key);

    if (value) {
      // TODO: remove toPromise when possible
      return of(value).toPromise();
    }

    return from(this.service.callPromise('GET', '/survey_answers/get', { itemId: answer_key }))
      .pipe(
        tap((response) => this.db.insert('surveyAnswers', response, true))
      )
      .toPromise();
  }

  submitAnswer(params) {
    return this.service.callPromise('POST', '/survey_answers/insert', params);
  }

  updateAnswer(id, params) {
    return this.service.callPromise('POST', `/survey_answers/update/${id}`, params);
  }

  loadQuestions(name, email?) {
    return this.service.callPromise('POST', '/question/get_by_survey_name', { name, email });
  }

  getSurveyAnswersbyEmail(email) {
    return this.service.callPromise('GET', `/survey_answers/getByEmail`, { email });
  }

  getSurveyAnswersbyId(itemId) {
    return this.service.callPromise('GET', '/survey_answers/get', { itemId });
  }

  emailResultsTo(surveyID, email = '') {
    return this.service.callPromise('POST', '/user/sendEnneagramLink', { email, surveyanswer: surveyID });
  }

  resonateUpdate(amount, comment, surveyID) {
    const queryParams = new HttpParams({fromObject: {
        resonateAmount: amount,
        resonateComment: comment,
        urlsafe: surveyID
    }});
    return this.service.callPromise('POST', `/survey_answers/resonateUpdate?${queryParams}`);
  }

  cacheGetByKey(key) {
    let value = this.db.find('caches', 'cacheKey', key);

    if (value) {
      this.db.refresh('cache');
      return of(JSON.parse(value.cacheValue)).toPromise(); // TODO: remove toPromise when possible
    }

    return from(this.service.callPromise('GET', '/cache/getByKey', { cacheKey: key }))
      .pipe(
        concatMap((response) => {
          if (!response.cacheValue) {
            return of(response);
          }

          response.dict = JSON.parse(response.cacheValue);
          this.db.insert('caches', response);
          this.db.refresh('cache');
          return of(JSON.parse(response.cacheValue));
        }),
        catchError(err => throwError(err))
      )
      .toPromise();
  }

  getAnswers(answers) {
    let img_index = 1;
    let para_index = 1;
    answers = _.isString(answers) ? JSON.parse(answers) : answers;

    const newAnswers = answers
      .filter(el => !!el)
      .map(answer => {
        delete answer.key;
        const [first, second] = answer['oid'].split('-');
        answer['index'] = parseInt(first.replace('q', ''), 10) * 100 + parseInt(second, 10);

        if (!(Array.isArray(answer.value) && answer.value.length)) {
          let labelDictionary = {
            'q20-1': 'Email',
            'q20-3': 'First Name',
            'q20-4': 'Last Name',
          };
          answer['question'] = labelDictionary[answer['oid']];
          answer['type'] = 'label';

          return answer;
        }

        if (answer.value[0].indexOf('http') !== -1) {
          answer['question'] = `${img_index}. Selected Cards in Order`;
          img_index += 1;
          answer['type'] = 'img';
        } else {
          answer['question'] = `${para_index}. Selected Statements in Order`;
          para_index += 1;
          answer['type'] = 'parag';
        }

        return answer;
      });

    return _.sortBy(newAnswers, 'index');
  }

  processResult(res) {
      let result = Object.assign({}, res);
      let extra = JSON.parse(res.extra);
      let obj = {
        surveyID: result.surveyID || result.id,
        user_id: extra.user_id,
        extra,
        answers: this.getAnswers(res.answers),
        chartData: extra.chart_data,
        tritype: extra.tritype,
        tritype_name: extra.tritype_name,
        tritype_full: extra.tritype ? extra.tritype.join('-') : null,
        enneagramType: extra.winning,
        instinct_stack: extra.instinct_stack,
        instinct_stack_full: extra.instinct_stack.join(', '),
        winning_instinct_full: extra.winning_instinct_full || extra.instinct_stack[0],
        winning_instinct: extra.winninginstinct,
        starring_roles: extra.starring_roles
      };

      // TODO: remove toPromise when possible
      return of(Object.assign(result, obj)).toPromise();
  }
  
  //faq api data
  get_FAQ() {
    return from(this.service.callPromise('GET', 'faq/getAll', {}))
      .pipe(
        tap((response) => {
        })
      )
      .toPromise();
  }

  get_Thinkific_UserCourses() {
    return from(this.service.callPromise('GET', '/thinkific_courses/get_users_courses', {}))
      .pipe(catchError(() => of([])))
      .toPromise();
  }
  get_Thinkific_UserSso() {
    return from(this.service.callPromise('GET', '/thinkific_courses/get_user_sso', {}))
    .pipe(catchError(() => of({})))
    .toPromise();
  }
}
