import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { GApiWrapper } from '@services/gapi-wrapper.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MessageService } from '@message/message.service';
import { AppEntityServices } from '@store/entity/entity-services';
import { LoginType } from '@store/services/user.service';

declare var $: any;

@Component({
  selector: 'app-accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.css']
})
export class AcceptInvitationComponent implements OnInit, OnDestroy {
  authTokenReceivedViaInvEmail: string;
  firstName: string;
  lastName: string;
  email: string;
  password = '';

  private ngUnsubscribe: EventEmitter<any> = new EventEmitter();

  constructor(
    private gapiWrapper: GApiWrapper,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private appEntityServices: AppEntityServices
  ) {}

  ngOnInit() {
    let that = this;
    this.route.queryParams.subscribe((params: Params) => {
      that.authTokenReceivedViaInvEmail = params['token'];
      that.firstName = params['first'];
      that.lastName = params['last'];
      that.email = params['email'];
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  submit() {
    const acceptBtn = $('#accept-button');

    acceptBtn.button('loading');

    this.gapiWrapper.setUser(this.authTokenReceivedViaInvEmail, this.password)
      .then(() => {
        this.appEntityServices.userService.login(LoginType.Password, this.email, this.password)
          .pipe(
            finalize(() => acceptBtn.button('reset')),
            takeUntil(this.ngUnsubscribe)
          )
          .subscribe(
            () => this.router.navigateByUrl('/home'),
            (err) => this.messageService.notify('danger', 'Invitation accepted successfully, but login failed', null, err.message || err)
          );
      })
      .catch((err) => {
        acceptBtn.button('reset');
        this.messageService.notify('warning', 'Sign-up failed', null, err.message || err)
      });
  }

}
