import { Injectable } from '@angular/core';
import { FacebookService, InitParams, UIParams, UIResponse } from 'ngx-facebook';
import { environment } from 'environments/environment';
import { MessageService } from '../modules/message/message.service';

@Injectable({
  providedIn: 'root'
})
export class FacebookShareService {

  constructor(private fb: FacebookService, private messageService: MessageService  ) { 
    this.fbInit();
  }

  fbInit() {
    let initParams: InitParams = {
      appId: environment.fb_app_id,
      cookie: true,
      xfbml: true,
      version: 'v2.12'
    };

    this.fb.init(initParams);
  }  

  /**
   * 
   * @param shareUrl - url for sharing on facebook or messenger
   * @param method - 'share' or 'send' , indicate weather this is share on facebook ('share') or messenger ('send')
   */
  fbShare(method,surveyID) {
      let params: UIParams = {
        href: `${environment.fb_shared_url}/${surveyID}`,
        method: method
      };    
      this.fb.ui(params)
        .then((res: UIResponse) => {
          console.log(res);
          this
        })
        .catch((e: any) => {
          this.messageService.notify('danger', e.message);
          console.error(e)
        });
    
  }
}
