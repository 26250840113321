

export class Coach {
    blocks?: any[];
    coach_type?:string;
    header_image?:string;
    id?:string;
    code?:string;
    name?:string;
    pindex?:string;
    subtitle?:string;

    public static toCoachArray(items: any[]) {
        return items.map((item) => {
            return {
                ...item,
                code: item.name.toLowerCase().replace(/\s/g, ""),
                blocks: JSON.parse(item.blocks) || []
            }
        })
    }
}