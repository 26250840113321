import {Injectable, EventEmitter} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';

@Injectable()
export class InsideHomeScreenNavigation {

  pageIndex: number = 0;
  isOpenFeedback: boolean = false;
  isLoading: boolean = false;
  notifications: any = { 0: 0 , 1: 0 , 2: 0 , 3: 0 , 4: 0 , all: 0 };
  logPage: EventEmitter<any> = new EventEmitter();
  scrollPage: EventEmitter<any> = new EventEmitter();
  showLoading: EventEmitter<any> = new EventEmitter();

  constructor( private router: Router  ) { }

  get isHomeUrl(){
    return location.href.indexOf('home') >= 0 && location.href.indexOf('enneagram-test') < 0;
  }

  get isSharedUrl():boolean {
    return location.href.indexOf('share')>-1;
  }

  get isMyProfileUrl() {
    return location.href.endsWith('profile');
  }

  get isProfileUrl() {
    return location.href.indexOf('profile')>-1 || location.href.indexOf('enneagram-test')>-1;
  }

  get isProfileResultsUrl() {
    return this.isProfileUrl && location.href.indexOf('questions') < 0;
  }

  get isProfileTestUrl() {
    return this.isProfileUrl && location.href.indexOf('questions') > -1;
  }

  get isProfileTestStartUrl() {
    return this.isProfileTestUrl && this.pageIndex===0;
  }

  get isRegisterUrl() {
    return !this.isProfileUrl && location.href.indexOf('register') >= 0;
  }

  get isLoginUrl() {
    return !this.isProfileUrl && location.href.indexOf('login') >= 0;
  }

  get isQuestionRegisterUrl() {
    return this.isProfileUrl &&
          (location.href.indexOf('register=true')>=0);
  }

  get isQuestionLoginUrl() {
    return this.isProfileUrl &&
          (location.href.indexOf('login=true')>=0);
  }

  isSelectedEnneagramTest(){
    return location.href.indexOf('enneagram-test') >= 0;
  }

  goToEnneagramTest(page, query?) {
    
    this.router.navigate(['profile', 'questions',page],{ queryParams: query , queryParamsHandling: 'merge'})
  }

  goToEnneagramOnboarding(query?) {
    if(!query) query = {};
    this.router.navigate(['onboarding'],{queryParams:query, queryParamsHandling: 'merge'})
  }

  goToEnneagramResult(query?) {
    if(!query) query = {};
    this.router.navigate(['profile', 'results'],{queryParams:query, queryParamsHandling: 'merge'})
  }

  goToEnneagramResultAdvanced() {
    this.router.navigate(['profile', 'results','advanced'],{queryParamsHandling: 'merge'})
  }

  logEnneagramPage(page) {
    this.logPage.emit('YouCloud_profileTest_page_' + page);
  }

}
