import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { BaseHttpService } from './base.service';

@Injectable()
export class GApiWrapper {

  constructor(
    private service: BaseHttpService
  ) {}

  firepassword4token(token) {
    return from(this.service.callPromise('POST', '/user/firepassword4token', {token}));
  }

  google4token(idToken, accessToken, refresh_token, token) {
    const params = {idToken, accessToken, refresh_token, token};

    return from(this.service.callPromise('POST', '/user/google4token', params));
  }

  setRefreshGoogleToken(google_access_token, google_refresh_token) {
    return from(this.service.callPromise('GET', '/user/refresh_google_token', {google_access_token, google_refresh_token}));
  }

  setRefreshFacebookToken(facebook_access_token) {
    return from(this.service.callPromise('GET', '/user/refresh_facebook_token', {facebook_access_token}));
  }

  facebook4token(accessToken, token) {
    const params = {accessToken, token};

    return from(this.service.callPromise('POST', '/user/facebook4token', params));
  }

  getUserConnections(): Observable<any> {
    return from(this.service.callPromise('GET', '/user_to_user/get_all'));
  }

  getUserInfo(userID: string): Promise<any> {
    return this.service.callPromise('GET', '/user/get', { id: userID });
  }

  deleteUserData(auth_token: string): Promise<any> {
    return this.service.callPromise('POST', '/user/delete_user', {auth_token});
  }

  setSurveyAnswersDefault(id: string): Promise<any> {
    return this.service.callPromise('POST', '/user/setSurveyAnswersDefault', {urlsafe: id});
  }

  getQuotes(): Promise<any> {
    return this.service.callPromise('POST', '/quote/list');
  }

  getSurveys(): Observable<any> {
    return this.service.call('GET', '/survey/list');
  }

  inviteUser(email: string, firstName: string, lastName: string, image_url?: string): Promise<string> {
    const params = {
      email,
      first: firstName,
      last_name: lastName,
      image_url: image_url
    };

    return this.service.callPromise('POST', '/user_to_user/invite_user', params);
  }

  setUser(authTokenReceivedViaInvEmail?: string, newPassword?: string): Promise<any> {
    const params = {token: authTokenReceivedViaInvEmail, password: newPassword};

    return this.service.callPromise('POST', '/user/get', params);
  }

  acceptInvitation(usertouser: string): Promise<any> {
    return this.service.callPromise('POST', '/user_to_user/accept_invite', {usertouser});
  }

  declineInvitation(usertouser: string): Promise<any> {
    return this.service.callPromise('POST', '/user_to_user/decline_invite', {usertouser});
  }

  removeContactByUserId(user: string): Promise<any> {
    return this.service.callPromise('DELETE', '/user_to_user/deletebyuser', {user});
  }

  submitSurveyFeedback2(userId: string, answers: string[]): Promise<any> {
    const params = {
      "title": "ContinueJourney",
      "description": JSON.stringify(answers),
      "category": "ContinueJourney",
      "user": userId
    };

    return this.service.callPromise('POST', '/issues/insert', params);
  }

  setManualType(first: string, second: string, third: string, instinct: string): Promise<any> {
    return this.service.callPromise('POST', '/user/manualset', {first, second, third, instinct});
  }

  getProducts(): Promise<any> {
    return this.service.callPromise('GET', '/product/permission_paginated_list');
  }

  setUserImageUrl(url) {
    return this.service.callPromise('POST', '/user/setUserImageUrl', {url});
  }

  getStaticPage(slug): Promise<any> {
    return this.service.callPromise('GET', '/page/getPageBySlug', {slug});
  }

  setUserName(firstName: string, lastName: string) {
    const params = {name: firstName, last: lastName};
    return this.service.callPromise('POST', '/user/setUserName', params);
  }

  getByUserId(userId: string) {
    return this.service.callPromise('GET', '/user_to_user/getbyuser', {id: userId});
  }
}

