import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers, metaReducers } from './root-store.state';
import { RootStoreEffects } from './root-store.effects';
import { EntityStoreModule } from './entity/entity-store.module';




@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      }
    }),
    EffectsModule.forRoot([RootStoreEffects]),    
    EntityStoreModule,
    StoreDevtoolsModule.instrument({logOnly: environment.production, features: { persist: true }}),   
  ],  
})
export class RootStoreModule {
}
