import { Injectable } from '@angular/core';
import { AdapterSync, LowdbSync } from 'lowdb';
import { Observable, BehaviorSubject } from 'rxjs';
import low from 'lowdb';
import LocalStorage from 'lowdb/adapters/LocalStorage';
import * as _ from 'lodash';
import { tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { GApiWrapper } from './gapi-wrapper.service';
import * as moment from 'moment';
import { BaseHttpService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class Database {
  cache: Observable<any>;
  me: Observable<any[]>;
  users: Observable<any[]>;
  friends: Observable<any[]>;
  friends_requests: Observable<any[]>;
  invitations: Observable<any[]>;

  private _cache: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private _me: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private _users: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private _friends: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private _friends_requests: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private _invitations: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private db;

  constructor(
    private base: BaseHttpService,
    private gapiWrapper: GApiWrapper
    ) {
    this.initDatabase();
    this.initObservables();
    this.initSubjectsFromDb();
    this.otherStuff();
  }

  updateRelationships() {
    let meKey = this.getValue('me.id');
    let friendEmails = [];
    const getUniqueItems = (userKey: 'user1'|'user2', accepted: boolean) => {
      const oppositeUserKey = (userKey === 'user1') ? 'user2' : 'user1';
      let items = this.db
        .get('user2user')
        .filter({ [userKey]: meKey, 'accepted': accepted, 'declined': false })
        .value();

      if (friendEmails.length) {
        items = items.filter(item => !friendEmails.includes(item.user2Email));
      }

      let obj = {};
      items.forEach((value) => {
        let item = this.mapUser2User(value, oppositeUserKey);
        obj[item.email] = item;
      });

      return Object.values(obj)
        .sort((a: any, b: any) => {
          return a.full_name.localeCompare(b.full_name);
        });
    };

    // friends
    const uniqueFriends = getUniqueItems('user1', true);
    friendEmails = uniqueFriends.map(({email}) => email);
    this._friends.next(uniqueFriends);

    // invitationsToMe
    const uniqueFriendsRequests = getUniqueItems('user2', false);
    this._friends_requests.next(uniqueFriendsRequests);

    // invitations from me
    const uniqueInvitations = getUniqueItems('user1', false);
    this._invitations.next(uniqueInvitations);
  }

  addNewRelationships(items) {
    items = Array.isArray(items) ? items : [items];
    items.forEach(item => {
      const {user1Email, user2Email} = item;
      const itemInDb = this.db.get('user2user').find({user1Email, user2Email}).value();

      if (this.isAllowedToUpdate(itemInDb, item)) {
        this.insert('user2user', item);
      }
    });
    this.updateRelationships();
    this.addToUserTable(items);
  }

  getUserConnections() {
    return this.gapiWrapper.getUserConnections()
      .pipe(
        tap((response) => {
          let items = response.items || [];
          items = this.getFilteredGuessItems(items);
          this.set('user2user', items);
          this.initRelationshipsData(items);
        })
      );
  }

  getValue(path) {
    return this.db.get(path).value();
  }

  set(path, value) {
    this.db.set(path, value).write();
  }

  remove(table: string, field: string, value: string): void {
    this.get(table).remove({[field]: value}).write();
  }

  get(path) {
    return this.db.get(path);
  }

  find(table: string, column: string, val: string) {
    let value = this.get(table)
      .find({[column]: val})
      .value();

    if (value === undefined || value.timeout === undefined) {
      return value;
    }

    let timeout = new Date(value.timeout);
    let now = new Date();
    if (now < timeout) {
      return value;
    }
  }

  // TODO: consider renaming -> save, upsert
  insert(table: string, obj, timeout?: number|boolean): void {
    const dbTable = this.get(table);
    const entity = dbTable.find({ id: obj.id });

    if (timeout !== undefined) {
      if (typeof timeout === 'boolean') {
        timeout = 30;
      }
      obj['timeout'] = moment().add(timeout, 'minutes').toISOString();
    }

    if (entity.value()) {
      entity.assign(obj).write();
    } else {
      dbTable.push(obj).write();
    }
  }

  refresh(key) {
    switch (key) {
      case 'cache':
        this._cache.next(this.get('caches'));
        break;
      case 'users':
        this._users.next(this.get('users'));
        break;
      default:
        throw Error('Wrong subject key!');
    }
  }

  refreshMe() {
    this._me.next(this.get('me').value());
  }

  clear() {
    const state = this.db.getState();
    Object.entries(state)
      .forEach(([key, value]) => {
        if (Array.isArray(value)) {
          this.db.set(key, []).write();
          return;
        }
        this.db.set(key, {}).write();
      });
  }

  private initDatabase() {
    const adapter = new LocalStorage('db1');

    this.db = low(adapter);
    this.db.defaults({
      me: {},
      user2user: [],
      users: [],
      caches: [],
      surveyAnswers: [],
      log_events: []
    }).write();
  }

  private initObservables() {
    this.users = this._users.asObservable();
    this.friends = this._friends.asObservable();
    this.friends_requests = this._friends_requests.asObservable();
    this.invitations = this._invitations.asObservable();
    this.cache = this._cache.asObservable();
    this.me = this._me.asObservable();
  }

  private initSubjectsFromDb() {
    this.refresh('cache');
    this.refresh('users');
  }

  private otherStuff() {
    // TODO: get rid of it
    this.base.authToken = this.getValue('me.authToken');

    // for debugging purposes
    if (!environment.production) {
      window['db'] = this.db;
    }
  }

  private mapUser2User(item, user) {
    if (!item) {
      return item;
    }

    const { id, inviteLastSent } = item;
    let newData = {
      user2user: id,
      id: item[user] || '',
      user_id: item[user] || '',
      name: item[user + 'Name'] || '',
      first_name: item[user + 'Name'] || '',
      last_name: item[user + 'LastName'] || '',
      email: item[user + 'Email'],
      enneagramType: (item[user + 'tritype']) ? item[user + 'tritype'].split(',')[0] : '',
      tritypeDescription: item[user + 'tritypeDescription'] || '',
      tritype: (item[user + 'tritype']) ? item[user + 'tritype'].split(',') : '',
      instinctual: item[user + 'instinctualType'] || '',
      instinct_stack: item[user + 'instinct_stack'] || [],
      instinctualType: item[user + 'instinctualType'] || '',
      image_url: item[user + 'image_url'] || 'assets/images/default-avatar.svg',
      surveyID: item[user + 'surveyAnswersDefault'],
      surveyAnswersDefault: item[user + 'surveyAnswersDefault'],
      precision: item[user + 'GuessPrecision'],
      isGuess: item[user + 'IsGuess'],
      score: item[user + 'score'],
      // ToDo: new if last sent date is within 7 days , update if necessary
      new: moment(inviteLastSent).add(7, 'days') > moment()
    };

    newData['full_name'] = [newData.first_name, newData.last_name].join(' ').trim();
    newData['tritype_full'] = _.isArray(newData.tritype) ? newData.tritype.join('-') : newData.tritype;
    newData['winning_instinct_full'] = (newData.instinct_stack && newData.instinct_stack.length) ? newData.instinct_stack[0] : '';

    return Object.assign({}, item, newData);
  }

  private addToUserTable(values = []) {
    if (!values.length) {
      return;
    }

    const self = this;
    const newUsers = [];

    handleRelationships(values);
    updateWithNewUsersData();
    this.refresh('users');

    function getNewKey(rsKey, keyName) {
      let key = (rsKey === keyName) ? 'id' : rsKey.replace(keyName, '').trim();

      return key.charAt(0).toLowerCase() + key.slice(1);
    }

    function getNewUserFields(newUserObj) {
      const { tritype, name, lastName } = newUserObj;

      return {
        enneagramType: tritype ? tritype[0] : null,
        full_name: [name, lastName].join(' ').trim()
      };
    }

    function populateUserGuesses(srcUser, targetUser) {
      if (!targetUser.guesses) {
        targetUser.guesses = [];
        return;
      }

      let filterguess = targetUser.guesses.filter(item => item.user2user === srcUser.user2user);
      if (!filterguess.length) {
        targetUser.guesses.push(srcUser);
      }
    }

    function handleRelationships(items) {
      items.forEach(relationship => {
        const { user1, user2, id } = relationship;
        let user1Exists = self.find('users', 'id', user1);
        let user2Exists = self.find('users', 'id', user2);
        let newUser1: any = {user2user: id, user_id: user1};
        let newUser2: any = {user2user: id, user_id: user2, guesses: []};
        // const self = this;

        Object.entries(relationship).forEach(populateNewUsers);
        populateNewUsersAdditional();
        handleGuesses();
        updateDb();

        // FUNCTIONS
        function populateNewUsers([rsKey, rsValue]) {
          if (!user1Exists && rsKey.startsWith('user1')) {
            const key = getNewKey(rsKey, ' user1');
            newUser1[key] = rsValue;
          } else if (rsKey.startsWith('user2')) {
            const key = getNewKey(rsKey, 'user2');
            newUser2[key] = rsValue;
          }
        }

        function populateNewUsersAdditional() {
          // only set user1 once
          if (!user1Exists) {
            Object.assign(newUser1, getNewUserFields(newUser1));
          }
          Object.assign(newUser2, getNewUserFields(newUser2));
        }

        function handleGuesses() {
          // adding Guesses for user2 and make sure that the one you are adding guesses to has enneagramType hens made a test
          if (user2Exists && user2Exists.enneagramType) {
            // if the real connection comes after guessed connection
            if (user2Exists.isGuess && !newUser2.isGuess) {
              newUser2.guesses = user2Exists.guesses;
              populateUserGuesses(user2Exists, newUser2);
              user2Exists.guesses = [];
            } else if (newUser2.isGuess) {
              populateUserGuesses(newUser2, user2Exists);
            }
          } else if (newUser2) {
            // TODO: do we need additional else if condition ????
            // assign newUser2 instead if user2Exists has no test Data (enneagramType is null)
            user2Exists = null;
          }
        }

        function updateDb() {
          if (!user1Exists) {
            newUsers.push(newUser1);
          }

          if (!user2Exists || user2Exists.isGuess && !newUser2.isGuess && newUser2.tritype) {
            newUsers.push(_.cloneDeep(newUser2));
          } else {
            newUsers.push(newUser2);
          } // update existing connections other than me
        }
      });
    }

    function updateWithNewUsersData() {
      const users = self.db.get('users').value();

      newUsers.forEach(newUser => {
        const index = users.findIndex(user => user.id === newUser.id);

        if (index !== -1) {
          Object.assign(users[index], newUser);
        } else {
          users.push(newUser);
        }
      });

      self.set('users', users);
    }
  }

  private initRelationshipsData(values) {
    this.updateRelationships();
    this.addToUserTable(values);
  }

  private getFilteredGuessItems(items) {
    if (!items.length) {
      return items;
    }

    const uniqItems = items.reduce((result, currentRs) => {
      const emailKey = [currentRs.user1Email, currentRs.user2Email].join('_');
      const prevElem = result[emailKey];
      if (this.isAllowedToUpdate(prevElem, currentRs)) {
        result[emailKey] = currentRs;
      }

      return result;
    }, {});


    return Object.values(uniqItems);
  }

  private isAllowedToUpdate(prevElem, currElem) {
    return !prevElem || prevElem.user2IsGuess && !currElem.user2IsGuess && currElem.user2tritype;
  }

}
