import { Component, OnInit, Input, TemplateRef, Output, EventEmitter, ViewChild, ElementRef, forwardRef } from '@angular/core';
import { ResultTemplateContext } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead-window';
import { isString } from 'util';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, map, tap, distinctUntilChanged, filter } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-friends-typehead',
  templateUrl: './friends-typehead.component.html',
  styleUrls: ['./friends-typehead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FriendsTypeheadComponent),
      multi: true
    }
  ]
})
export class FriendsTypeheadComponent implements ControlValueAccessor {
  selected: any;
  @Input() data: any[] = [];
  @Input() id: any;
  @Input() selectedValue: any = [];
  @Input() searchfields: string[] = [];
  @Input() valuefield: string = 'id';
  @Input() labelfield: string = 'name';
  @Input() template: TemplateRef<ResultTemplateContext>;
  @Output() changeValue: EventEmitter<any> = new EventEmitter();
  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  onChanged: any = () => {};
  onTouched: any = () => {};

   // gets the value from the formControl
   writeValue(val) {
  }
  registerOnChange(fn: any) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  formatter = (x: {full_name: string}) => x.full_name;
  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === this.selectedValue ? this.data
        : this.data.filter(v => {
          return v.full_name.toLowerCase().indexOf(term.toLowerCase()) > -1;
        })))
    );
  }
  onSelect(ev) {
    if (ev.item !== this.selected) {
      this.selected = {
        value: this.valuefield ?  ev.item[this.valuefield] :  ev.item,
        label: this.labelfield ?  ev.item[this.labelfield] :  ev.item,
        data: ev.item
      };
      this.writeValue(this.selected);
      this.onChanged(this.selected);
      this.onTouched(this.selected);
      this.changeValue.emit([this.selected , this.id]);
    }
  }
}
