export default {
  site_url: 'https://www.onetype.co',
  app_url: 'https://app.onetype.co',
  api_url: 'https://enneagram-359016.ue.r.appspot.com/_ah/api/youcloud/v1',
  blog_url: 'https://www.OneType.io/OneType-blog',
  store_url:'https://store.OneType.io',
  info_url: 'https://info@OneType.io',
  typeform_url: 'https://OneType.typeform.com',
  enneagram_course_url: 'https://explore.OneType.io/intro-to-the-enneagram',
  ben_video_url: 'https://explore.OneType.io/tytr-webinar-registration-gift-1',
  transform_course: 'https://explore.OneType.io/tytr-salespage-gift-1',
  s3_collage_img:'https://s3.amazonaws.com/positive-youcloud/images/collage',
  app_email: '@OneType.io',

  fb_shared_url: 'http://app.OneType.io/profile/share',
  fb_invite_url: 'http://app.OneType.io',

};
