import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil, map } from 'rxjs/operators';
import { CurrentUser } from '@services/current-user.service';
import { ConnectionInvite } from '@models/connection.model';
import { MatDialogRef } from '@angular/material';
import { AppEntityServices } from '../../../../@store/entity/entity-services';
declare var $: any;

@Component({
  selector: 'app-connection-invite-modal',
  templateUrl: './connection-invite-modal.component.html',
  styleUrls: ['./connection-invite-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectionInviteModalComponent implements OnInit, OnDestroy {  // TODO: refactoring needed
  inviteForm: FormGroup;
  success = false;
  error = false;
  errorMsg = '';
  private ngUnsubscribe: EventEmitter<any> = new EventEmitter();

  @Output() refreshList: EventEmitter<any> = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
    public currentUser: CurrentUser,
    private appEntityServices: AppEntityServices,
    public dialogRef: MatDialogRef<ConnectionInviteModalComponent>
  ) {
    
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  close() {
    this.dialogRef.close();
  }

  initForm() {
    this.inviteForm = new FormGroup({
      first_name: new FormControl(null, Validators.required),
      last_name: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email])
    });
    this.errorMsg = '';
    this.success = false;
    this.error = false;
  }

  isInValid(element) {
    element = this.inviteForm.get(element);
    return (element.invalid && (element.dirty || element.touched));
  }

  
  invite() {
    let {email, first_name, last_name} = this.inviteForm.value;

    if (!this.inviteForm.valid || !email.length) {
      return;
    }
    let user: ConnectionInvite = {  email, last_name, first_name};
    this.appEntityServices.connectionService.inviteConnection(user, false)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(response => {      
        if(response && !response.error) {
          this.inviteForm.reset();
          this.success = true;
        } else {
            this.error = true;
            this.success = false;
            this.errorMsg = response.error;
        }
        this.cdr.detectChanges();      
    }, error => {      
      this.error = true;
      this.success = false;
      this.errorMsg = error;
      this.cdr.detectChanges();
    });
  }

  /*fbInit() {
    let initParams: InitParams = {
      appId: environment.fb_app_id,
      cookie: true,
      xfbml: true,
      version: 'v2.12'
    };

    this.fb.init(initParams);
  }*/
}
