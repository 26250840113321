export class SocialUser {
  id: string;
  full_name: string;
  email: string;
  last_name: string;
  name: string;
  image_url: string  = 'assets/images/default-avatar.svg';  
  googleId?: string;
  facebookId?: string;
  
  public static maptoSocialUser(item, replaceStr?): SocialUser {   
    if (replaceStr) {
      item = { ...item,
        email: item[replaceStr+'Email'],
        name: item[replaceStr+'Name'],
        last_name: item[replaceStr+'user2LastName'],
        image_url: item[replaceStr+'ImageUrl'],
        googleId: item[replaceStr+'GoogleId'],
      }
    }
        
    return {
      ...item,
      social_id: item.id,
      id: item.user2 || item.email,
      user_id: item.user2 || item.email,
      image_url: item.image_url || 'assets/images/default-avatar.svg',
      full_name: [item.name, item.last_name].join(' ').trim(),
      googleId: item.googleId || item.googleid,
      facebookId: item.facebookId || item.facebookid
    };
  }
}