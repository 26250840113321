import { NgModule } from '@angular/core';
import { EntityDataService, EntityDataModule, DefaultDataServiceConfig, EntityCollectionReducerMethodsFactory, PersistenceResultHandler, EntityServices } from '@ngrx/data';
import { CONSTANTS } from '@models/constants.model';
import { entityConfig } from './entity-metadata';
import { AppEntityServices } from './entity-services';
import { AdditionalEntityCollectionReducerMethodsFactory, AdditionalPropertyPersistenceResultHandler } from './entity-collection';
import { ProfileDataService, ProfileCollectionService } from '@store/services/profile.service';
import { CacheDataService, CacheCollectionService } from '@store/services/cache.service';
import { UserDataService, UserCollectionService } from '@store/services/user.service';
import { QuestionDataService, QuestionCollectionService } from '@store/services/question.service';
import { CommonDataService, CommonCollectionService } from '@store/services/common.service';
import { SocialCollectionService, SocialDataService } from '@store/services/social.service';
import { GroupCollectionService, GroupDataService } from '@store/services/group.service';
import { ConnectionDataService, ConnectionCollectionService } from '@store/services/connection.service';
import { MessageCollectionService, MessageDataService } from '@store/services/message.service';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
    root: CONSTANTS.HOST + '/_ah/api/youcloud/v1/',
    timeout: 3000, // request timeout
} 
  
  @NgModule({
    imports: [
        EntityDataModule.forRoot(entityConfig), 
    ],
    providers: [
      AppEntityServices,
      { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
      { provide: PersistenceResultHandler, useClass: AdditionalPropertyPersistenceResultHandler },
      { provide: EntityCollectionReducerMethodsFactory, useClass: AdditionalEntityCollectionReducerMethodsFactory },
      { provide: EntityServices, useExisting: AppEntityServices },      
      QuestionDataService,
      CommonDataService,
      ProfileDataService,
      CacheDataService,
      UserDataService,
      SocialDataService,
      GroupDataService,
      MessageDataService,
      ConnectionDataService,
      UserCollectionService,
      QuestionCollectionService,
      CacheCollectionService,
      ProfileCollectionService,
      SocialCollectionService,
      CommonCollectionService,
      GroupCollectionService,
      ConnectionCollectionService,
      MessageCollectionService
    ]
  })
  export class EntityStoreModule {
    constructor(
      entityDataService: EntityDataService,
      questionDataService: QuestionDataService,
      commonDataService: CommonDataService,
      profileDataService: ProfileDataService,
      cacheDataService: CacheDataService,
      userDataService: UserDataService,
      socialDataService: SocialDataService,
      groupDataService: GroupDataService,
      connectionDataService: ConnectionDataService,
      messageDataService: MessageDataService,
    ) {
    // Register Custom Data Services
      entityDataService.registerService('Question', questionDataService);
      entityDataService.registerService('Common', commonDataService);
      entityDataService.registerService('Profile', profileDataService);      
      entityDataService.registerService('Cache', cacheDataService);
      entityDataService.registerService('User', userDataService);
      entityDataService.registerService('Social', socialDataService);
      entityDataService.registerService('Group', groupDataService);
      entityDataService.registerService('Connection', connectionDataService);
      entityDataService.registerService('Message', messageDataService);
    }
  }