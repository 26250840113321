import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectionInviteModalComponent } from './connections/invite-modal/connection-invite-modal.component';
import { CreateGroupModalComponent } from './groups/create-modal/create-group-modal.component';
import { UpdateGroupModalComponent } from './groups/update-modal/update-group-modal.component';
import { GroupInviteModalComponent } from './groups/invite-modal/group-invite-modal.component';
import { ResultsFeedbackComponent } from './profile/results-feedback/results-feedback.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@shared/pipes/pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TypeaheadModule } from '@shared/components/typeahead/typeahead.module';
import { MaterialModule } from '@material/material.module';
import { ResultsModalComponent } from './profile/results-modal/results-modal.component';
import { ResultsShareComponent } from './profile/results-share/results-share.component';

const EXPORTED_COMPONENTS = [
  ConnectionInviteModalComponent,
  CreateGroupModalComponent,
  UpdateGroupModalComponent,
  GroupInviteModalComponent,
  ResultsFeedbackComponent,
  ResultsModalComponent,
  ResultsShareComponent
];

@NgModule({
  declarations: [...EXPORTED_COMPONENTS],
  exports: [...EXPORTED_COMPONENTS],
  entryComponents: [...EXPORTED_COMPONENTS],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    NgbModule,
    TypeaheadModule,
    MaterialModule
  ]
})
export class ModalsModule { }
