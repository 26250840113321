import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, takeUntil, map, pluck, take } from 'rxjs/operators';
import { CurrentUser } from '@services/current-user.service';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { GroupRequest } from '@models/group.model';
import { MatDialogRef } from '@angular/material';
import { MessageService } from '@message/message.service';
import { AppEntityServices } from '@store/entity/entity-services';
import { User } from '@models/user.model';
declare var $: any;

@Component({
  selector: 'app-group-invite-modal',
  templateUrl: './group-invite-modal.component.html',
  styleUrls: ['./group-invite-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupInviteModalComponent implements OnInit, OnDestroy {  // TODO: refactoring needed
  inviteForm: FormGroup;
  team_group_key: string;
  usersLoading = false;
  userToken: string = '';
  users: User[] = [];
  member_emails: string[] = [];
  members: { label: string, value: string }[] = [];
  showImport: boolean = false;
  inProgress: boolean = false;
  success: boolean = false;
  errorMsg: string;
  fileName: string;
  private ngUnsubscribe: EventEmitter<any> = new EventEmitter();

  @Output() refreshList: EventEmitter<any> = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
    public currentUser: CurrentUser,
    private route: ActivatedRoute,
    private actions$: Actions,
    private messageService: MessageService,
    private appEntityServices: AppEntityServices,
    public dialogRef: MatDialogRef<GroupInviteModalComponent>
  ) {
    this.initForm();
   }

  ngOnInit(): void {
    this.appEntityServices.groupService.selectedGroupId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(id => this.team_group_key = id);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  close() {
    this.dialogRef.close();
  }

  getUsers(data) {
    if (data && data.length === 3 && this.userToken != data) {
      this.usersLoading = true;
      this.userToken = data;
      this.appEntityServices.userService.loadAllUsers(this.userToken).subscribe(users=>{
        this.users = users;
        this.usersLoading = false;     
        this.cdr.detectChanges();
      });
    }
  }

  initForm() {

    this.inviteForm = new FormGroup({
      email: new FormControl(null),
      // invitation_text: new FormControl(null),
    });
    this.member_emails = [];
    this.members = [];
  }

  get canSubmit(): boolean {
    return this.member_emails.length > 0 && !this.inviteForm.invalid;
  }

  isValidEmail(email) {
    const control = new FormControl(email, Validators.email);
    return !control.errors;
  }

  addEmail() {
    const { email, ...rest } = this.inviteForm.value;
    let isValid = email && email.value && (email.data || (!email.data && this.isValidEmail(email.value))) && !this.member_emails.includes(email.value);
    if (isValid) {
      this.member_emails.push(email.value);
      this.members.push({ label: email.label, value: email.value });
      this.inviteForm.reset({ ...rest, email: null });
      this.cdr.detectChanges();
    }
  }

  deleteEmail(item) {
    let index = this.members.indexOf(item);
    this.member_emails.splice(index, 1);
    this.members.splice(index, 1);
  }

  cancelImport() {
    this.showImport = !this.showImport;
    this.member_emails = [];
    this.members = [];
  }


  submitInvite() {
    let { invitation_text } = this.inviteForm.value;

    if (!this.inviteForm.valid) {
      return;
    }

    this.inProgress = true;
    let group: GroupRequest = { member_emails: this.member_emails, members: this.members, team_group_key: this.team_group_key, invitation_text };
    this.appEntityServices.groupService.inviteUserstoGroup(group).pipe(take(1), pluck('error'))
    .subscribe(error => this.handleReturn(error), error=> this.handleReturn(error));
  }

  handleReturn(error) {
    this.inProgress = false;
    this.errorMsg = (error || {}).message;
    this.success = !error;
    if (this.success) {
      this.initForm();
      this.appEntityServices.groupService.loadAllGroupConnections( this.team_group_key);
    }      

    this.cdr.detectChanges();    
  }

  fileChangeListener(files: FileList) {
    console.log(files);
    if (files && files.length > 0) {
      let file: File = files.item(0);
      this.fileName = file.name;
      let reader: FileReader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = (e) => {
        let csv: string = reader.result as string;
        let lines = csv.split(/\r\n|\n|\r/ig) || [];
        const { members, member_emails } = lines.reduce((acc, curr, index) =>
        {
          if(!curr) return acc;
          if (index === 0)
            acc.headers = [...curr.split(',')];
          else {
            const values = [...curr.split(',')];
            const v_index = acc.headers['email'] || 0;
            const l_index = acc.headers['Name'] || acc.headers['First Name'] || 1;
            if (!acc.member_emails.includes(values[v_index])) {
              acc.members = [...acc.members, { value: values[v_index], label: values[l_index] }];
              acc.member_emails = [...acc.member_emails, values[v_index]];
            }
          }
          return acc;
        }, { members: this.members, member_emails: this.member_emails, headers: [] });

        this.member_emails = member_emails;
        this.members = members;
      }
    }
  }

}
