import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { CompleteResetPasswordComponent } from './complete-reset-password/complete-reset-password.component';
import { LoginGuardGuard } from '@guards/login-guard.guard';

const routes: Routes = [
  { path: 'login', canActivate: [LoginGuardGuard], component: LoginComponent },
  { path: 'register', canActivate: [LoginGuardGuard], component: RegisterComponent },
  { path: 'accept-invitation-individual', component: AcceptInvitationComponent },
  { path: 'complete-reset-password', component: CompleteResetPasswordComponent },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
