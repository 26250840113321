import { Component, OnInit } from '@angular/core';
import { InsideHomeScreenNavigation } from '@services/navigation.service';
import { CurrentUser } from '@services/current-user.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  copyrightText = '&copy; OneType Systems Inc. 2019';
  env = environment;
  constructor(
    public navigation: InsideHomeScreenNavigation,
    public currentUser: CurrentUser
  ) {}

  ngOnInit() {
  }

  get showFooter(){
    return !this.navigation.isLoginUrl && !this.navigation.isRegisterUrl;
  }

  get showTestFooter(){
    return this.navigation.isProfileTestUrl;
  }

  get faqUrl() {
    return `${environment.app_url}/faq`;
  }

  get productUrl(){
    return `${environment.typeform_url}/to/WmwpZH?email=${this.currentUser.userInfo.email}&firstname=${this.currentUser.userInfo.name}&userid=${this.currentUser.userInfo.id}`;
  }

}
