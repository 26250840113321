import urls from "./urls";

export default {
  ...urls,
  stripeKey: 'pk_test_789eOb1gv9ao0CWJvx14VcsU', // YouCloud test key,
  amplitudeKey: '9371197749c2f20683c576ac6bf6e2db',  // Project 'ConsumerApp',
  fb_app_id: '141042016571550', // Facebook  App ID , https://developers.facebook.com,
  enneagram_default_redirectTest: 'enneagramv2a', // redirect to this test when no valid test
  enneagram_default_testName: 'enneagramv2a',  // load this as default test
  enneagram_validTests: ['enneagramv3', 'enneagramv4', 'guessfriend', 'enneagramv2nointroq', 'ktest', 'enneagramv2a', 'enneagramv2a'],  // all valid tests a user can load
  enable_facebook: true
};
