import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, Logger, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from '@ngrx/data';

import { Observable, of, EMPTY, combineLatest } from 'rxjs';
import { map, catchError, pluck, tap } from 'rxjs/operators';
import { Question } from '@models/question.model';
import { BaseHttpService } from '@services/base.service';
import { Survey } from '@models/survey.model';
import { ActivatedRoute } from '@angular/router';
import { isArray } from 'util';


@Injectable()
export class ProfileDataService extends DefaultDataService<Survey> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private service: BaseHttpService) {
    super('Profile', http, httpUrlGenerator);
  }

  // get All Reports / Survey Answers
  getAll(): Observable<Survey[]> {
    return this.getReports();
  }

  getById(key: number | string): Observable<Survey> {
    return this.getReport(key);
  }

  getWithQuery(params: string | QueryParams | any): Observable<any> {
    return of([]);
  }

  upsert(entity: any): Observable<any> {    
    const {id} = entity;
    if (id)
    return this.service.call('PUT', `/survey_answers/update/${id}`, entity);

    return this.service.call('POST', '/survey_answers/insert', entity);
  }

  emailResultsTo(surveyID, email = ''): Observable<any> {
    return this.service.call('POST', '/user/sendEnneagramLink', { email, surveyanswer: surveyID });
  }

  resonateUpdate(amount, comment, surveyID): Observable<any> {
    const queryParams = new HttpParams({fromObject: {
        resonateAmount: amount,
        resonateComment: comment,
        urlsafe: surveyID
    }});
    return this.service.call('POST', `/survey_answers/resonateUpdate?${queryParams}`);
  }

  submitSurveyFeedback2(userId: string, answers: string[]): Observable<any> {
    const params = {
      "title": "ContinueJourney",
      "description": JSON.stringify(answers),
      "category": "ContinueJourney",
      "user": userId
    };

    return this.service.call('POST', '/issues/insert', params);
  }

  setSurveyAnswersDefault(id: string): Observable<any> {
    return this.service.call('POST', '/user/setSurveyAnswersDefault', {urlsafe: id});
  }

  getSurveyAnswersbyEmail(email): Observable<any> {
    return this.service.call('GET', `/survey_answers/getByEmail`, { email });
  }

  // get All Survey Answers
  private getReports(): Observable<Survey[]> {    
    return this.service.call('GET', '/survey_answers/getAll')
    .pipe(
        catchError(err => of([])),
        pluck('items'),
        map(items=>items.map((item=>Survey.maptoSurvey(item))))
    );
  }

   // get Report by Id
   private getReport(itemId): Observable<Survey> {    
    return this.service.call('GET', '/survey_answers/get', { itemId })
    .pipe(
        catchError(err => of(null)),
        map(item=>Survey.maptoSurvey(item))
    );
  }
  

}

@Injectable()
export class ProfileCollectionService extends EntityCollectionServiceBase<Survey> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory,
    private dataService: ProfileDataService,
    private route: ActivatedRoute) {
    super('Profile', elementsFactory);
  }

  upsert(entity: any): Observable<any> {
    return this.dataService.upsert(entity)
    .pipe(map(item => {      
      if (isArray(item) && item.length > 0) item = item[0];
      item = Survey.maptoSurvey({ ...entity, ...item});      
      this.setSurvey(item);
      return item;
    }));
  }

  resetData(): Observable<any> {        
    let queryParams: any = {additional: {survey: null, user1: null, user2: null}};
    return this.getWithQuery(queryParams);
  }

  setSurvey(survey: any): Observable<any> {        
    let queryParams: any = {additional: {survey}};
    return this.getWithQuery(queryParams);
  }

  setUsers(user1: any, user2: any): Observable<any> {        
    let queryParams: any = {additional: {user1, user2}};
    return this.getWithQuery(queryParams);
  }

  get profiles$(): Observable<any> {
    return this.collection$.pipe(map((item: any) => item.entities));
  }

  get user1$(): Observable<any> {
    return this.collection$.pipe(map((item: any) => item.user1));
  }
 
  get user2$(): Observable<any> {
    return this.collection$.pipe(map((item: any) => item.user2));
  }

  get survey$(): Observable<any> {
    return this.collection$.pipe(map((item: any) => item.survey));
  }

  get surveyID$(): Observable<string> {
    return combineLatest(this.route.params, this.route.queryParams, this.collection$)
      .pipe(map(([params, queryParams, collection]) => {        
        const {survey, user1} = <any>collection;
        let id = params.resultId || queryParams.resultId || (survey||{}).id || (user1||{}).surveyID || (user1||{}).surveyAnswersDefault || null;
        return id;
      }));
  }

  getSurveyAnswersbyEmail(email): Observable<any> {
    return this.dataService.getSurveyAnswersbyEmail(email);
  }

  setSurveyAnswersDefault(id: string): Observable<any> {
    return this.dataService.setSurveyAnswersDefault(id);
  }

  emailResultsTo(surveyID, email = ''): Observable<any> {
    return this.dataService.emailResultsTo(surveyID, email);
  }

  resonateUpdate(amount, comment, surveyID): Observable<any> {
    return this.dataService.resonateUpdate(amount, comment, surveyID);
  }

  submitSurveyFeedback2(userId: string, answers: string[]): Observable<any> {
    return this.dataService.submitSurveyFeedback2(userId, answers);
  }
}