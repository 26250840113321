import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, Logger, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { Observable, of, from, forkJoin } from 'rxjs';
import { map, catchError, pluck, tap, mergeMap, withLatestFrom, switchMap, concatMap, take, filter } from 'rxjs/operators';
import { BaseHttpService } from '@services/base.service';
import { User } from '@models/user.model';
import { SocialUser } from '@models/social.model';
import { TokenStorageService } from '@services/token-storage.service';
import { sortComparerFullName } from '@store/entity/entity-metadata';
import { Suggestion } from '@models/suggestion.model';


@Injectable()
export class SocialDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private service: BaseHttpService, private tokenStorageService: TokenStorageService) {
    super('Social', http, httpUrlGenerator);
  }

  getWithQuery(params: string | QueryParams | any): Observable<SocialUser[] | any[] | any> {
    const {data} = params;      
    return of(data || []);
  }

  getGoogleFriends(cursor_urlsafe?: string, size = 100): Observable<{ accessTokenIsValid: boolean, friends: any[], more: boolean, cursor: string }> {
    const params = { size };

    if (cursor_urlsafe) {
      params['cursor_urlsafe'] = cursor_urlsafe;
    }

    return this.service.call('GET', '/google_friend/list', params)
      .pipe(
        catchError(err => of({friends: [], more: false, accessTokenIsValid: null, cursor: ''})),
        pluck('json_message'),
        map(result => JSON.parse(result)),
        map(({ accessTokenIsValid, friends, more, cursor }) => {
          return {
            friends: friends.map(item => SocialUser.maptoSocialUser(item)).sort(sortComparerFullName) || [],
            accessTokenIsValid,
            more,
            cursor
          };
        })
      );
  }

  getFacebookFriends(cursor_urlsafe?: string, size = 100): Observable<{ accessTokenIsValid: boolean, friends: any[], more: boolean, cursor: string }> {
    const params = { size };

    if (cursor_urlsafe) {
      params['cursor_urlsafe'] = cursor_urlsafe;
    }

    return this.service.call('GET', '/facebook_friend/list', params)
      .pipe(
        catchError(err => of({friends: [], more: false, accessTokenIsValid: null, cursor: ''})),
        pluck('json_message'),
        map(result => JSON.parse(result)),
        map(({ accessTokenIsValid, friends, more, cursor }) => {
          return {
            friends: friends.map(item => SocialUser.maptoSocialUser(item)).sort(sortComparerFullName) || [],
            accessTokenIsValid,
            more,
            cursor
          };
        })
      );
  }  

  getSuggestionFriends(): Observable<Suggestion[]> {
    return this.service.call('GET', '/friend_suggestion/getAllSuggestionsAsUsers')
    .pipe(
      pluck('items'),
      map(result => result.map(item => SocialUser.maptoSocialUser(item))),
      catchError(err => of([]))
    );
  }
}

@Injectable()
export class SocialCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: SocialDataService) {
    super('Social', elementsFactory);
  }

  setData(additional: any): Observable<any> {
    let queryParams: any = { additional };
    return this.getWithQuery(queryParams);
  }

  getFriendSuggestion(): Observable<Suggestion[]> {
    return this.dataService.getSuggestionFriends().pipe(tap(friendSuggestion => this.setData({ friendSuggestion })));
  }

  loadSocialFriends(reset?:boolean , getGoogle?:boolean , getFacebook?: boolean): Observable<any> {    
    return this.collection$.pipe(take(1),
    concatMap(({googleFriends, facebookFriends}: any) => {
      googleFriends = {...googleFriends, friends: googleFriends.friends||[], more: googleFriends.more|| false, accessTokenIsValid: googleFriends.accessTokenIsValid||null, cursor: googleFriends.cursor||''};
      facebookFriends = {...facebookFriends, friends: facebookFriends.friends||[], more: facebookFriends.more|| false, accessTokenIsValid: facebookFriends.accessTokenIsValid||null, cursor: facebookFriends.cursor||''};

      let requests: any[] = [
        of({googleFriends, facebookFriends}),
        (!getFacebook) ? of(facebookFriends) : this.dataService.getFacebookFriends((facebookFriends||{}).cursor),
        (!getGoogle) ? of(googleFriends) : this.dataService.getGoogleFriends((googleFriends||{}).cursor)
      ];    
          
      return forkJoin(requests).pipe(
        map(([{googleFriends, facebookFriends}, newfacebookFriends, newgoogleFriends]) => {

        if(reset) {
            googleFriends= {...googleFriends, friends: [], cursor: ''};
            facebookFriends= {...facebookFriends, friends: [], cursor: ''};            
        }      
        if (newfacebookFriends && (!facebookFriends.cursor || facebookFriends.cursor !== newfacebookFriends.cursor)) {
          facebookFriends = { ...newfacebookFriends, friends: [...facebookFriends.friends, ...newfacebookFriends.friends].sort(sortComparerFullName) };
        }

        if (newgoogleFriends && (!googleFriends.cursor || googleFriends.cursor !== newgoogleFriends.cursor)) {
          googleFriends = { ...newgoogleFriends, friends: [...googleFriends.friends, ...newgoogleFriends.friends].sort(sortComparerFullName) };
        }
        return this.setData({facebookFriends, googleFriends});
        })
      )      
    }));

  }

  get googleFriends$(): Observable<SocialUser[]> {    
    return this.collection$.pipe(pluck('googleFriends'), map(({friends}) => [...friends ] || []));
  }

  get facebookFriends$(): Observable<SocialUser[]> {    
    return this.collection$.pipe(pluck('facebookFriends'), map(({friends}) => [...friends ]|| []));
  }

  get socialFriends$(): Observable<SocialUser[]> {    
    return this.collection$.pipe(map(({facebookFriends, googleFriends}:any) => [...facebookFriends.friends,...googleFriends.friends ].sort(sortComparerFullName) || []));
  }

  // get suggestionFriends$(): Observable<Suggestion[]> {
  //   return this.collection$.pipe(pluck('suggestionFriends'), map(({item}) => item.friendSuggestion));
  // }


  get suggestionFriends$(): Observable<Suggestion[]> {
    return this.collection$.pipe(
      map((item: any) => item.friendSuggestion),
      switchMap(items => items ? of(items) : this.getFriendSuggestion()));
  }


}
