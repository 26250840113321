import { Component, Input, forwardRef, TemplateRef, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, map, finalize, tap } from 'rxjs/operators';
import { ResultTemplateContext } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead-window';
import { isString } from 'util';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  providers: [     
    {
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => TypeaheadComponent),
      multi: true     
    }   
  ]
})
export class TypeaheadComponent implements ControlValueAccessor {
  selected: any;
  @Input() data: any[] = [];
  @Input() searchfields: string[] = [];  
  @Input() valuefield: string = "id";  
  @Input() labelfield: string = "name";
  @Input() mustSelect: boolean = true;
  @Input() placeholder: string = '';
  @Input() template: TemplateRef<ResultTemplateContext>;
  @Output() changeValue: EventEmitter<any> = new EventEmitter();
  @Output() filter: EventEmitter<any> = new EventEmitter();
  @Output() input: EventEmitter<any> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();
  @ViewChild('typeaheadInput') typeaheadInput: ElementRef;

  onChanged: any = () => {}
  onTouched: any = () => {}

  //gets the value from the formControl
  writeValue(val) {      
  }

  registerOnChange(fn: any) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn
  }
  
  private _loading: boolean = false;
  @Input()
  public get loading(): boolean {
    return this._loading;
  }

  public set loading(value: boolean) {
    this._loading = value;
    if(!value && this.selected && this.data.length > 0) {
      // show search after data is refreshed
      this.typeaheadInput.nativeElement.dispatchEvent(new Event('input'));
    }
  }

  onFocus(ev){
    this.focus.emit(this.selected);  
  }

  onInput(ev) {
    this.input.emit(ev.target.value);    
  }

  onSelect(ev) {
    if (ev.item != this.selected) {
      this.selected = {
        value: this.valuefield ?  ev.item[this.valuefield] :  ev.item, 
        label: this.labelfield ?  ev.item[this.labelfield] :  ev.item,
        data: ev.item
      }
      this.writeValue(this.selected);      
      this.onChanged(this.selected);
      this.onTouched(this.selected);
      this.changeValue.emit(this.selected);
    }    
  }

  onBlur() {    
    if (!this.mustSelect) {
      if(this.selected && isString(this.selected))
        this.selected = {label: this.selected , value: this.selected};

      this.writeValue(this.selected);
      this.onChanged(this.selected);
      this.onTouched(this.selected);
      this.changeValue.emit(this.selected);
    } 
    else 
    this.selected = null;       
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map((term:string) => term === '' ? []
      : (this.data ||[]).filter((item) => {
        let result = this.searchfields.reduce((acc,curr) => {
            return acc = (acc || (item[curr] || '').toLowerCase().includes(term.toLowerCase()));
        }, false);
  
        return result;
      })),
      tap(()=>{
        this.filter.emit(this.selected);
      })
  );

  formatter = (x: {full_name: string, label?: string}) => x.full_name || x.label;

}